import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/ApiCall.web";
import React from 'react';
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsCondsList {
    id: string,
    type: string,
    attributes: {
        id: number,
        description: string,
        accepted_by: IUser[]
    }
}

interface IUser {
    account_id: number,
    accepted_on: string,
    email: string
}

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    checkedAgree: boolean,
    termsCondsValue: string,
    termsCondsData: ITermsCondsList | null
    isUserAgreeTermsConds: boolean,
    isMobile: boolean,
    loading: boolean,
    isloggedIn: boolean,
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class TermsConditionsUserPartController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    termsAndConditionsApiCallId: string = "";
    agreeTermsAndConditionsApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            checkedAgree: false,
            termsCondsValue: "",
            loading: false,
            termsCondsData: null,
            isUserAgreeTermsConds: false,
            isMobile: window.innerWidth < 900,
            isloggedIn: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.getTermsAndConditions()

        window.addEventListener('resize', this.handleCheckScreenSize);
    }

    async componentWillUnmount() {
        window.removeEventListener('resize', this.handleCheckScreenSize);
    }

    setAgree = (value: boolean) => {
        this.setState({ checkedAgree: value })
    }

    handleCheckScreenSize = () => {
        this.setState({ isMobile: window.innerWidth < 900 });
    }

    onHandleAgree = async () => {
        if (this.state.checkedAgree) {
            await this.agreeTermsConds().then(() => 
            this.setState({isUserAgreeTermsConds: true})
            )
        }
    }

    getTermsAndConditions = async () => {
        this.setState({ loading: true })
        this.termsAndConditionsApiCallId = await apiCall({
            method: "GET",
            contentType: "application/json",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `bx_block_terms_and_conditions/terms_and_conditions/latest_record`,
        });
    }

    agreeTermsConds = async () => {
        this.setState({ loading: true })
        this.agreeTermsAndConditionsApiCallId = await apiCall({
            method: "POST",
            contentType: "application/json",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `bx_block_terms_and_conditions/terms_and_conditions/accept_and_reject`,
            body: {   
                "terms_and_condition_id": `${this.state.termsCondsData?.id}`,
                "is_accepted": this.state.checkedAgree
            }
        });
    }

    checkIfAgree = () => {
        this.checklogin()
        const userAcceptedArray = this.state.termsCondsData?.attributes.accepted_by
        const token = localStorage.getItem("token");
        if (token && userAcceptedArray) {
            const { id: userId } = jwtDecode<{ id: number }>(token);
            console.log(userId)
            const isUserAccepted = userAcceptedArray.some(user => user.account_id === userId);
            console.log(isUserAccepted)
            this.setState({ isUserAgreeTermsConds: isUserAccepted })
        }
    }
    checklogin= () => {
        if(localStorage.getItem("token"))
        {
           this.setState({isloggedIn: true});
        }
    }

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.termsAndConditionsApiCallId) {
                    if (responseJson.data) {
                        const responseData = responseJson.data as ITermsCondsList
                        this.setState({ termsCondsData: responseData, termsCondsValue: responseData.attributes.description })
                        this.checkIfAgree()
                    } else if (responseJson.error) {
                        toast.error(responseJson.error);
                    }
                }

                if (apiRequestCallId === this.agreeTermsAndConditionsApiCallId) {
                    if (responseJson.data) {
                    } else if (responseJson.error) {
                        toast.error(responseJson.error);
                    }
                }

                this.setState({ loading: false });
            }
        }
        // Customizable Area End
    }
}
