// App.js - WEB
import React, { Component, useState } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { WebRoutesGenerator, MyContext } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import AdminLandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import CourseAnalytics from "../../blocks/analytics/src/CourseAnalytics.web.tsx"
import UserAnalytics from "../../blocks/analytics/src/UserAnalytics.web.tsx"
import ReportsAnalytics from "../../blocks/analytics/src/ReportsPage.web.tsx"
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Contactus from "../../blocks/contactus/src/ContactPage.web";
import ContactusQueries from "../../blocks/contactus/src/Contactus.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Videos from "../../blocks/videos/src/Videos";
import Notifications from "../../blocks/notifications/src/Notifications";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import AssessmentTest from "../../blocks/assessmenttest/src/AssessmentTestPage.web";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsAndConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import PasswordReset from "../../blocks/email-account-registration/src/PasswordReset.web";
import AdminLogin from "../../blocks/email-account-registration/src/AdminLogin.web";
import AdminPasswordReset from "../../blocks/email-account-registration/src/AdminPasswordReset.web";
import AdminForgotPassword from "../../blocks/forgot-password/src/AdminForgotPassword.web";
import CourseCreation from '../../blocks/contentmanagement/src/CourseCreation.web'
import HelpCenter from "../../blocks/helpcentre/src/HelpCenter.web";
import UserProfile from "../../blocks/customisableuserprofiles/src/UserProfile.web";
import TermsConditionsUserPart from '../../blocks/termsconditions/src/TermsConditionsUserPart.web';
import PrivacyPolicyUserPart from '../../blocks/termsconditions/src/PrivacyPolicyUserPart.web'
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from "../../blocks/dashboard/src/Dashboard.web.tsx";
import MyLearning from "../../blocks/dashboard/src/MyLearning.web.tsx";
import EventManagement from '../../blocks/events/src/EventManagement.web.tsx';
import AdminSettings from '../../blocks/settings2/src/Settings2.web.tsx';
import ManageClient from '../../blocks/accountgroups/src/ManageClient.web.tsx';
import CoursesAndPackages from '../../blocks/accountgroups/src/CoursesAndPackages.web.tsx';
import CustomisableUserProfiles from '../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web.tsx';
import CourseDescription from '../../blocks/contentmanagement/src/CourseDescription.web.tsx'
import ClientProfile from '../../blocks/accountgroups/src/ClientProfile.web.tsx';
import LearningInsights from '../../blocks/mixpanelintegration/src/MixpanelIntegration.web.tsx';
import CourseLearning from '../../blocks/contentmanagement/src/CourseLearning.web.tsx'
import Certificate from '../../blocks/assessmenttest/src/Certificate.web.tsx';
import ClientManagement from '../../blocks/customisableuserprofiles/src/ClientManagement.web'
import ClientContentManagement from '../../blocks/contentmanagement/src/ClientContentManagement.web.tsx'
import AdminContentManagement from '../../blocks/contentmanagement/src/AdminContentManagement.web.tsx'
import ClientWideAnalytics from '../../blocks/analytics/src/ClientWideAnalytics.web.tsx';
import AuditTrailDetail from '../../blocks/audittrail/src/AuditTrailDetail.web.tsx'
import ClientSignupEmail from '../../blocks/termsconditions/src/ClientSignupEmail.web.tsx'
import AdminDashboard from '../../blocks/dashboard/src/AdminDashboard.web.tsx'

const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPage:{
 component:Dashboard,
path:"/",
isProtected: true,
role: "user",
},
MyLearning:{
  component:MyLearning,
  path:"/my-learning",
  isProtected:true,
  role:"user"
},
LearningInsights:{
  component:LearningInsights,
  path:'/LearningInsights',
  isProtected:true,
  role:'user'
},
CourseLearning:{
  component:CourseLearning,
  path:'/course-learning/:courseId/:courseModuleId',
  isProtected:true,
  role:'user'
},
AdminLandingPage: {
  component: TermsConditions,
  path: "/admin-landingpage",
  isProtected: true,
  role: "admin",
},
AdminDashboard: {
  component: AdminDashboard,
  path: "/admin-dashboard",
  isProtected: true,
  role: "admin",
},
Analytics:{
 component:Analytics,
path:"/Analytics",
isProtected:true,
role:"admin"
},
CourseAnalytics:{
  component:CourseAnalytics,
  path:'/course-analytics',
  isProtected:true,
  role:'admin'
},
AuditTrail:{
  component :AuditTrailDetail,
 path:"/audit-trail",
 isProtected:true,
 role:"admin"
 },
UserAnalytics:{
  component:UserAnalytics,
  path:'/user-analytics',
  isProtected:true,
  role:'admin'
},
ReportsAnalytics:{
  component:ReportsAnalytics,
  path:'/report-analytics',
  isProtected:true,
  role:'admin'
},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/sign-in"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Clients:{
 component:AccountGroups,
path:"/AccountGroups"},
Contactus:{
 component:Contactus,
path:"/contact-us",
},
ContactusQueries:{
  component:ContactusQueries,
 path:"/contact-us-queries",
 isProtected: false,
 },
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Videos:{
 component:Videos,
path:"/Videos"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
AssessmentTest:{
 component:AssessmentTest,
path:"/assessment-test/:courseId/:courseModuleId"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ResetPassword:{
  component:ResetPassword,
 path:"/forgot-password"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
TermsConditions:{
 component:TermsConditions,
path:"/terms-and-condition",
isProtected: true,
role: "admin",
},
TermsConditionsUserPart: {
  component: TermsConditionsUserPart,
  path: "/terms-and-conditions-user",
},
PrivacyPolicyUserPart: {
  component: PrivacyPolicyUserPart,
  path: "/privacy-policy",
},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
CourseCreation: {
  component: CourseCreation,
  path: "/course-creation/:id?",
  isProtected: true,
  role: "admin",
},
  AdminLogin: {
    component: AdminLogin,
    path: "/admin-login",
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  PasswordReset: {
    component: PasswordReset,
    path: "/reset-password",
  },
  AdminPasswordReset: {
    component: AdminPasswordReset,
    path: "/admin-reset-password",
  },
  AdminForgotPassword: {
    component: AdminForgotPassword,
    path: "/admin-forgot-password",
  },
  HelpCenter: {
    component: HelpCenter,
    path: "/help-center",
    isProtected: true,
    role: "user",
  },
  UserProfile: {
    component: UserProfile,
    path: "/user-profile",
    isProtected: true,
    role: "user",
  },
  UserManagement:{
    component:CustomisableUserProfiles,
    path:'/user-management',
    isProtected:true,
    role:"admin"
  },
  EventManagement: {
    component: EventManagement,
    path:"/EventManagement"
  },
  AdminSettings:{
    component: AdminSettings,
    path:'/AdminSettings',
  },
  ManageClient:{
    component: ManageClient,
    path:'/ManageClient'
  },
  CoursesAndPackages:{
    component:CoursesAndPackages,
    path:'/CoursesAndPackages'
  },
  CourseLibrary:{
    component: AdminContentManagement,
    path:'/course-library',
  },
  ClientProfile:{
    component:ClientProfile,
    path:'/ClientProfile'
  },
  ClientWideAnalytics:{
    component:ClientWideAnalytics,
    path:'/ClientWideAnalytics'
  },
  Certificate:{
    component:Certificate,
    path:'/Certificate'
  },
  
  Packages:{
    component:Catalogue,
    path:'/Packages'
  },
  CourseDescription:{
    component:CourseDescription,
    path:'/CourseDescription/:courseId'
  },
  ClientManagement:{
    component:ClientManagement,
    path:'/ClientManagement'
  },
  ClientContentManagement:{
    component:ClientContentManagement,
    path:'/ClientContentManagement',
  },
  ClientSignupEmail:{
    component:ClientSignupEmail,
    path:'/ClientSignupEmail'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  const [accessPermission, setAccessPermission] = useState(localStorage.getItem("p") === "t");

  const onChange = (val) => {
    localStorage.setItem("p", val?.toString()?.charAt(0));
    setAccessPermission(val);
  }

  return (
    <BuilderProvider>
      <MyContext.Provider value={{ setAccessPermission: onChange }}>
        <ToastContainer
          style={{ zIndex:"9999999" }}
          autoClose={3000}
          position="top-right"
          className="toast-container"
        />
        <WebRoutesGenerator routeMap={routeMap} accessPermission={accessPermission} />
        <Outlet />
      </MyContext.Provider>
    </BuilderProvider>
  );
}

export default App;