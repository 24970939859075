import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../../packages/framework/src/BlockComponent";
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    searchQuery:string;
    filteredLearningPaths:any;
    selectedLearningPathToAssign:any;
    anchorEl:any;
    anchorElCourse:any;
    showActionPopup:boolean,
    isCreateLearningPathModalOpen:boolean,
    isDeleteLearningPathModalOpen:boolean,
    titleError:string,
    updatePackageModal:boolean;
    updatedPackage:any;
    currentPackage:any;
    updatedPackageName:string;
    userAnchorEl: any
    courseSearchQuery:string;
    learningPathSearchQuery:string;
    courseData:any[];
    isCloning:boolean;
    title:string;
    description:string;
    showCourseActionPopup:boolean;
    selectedCourse:any;
    learningPathDropdown:any[];
    isCreatingLearningPath:boolean;
    loading:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminContentManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getLearningPathsApiCallId: string;
    getCoursesApiCallId: string;
    courseSearchQueryApiCallId: string;
    learningPathSearchQueryApiCallId: string;
    createLearningPathApiCallId: string;
    updateLearningPathApiCallId: string;
    deleteLearningPathsApiCallId: string;
    publishCourseApiCallId: string;
    deleteCourseApiCallId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            loading: false,
            searchQuery: '',
            filteredLearningPaths: [],
            learningPathDropdown: [],
            selectedLearningPathToAssign: null,
            anchorEl: {},
            anchorElCourse: {},
            showActionPopup: false,
            isCreateLearningPathModalOpen: false,
            isDeleteLearningPathModalOpen: false,
            titleError: '',
            updatePackageModal: false,
            updatedPackageName: '',
            currentPackage: null,
            userAnchorEl: null,
            courseSearchQuery: '',
            learningPathSearchQuery: '',
            updatedPackage: null,
            courseData: [],
            isCloning: false,
            title: '',
            description: '',
            showCourseActionPopup: false,
            selectedCourse: null,
            isCreatingLearningPath: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.getLearningPathsApiCallId = '';
        this.getCoursesApiCallId = '';
        this.courseSearchQueryApiCallId = '';
        this.learningPathSearchQueryApiCallId = '';
        this.createLearningPathApiCallId = '';
        this.updateLearningPathApiCallId = '';
        this.deleteLearningPathsApiCallId = '';
        this.publishCourseApiCallId = '';
        this.deleteCourseApiCallId = '';
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.learningPathSearchQueryApiCallId:
                    case this.getLearningPathsApiCallId: this.handleLearningPathsResponse(responseJson);
                        break;
                    case this.courseSearchQueryApiCallId:
                    case this.getCoursesApiCallId: this.handleGetCoursesResponse(responseJson);
                        break;
                    case this.createLearningPathApiCallId: this.handleCreateLearningpathResponse(responseJson);
                        break;
                    case this.updateLearningPathApiCallId: this.handleUpdateLearningPathResponse(responseJson);
                        break;
                    case this.deleteLearningPathsApiCallId: this.handleDeleteLearningPath(responseJson);
                        break;
                    case this.deleteCourseApiCallId:
                    case this.publishCourseApiCallId: this.handleCourseActionResponse(responseJson);
                        break
                }
            }
            if (apiRequestCallId !== this.getLearningPathsApiCallId) {
                this.setState({ loading: false });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getLearningPaths();
        this.getCourses();
    }

    getCourses = async () => {
        this.setState({ loading: true });
        this.getCoursesApiCallId = await apiCall({
            method: "GET",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `/bx_block_admin/courses`,
        });
    }

    handleGetCoursesResponse = (response: any) => {
        this.setState({
            courseData: response.data.map((course: any) => ({
                id: course.id,
                title: course.attributes.title,
                description: course.attributes.description,
                duration: course.attributes.duration,
                author_name: course.attributes.author_name,
                image: course.attributes?.thumbnail_image,
                course_module_count: course.attributes.course_modules.length,
                status: course.attributes.status || null,
            }))
        })
    }

    handleCourseActionResponse = (responseJson: any) => {
        if (responseJson.message) {
            toast.success(responseJson.message);
            this.setState({ isDeleteLearningPathModalOpen: false, anchorElCourse: {} });
            this.getLearningPaths();
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    handleCourseSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ courseSearchQuery: event.target.value }, async () => {
            if (this.state.courseSearchQuery !== "") {
                this.courseSearchQueryApiCallId = await apiCall({
                    method: "GET",
                    navigation: this.props.navigation,
                    token: localStorage.getItem("token"),
                    endPoint: `/bx_block_admin/courses/search_packages?course=${this.state.courseSearchQuery}`,
                })
            } else if (this.state.courseSearchQuery === "") {
                this.getCourses()
            }
        })
    }

    handleLearningPathSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ learningPathSearchQuery: event.target.value }, async () => {
            if (this.state.learningPathSearchQuery !== "") {
                this.learningPathSearchQueryApiCallId = await apiCall({
                    method: "GET",
                    navigation: this.props.navigation,
                    token: localStorage.getItem("token"),
                    endPoint: `/bx_block_categories/learning_paths/search_learning_path?query=${this.state.learningPathSearchQuery}`,
                })
            } else if (this.state.learningPathSearchQuery === "") {
                this.getLearningPaths();
            }
        })
    }

    handleDeleteLearningPath = (responseJson: any) => {
        if (responseJson.message) {
            toast.success(responseJson.message);
            this.setState({ isDeleteLearningPathModalOpen: false });
            this.getLearningPaths();
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    onSubmitLearningPath = (courseData: any) => {
        if (!courseData.length) {
            this.navigateToCourseCreation()
        }else {
            this.updateLearningPath()
        }
    }

    handleUpdateCourseSelection = (courseId: string) => {
        this.setState((prevState) => {
            const { updatedPackage } = prevState;

            if (!updatedPackage) {
                return {
                    updatedPackage: {
                        id: '',
                        name: '',
                        addCourse: [courseId.toString()],
                        removeCourse: [],
                    }
                };
            }

            const { addCourse = [], removeCourse = [] } = updatedPackage;
            if (addCourse.includes(courseId.toString())) {
                return {
                    updatedPackage: {
                        ...updatedPackage,
                        addCourse: addCourse.filter((id: any) => id.toString() != courseId.toString()),
                        removeCourse: [...removeCourse, courseId.toString()],
                    }
                };
            } else {
                return {
                    updatedPackage: {
                        ...updatedPackage,
                        addCourse: [...addCourse, courseId.toString()],
                        removeCourse: removeCourse.filter((id: any) => id.toString() != courseId.toString()),
                    }
                };
            }
        });
    };

    prepareRequestBody = (updatedPackage: any, isCloning: boolean) => {
        let learning_path: any = {};
        learning_path['add_course'] = updatedPackage?.addCourse;
        learning_path['remove_course'] = updatedPackage?.removeCourse;
        if (!isCloning) {
            learning_path['tite'] = updatedPackage?.title;
            learning_path['description'] = updatedPackage.description;
        }
        const body = {
            "learning_path": learning_path
        }
        return body;
    }

    updateLearningPath = async () => {
        const { updatedPackage, isCloning } = this.state
        if (isCloning && !this.state.selectedLearningPathToAssign) {
            toast.error('Please select learning path to assign.');
            return;
        }
        const reqBody = this.prepareRequestBody(updatedPackage, isCloning);
        this.updateLearningPathApiCallId = await apiCall({
            method: "PATCH",
            navigation: this.props.navigation,
            contentType: 'application/json',
            token: localStorage.getItem("token"),
            body: reqBody,
            endPoint: `/bx_block_categories/learning_paths/${this.state.isCloning ? this.state.selectedLearningPathToAssign : updatedPackage?.id}`,
        });
    }

    createDropdownData = (data: any) => {
        const dropdownList: any[] = [];
        data.forEach((path: any) => {
            dropdownList.push({
                label: path.attributes.title,
                value: path.id
            })
        });
        return dropdownList;
    }

    updateDropdownSelected = (path: any) => {
        this.setState({ selectedLearningPathToAssign: path.value })
    }

    handleLearningPathsResponse = (responseJson: any) => {
        if (responseJson?.data) {
            this.setState({ learningPathDropdown: this.createDropdownData(responseJson.data) })
            this.setState({ filteredLearningPaths: responseJson.data })
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    handleCreateLearningpathResponse = (responseJson: any) => {
        if (responseJson.data) {
            this.setState({ isCreatingLearningPath: true, currentPackage: responseJson.data });
            this.handlePackageOption(responseJson.data);
            this.closeCreateLearningPathModal();
            this.openUpdatePackageModal();
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    handleUpdateLearningPathResponse = (responseJson: any) => {
        if (responseJson.message) {
            toast.success(responseJson.message);
            this.getLearningPaths();
            this.closeUpdatePackageModal();
            if (this.state.isCreatingLearningPath) {
                this.setState({ isCreatingLearningPath: false });
                this.getLearningPaths();
            }
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    closeCreateLearningPathModal = () => {
        this.setState({ isCreateLearningPathModalOpen: false, isCloning: false })
    }

    openCreateLearningPathModal = () => {
        this.setState({ isCreateLearningPathModalOpen: true });
    }

    openUpdatePackageModal = () => {
        this.setState({ updatePackageModal: true })
    }

    closeUpdatePackageModal = () => {
        this.setState({ updatePackageModal: false, isCloning: false })
    }

    getLearningPaths = async () => {
        this.setState({ loading: true });
        this.getLearningPathsApiCallId = await apiCall({
            method: "GET",
            contentType: "application/json",
            endPoint: `bx_block_categories/learning_paths`,
            token: localStorage.getItem("token"),
        });
    }

    createLearningPath = async () => {
        if (!this.state.title.length) {
            this.setState({ titleError: 'Title can not be blank.' });
            return;
        }
        this.createLearningPathApiCallId = await apiCall({
            method: "POST",
            contentType: "application/json",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `bx_block_categories/learning_paths`,
            body: {
                "learning_path": {
                    "title": this.state.title,
                    "description": this.state.description
                }
            },
        });
    }

    handlePopoverClick = (event: any, item = {}) => {
        this.setState({ anchorEl: event?.currentTarget, showActionPopup: true, selectedLearningPathToAssign: null, isCloning: false });
        this.handlePackageOption(item);
    }

    handlePopoverClose = () => {
        this.setState({ anchorEl: {}, showActionPopup: false, isCloning: false });
    }

    handleCoursePopoverClick = (event: any, item = {}) => {
        this.setState({ anchorElCourse: event?.currentTarget, showCourseActionPopup: true, selectedCourse: item });
    }

    handleCoursePopoverClose = () => {
        this.setState({ anchorElCourse: {}, showCourseActionPopup: false });
    }

    handlePublishCourse = async (course: any) => {
        this.handleCoursePopoverClose();
        const status = course.status === 'published' ? 'published' : 'draft';
        this.publishCourseApiCallId = await apiCall({
            method: "PATCH",
            contentType: "application/json",
            endPoint: `bx_block_admin/courses/update_status`,
            body: {
                id: course.id,
                status: status
            },
            token: localStorage.getItem("token"),
        });
    }

    handleUpdateCourse = () => {
        this.handleCoursePopoverClose();
    }

    handleCloneCourse = () => {
        this.handleCoursePopoverClose();
    }

    handleDeleteCourse = async (course: any) => {
        this.handleCoursePopoverClose();
        const id = course.id;
        this.deleteCourseApiCallId = await apiCall({
            method: "DELETE",
            contentType: "application/json",
            endPoint: `bx_block_admin/courses/${id}`,
            token: localStorage.getItem("token"),
        });
    }

    handlePackageOption = (learningPath: any) => {
        this.setState({
            currentPackage: learningPath,
            updatedPackage: {
                id: learningPath.id.toString(),
                title: learningPath.attributes.title,
                description: learningPath.attributes?.description || '',
                addCourse: learningPath.attributes.courses.map((c: any) => c.id.toString()),
                removeCourse: [],
            },
        });
    };

    handleAssignCourses = (e: React.MouseEvent<HTMLElement>, learningPath: any) => {
        e?.stopPropagation?.();
        this.openUpdatePackageModal();
        this.setState({
            showActionPopup: false,
            updatedPackageName: this.state.currentPackage?.attributes.name
        })
    }

    handleClone = (e: React.MouseEvent<HTMLElement>, learningPath: any) => {
        e?.stopPropagation?.();
        this.openUpdatePackageModal();
        this.setState({ courseData: this.state.currentPackage?.attributes.courses, isCloning: true, showActionPopup: false })
    }

    handleDelete = (e: React.MouseEvent<HTMLElement>, id: any) => {
        e?.stopPropagation?.();
        this.setState({ isDeleteLearningPathModalOpen: true, showActionPopup: false })
    }

    onDeletePathConfirm = async (isDelete: boolean) => {
        if (isDelete) {
            const id = this.state.currentPackage.id;
            this.deleteLearningPathsApiCallId = await apiCall({
                method: "DELETE",
                contentType: "application/json",
                endPoint: `bx_block_categories/learning_paths/delete_learning_path?id=${id}`,
                token: localStorage.getItem("token"),
            });
        } else {
            this.setState({ isDeleteLearningPathModalOpen: false })
        }
    }

    navigateToCourseCreation = () => {
        this.props.navigation.navigate('CourseCreation')
    }
    // Customizable Area End
}
