import React from "react";
// Customizable Area Start
  import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    Paper,
    Box,
    Grid,
    TextField,
    Select,
    Checkbox,
    ListItemText,
    Divider,
    InputAdornment,
    FormControl,
    Autocomplete,
    List, ListItem,
    DialogTitle,
    DialogContent,
    Dialog,
    Pagination,
    TableFooter,
    AppBar,
    Tabs,
    Tab,
    ListItemIcon,
    Radio,
    Chip,
  } from "@mui/material";
  import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
    styled,
  } from "@mui/material/styles";
  import { 
    AddCircleOutline,
    Close, 
    DragIndicator, 
    EventNoteOutlined, 
    KeyboardArrowDown,
    Search
   } from "@mui/icons-material";
  import MoreVertIcon from "@mui/icons-material/MoreVert";
  import AnalyticsController, { Props } from "./AnalyticsController.web";
  import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import AdminFooter from '../../../../packages/components/src/AdminFooter.web';

  /* istanbul ignore next */
  const CustomPagination = styled(Pagination)(({ theme }) => ({
    '& .MuiPagination-ul': {
      justifyContent: 'center',
      flexWrap:"nowrap"
    },
    '& .MuiPaginationItem-root': {
      borderRadius: '50%',
      color:"#000" ,
      margin: theme.spacing(0.5),
      textDecoration:"underline",
      '&.Mui-selected': {
        backgroundColor: "transparent",
        color: "#6200EA",
        textDecoration:"none"
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }));

  /* istanbul ignore next */
  const  StyledMenuItem = styled(MenuItem)(({ theme,selected }) => ({
      padding: 0,
      borderRadius: "2px",
      border:"1px solid #D7D7D7",
      backgroundColor:"#fff",
      margin:"10px",
      "&:hover": {
        backgroundColor: "#fff",
      },
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: "500",
        color:"#000",
        marginLeft:"10px"
      },
  }));
// Customizable Area End

export default class ReportsAnalytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

 // Customizable Area Start

 /* istanbul ignore next */
 customRangePicker = (startDate:any, endDate:any,range:any) =>{
 return(
  <Box display="flex" alignItems="center" gap={2}>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
         <DatePicker
          sx={{width:"100%"}}
          value={startDate}
          onChange={(date)=>{this.handleStartDateChange(date,range)}}
          format="DD/MM/YYYY"
          slots={{
            openPickerIcon:EventNoteOutlined
          }}
          slotProps={{
            textField: {
              placeholder: "Select a StartDate",
            },
            openPickerButton: {
              sx: {
                color: "#000",
              },
            },
          }}
        />
         <Box>-</Box>
        <DatePicker
          value={endDate}
          onChange={(date)=>{this.handleEndDateChange(date,range)}}
          sx={{width:"100%"}}
          minDate={startDate}
          format="DD/MM/YYYY"
          slots={{
            openPickerIcon:EventNoteOutlined
          }}
          slotProps={{
            textField: {
              placeholder: "Select a endDate",
            },
            openPickerButton: {
              sx: {
                color: "#000",
              },
            },
          }}
          />
          </LocalizationProvider>
      </Box>
 )
 }

 
  /* istanbul ignore next */ 
 CreateReportAnalytics = () => {
    const {
        title,
        reportingArea,
        availableColumns,
        selectedColumns,
        arrangedColumns,
        selectedFilter,
        subFilter,
        availableFilters,
        availableSubFilters,
        emailOptions,
        selectedEmails,
        isEdit,
        reportId,
        startDate, 
        endDate,
        emailStartDate,
        emailEndDate
      } = this.state

      const reportingAreaData=[{label:"Courses",value:"course_progress"},{label:"Accreditation",value:"company_progress"},{label:"Clients",value:"client_progress"},{label:"Users",value:"user_progress"}]
      
      return (
        <div>
        <Box padding={3}>
            <Box style={{display:"flex",justifyContent:"space-between"}}>
            <Typography variant="h6" style={{fontWeight:"bold"}}>{isEdit?"Edit Report":"Create New Report"}</Typography>
            <Button variant="outlined" style={{height:"50px",width:"200px",backgroundColor:"#fff",textTransform:"capitalize"}}
            onClick={this.handleOpenPreviewReport}
            disabled={!isEdit}
            > 
            Preview Report 
            </Button>
            </Box>
            <Grid container spacing={3} marginTop={2}>
            <Grid item xs={12}>
            <Typography style={webStyle.typography}>Title of Report</Typography>
              <TextField
                value={title}
                onChange={this.handleTitleChange}
                fullWidth
                style={{backgroundColor:"#fff"}}
                placeholder="write title here"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Typography style={webStyle.typography}>Select Reporting Area</Typography>
                <Select
                  value={reportingArea}
                  placeholder="select a ReportingArea"
                  renderValue={(selectedValue) => {
                    const selectedOption = reportingAreaData.find(
                      (option) => option.value === selectedValue
                    );
                    return (
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        {selectedOption?.label}
                      </Typography>
                    );
                  }}
                onChange={this.handleChangereportingArea}
                style={{backgroundColor:"#fff"}}
                >
              {reportingAreaData.map((option) => (
              <StyledMenuItem
              key={option.value}
              value={option.value}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <ListItemText
                primary={option.label}
                primaryTypographyProps={{
                  fontSize: "14px",
                }}
              />
                <ListItemIcon sx={{ minWidth: "32px" }}>
                <Radio
                  checked={reportingArea === option.value}
                  value={option.value}
                  name="filter-radio-group"
                  sx={{
                    color: "#000",
                    "&.Mui-checked": { color: "#000" },
                  }}
                />
              </ListItemIcon>
              </StyledMenuItem>
              ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography style={webStyle.typography}>Select Columns</Typography>

              <Box sx={{backgroundColor:"#fff",padding:"20px",borderRadius:"5px",height:"100%"}}>
              <TextField
                placeholder="Search Columns"
                variant="outlined"
                size="small"
                fullWidth
                sx={{ marginRight: 1 }}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                    ),
                }}
                />
               
              {availableColumns.map((column,index) => (
                <>
                {/* istanbul ignore next */}
                <Box key={index} display="flex" alignItems="center">
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={(e)=>this.handleSelectColoumn(e,column.id)}
                  />
                  <ListItemText primary={column.label} />
                </Box>
                </>
              ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography style={webStyle.typography}>Arrange Columns</Typography>
              <Box sx={{backgroundColor:"#fff",padding:"20px",borderRadius:"5px",height:"100%"}}>
              <List>
            {arrangedColumns.map((item:any, index) => (            
             <ListItem
              key={index}
              draggable
              onDragStart={() => this.handleDragStart(index)}
              onDragOver={this.handleDragOver}
              onDrop={() => this.handleDrop(index)}
              onDragEnd={this.handleDragEnd}
              style={{
                cursor: 'move',
                margin: '4px 0',
                border: '1px solid #ddd',
                padding: '8px',
                borderRadius: '4px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
            <Typography>{index+1} .  </Typography>
              <IconButton>
                <DragIndicator />
              </IconButton>
              <ListItemText primary={item.label} />
              <IconButton
                edge="end"
                onClick={() => this.handleDelete(index)}
                aria-label="delete"
              >
                <Close />
              </IconButton>
            </ListItem>
          ))}
              </List>
              </Box>
            </Grid>
            </Grid>


            <Box p={3} border={1} borderRadius={2} borderColor="grey.300" style={{marginTop:"12%",backgroundColor:"#fff"}}>
            <Typography variant="h6" mb={2} style={{color:"black",fontWeight:"bold"}}>
             Report Filters
            </Typography>

            <Grid item xs={12}>
            <Typography style={webStyle.typography}>Date Range</Typography>
             {this.customRangePicker(startDate, endDate,"filter")}
            </Grid>

            <div style={{
               display: "flex", 
               flexWrap: "wrap", 
               gap: "2%", 
               marginTop: "2%",
               alignItems:"end" 
               }}>
            <div style={{ flex: "1 1 40%" }}>
            <div style={{ width: "100%" }}>
           <Typography style={webStyle.typography}>Filter</Typography>
      {/* istanbul ignore next */}
       <Select
        value={selectedFilter}
        onChange={this.handleFilterChange}
        renderValue={(data: any) => (
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "18px",
            }}
          >
            {data}
          </Typography>
        )}
        style={{ height: 54,width:"100%" }}
      >
        {availableFilters.map((option) => (
          <StyledMenuItem
            key={option}
            value={option}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <ListItemText
              primary={option}
              primaryTypographyProps={{
                fontSize: "14px",
              }}
            />
            <ListItemIcon sx={{ minWidth: "32px" }}>
              <Radio
                checked={selectedFilter === option}
                value={option}
                name="filter-radio-group"
                sx={{
                  color: "#000",
                  "&.Mui-checked": { color: "#000" },
                }}
              />
            </ListItemIcon>
          </StyledMenuItem>
        ))}
      </Select>
    </div>
  </div>

  <div style={{ flex: "1 1 40%" }}>
    <div style={{ width: "100%" }}>
      <Typography style={webStyle.typography}>Sub Filter</Typography>
      <Select
        multiple
        value={subFilter}
        renderValue={(selected) => selected?.join(", ")}
        style={{
          width:"100%"
        }}
      >
        {availableSubFilters.map((option) => (
          <StyledMenuItem
            key={option}
            value={option}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <ListItemText
              primary={option}
              primaryTypographyProps={{
                fontSize: "14px",
              }}
            />
            <ListItemIcon sx={{ minWidth: "32px" }}>
              <Radio
                checked={subFilter.includes(option)}
                value={option}
                name="filter-radio-group"
                sx={{
                  color: "#000",
                  "&.Mui-checked": { color: "#000" },
                }}
              />
            </ListItemIcon>
          </StyledMenuItem>
        ))}
      </Select>
    </div>
  </div>

  <div style={{ flex: "1 1 15%", display: "flex", alignItems: "center" }}>
    <Button variant="outlined" style={{ height: "55px", width: "100%" }}>
      Add Filter
    </Button>
  </div>
</div>

            </Box> 

            <Box p={3} border={1} borderRadius={2} borderColor="grey.300" style={{marginTop:"7%",backgroundColor:"#fff"}}>
            <Typography variant="h6" mb={2} style={{color:"black",fontWeight:"bold"}}>
            Schedule Report
            </Typography>

        <Grid container spacing={2}>
          {/* Date & Time Picker */}
          <Grid item xs={12}>
          <Typography style={webStyle.typography}>Date Range</Typography>
          {this.customRangePicker(emailStartDate,emailEndDate,"scheduleReport")}
            </Grid>

          <Grid item xs={8} style={{marginTop:"-5px"}}>
            <Typography style={webStyle.typography} variant="subtitle1" gutterBottom>
              Email Report
            </Typography>
            <Autocomplete
               multiple
               options={emailOptions}
               getOptionLabel={(option) => option}
               value={selectedEmails}
               onChange={this.handleEmailChange}
               renderTags={(value, getTagProps) =>
           value.map((option, index) => (
           <Chip
            // key={index}
            label={option}
            deleteIcon={<Close />}
            {...getTagProps({ index })}
            sx={{
              border: "1px solid black",
              borderRadius: "4px",
              fontWeight: 400,
              backgroundColor:"#fff",
              color:"black",
              "& .MuiChip-deleteIcon":{
                color:"black",
                fontSize:"16px"
              }
            }}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Select Emails"
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: "7px",
            },
          }}
        />
      )}
      sx={{
        "& .MuiAutocomplete-tag": {
          margin: "2px",
        },
      }}
    />
          </Grid>

          <Grid item xs={4} style={{marginTop:"50px"}}>
            <Typography></Typography>
            <Button variant="contained" color="primary" fullWidth style={{height:"50px"}} 
            onClick={()=>{this.handleSaveReport(isEdit)}}
            >
              Schedule & Create
            </Button>
          </Grid>
        </Grid>
            </Box>

        </Box>
        </div>
    );
  };

  /* istanbul ignore next */
  renderRunReportTable =()=>{
    const { reportPreview, rowsPerPage, page,metaData} = this.state; 
    const startResult = page * rowsPerPage + 1;
    const endResult = Math.min((page + 1) * rowsPerPage, metaData.total_count);
    const total_pages = Math.ceil(reportPreview?.length / rowsPerPage)
   console.log(reportPreview,"reportPreview");
   
    return(
      <Paper>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1050}}>
        <TableHead>
          <TableRow>
            <TableCell style={webStyle.tableHeader}>Clients Name</TableCell>
            <TableCell style={webStyle.tableHeader}>Users</TableCell>
            <TableCell style={webStyle.tableHeader}>Course Assigned</TableCell>
            <TableCell style={webStyle.tableHeader}>Course Completed</TableCell>
            <TableCell style={webStyle.tableHeader}>Accreditation Achieved</TableCell>
            <TableCell style={webStyle.tableHeader}>Average User Completion Rate</TableCell>
            <TableCell style={webStyle.tableHeader}>Total Learning Path Completed</TableCell>
            <TableCell style={webStyle.tableHeader}>Total Learning Hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportPreview && reportPreview.length>0 && reportPreview.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row:any, index:any) => (
            <TableRow key={index}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.companyName}</TableCell>
              <TableCell>{row.courseAssigned}</TableCell>
              <TableCell>{row.courseCompleted}</TableCell>
              <TableCell>{row.accreditationAchieved}</TableCell>
              <TableCell>{row.avgCompletionRate}</TableCell>
              <TableCell>{row.totalLearningPathCompleted}</TableCell>
              <TableCell>{row.totalLearningHours}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
       <Table>
       <TableFooter>
         <TableRow>
             <TableCell style={{borderBottom:0}} colSpan={7} align="center">
               <div style={{alignItems: 'center',justifyContent:"space-between",display:"flex",width:"100%"}}>
                 <div  style={{ width:"20%"}}></div>
                 <div  style={{ display: 'flex', justifyContent: 'center', width:"60%"}}>
                             <CustomPagination
                               data-test-id="pagination"
                               count={total_pages}
                               page={this.state.metaData.current_page}
                               onChange={this.handlePageChange}
                               siblingCount={1}
                               boundaryCount={1}
                             />
                 </div>
                 <div  style={{ width:"20%", textAlign: 'right' }}>
                             {`${startResult}-${endResult} of ${reportPreview?.length} results`}
                 </div>
               </div>
             </TableCell>
         </TableRow>
       </TableFooter> 
       </Table>
    </Paper>
    )
  }

  /* istanbul ignore next */
  reportPreviewModal = () => {
    const { reportPreview, rowsPerPage, page, openDialog ,openRunReport,metaData,reportId,startDate, endDate} = this.state; 
    const startResult = page * rowsPerPage + 1;
    const endResult = Math.min((page + 1) * rowsPerPage, metaData.total_count);
    const total_pages = Math.ceil(metaData.total_count / rowsPerPage)
   
    return (
        <Dialog open={openDialog} onClose={this.previewReportClose} fullWidth maxWidth="lg">
        <DialogTitle style={{fontWeight:"bold"}}>
           {openRunReport?"Run Report" :"Report Preview"}
            <IconButton
            aria-label="close"
            onClick={this.previewReportClose}
            style={{ position: "absolute", right: 15, top: 8 ,color:"#000"}}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
           {openRunReport&& <>
            <Typography style={webStyle.typography}>Date Range</Typography>
            {this.customRangePicker(startDate, endDate,"filter")}
            </>}
          <Divider style={{marginTop:"3%"}}/>
         {this.renderRunReportTable()}        
         {openRunReport ?
          <div style={{ marginTop: "16px", display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <Button style={{...webStyle.modalBtn,backgroundColor:"#F0F0F0",textTransform:"capitalize",padding:"0 3%"}} onClick={()=>{
              this.handleExportReport(reportId);
              // this.handleOpenreportTable()
              this.previewReportClose()}}>
             Export Report
            </Button>
            <Button variant="contained" style={{...webStyle.modalBtn,textTransform:"capitalize",padding:"0 3%"}} onClick={()=>{this.handleOpenRunReport(reportId);}}>
              Run Report
            </Button>
          </div>:
          <div style={{ marginTop: "16px", display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <Button style={{...webStyle.modalBtn,backgroundColor:"#F0F0F0",textTransform:"capitalize",padding:"0 3%"}} onClick={() => {this.handleOpenTemplateModal()}}>
            Save as Template
          </Button>
          <Button variant="contained" style={{...webStyle.modalBtn,textTransform:"capitalize",padding:"0 3%"}} onClick={() => {this.handleExportReport(reportId);}} >
            Export Report
          </Button>
        </div>
        }
          
        </DialogContent>
      </Dialog>
    )
  }

  saveTemplateModal = () => {
    const { openTemplateDialog ,reportId} = this.state;    
    return (
        <Dialog open={openTemplateDialog} onClose={this.handleCloseTemplateModal} fullWidth maxWidth="sm">
        <DialogTitle style={{fontWeight:"bold"}}>
            Save as Template 
            <IconButton
            aria-label="close"
            onClick={this.handleCloseTemplateModal}
            style={{ position: "absolute", right: 8, top: 3,color:"#000" }}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <Divider/>
        <DialogContent>
        
        <Grid item xs={12}>
        <Typography style={webStyle.typography}>Title of Report</Typography>
              <TextField
                value={this.state.templateName}
                onChange={this.handleTemplateTitleChange}
                fullWidth
                placeholder="write title here"
                style={{backgroundColor:"#fff"}}
              />
            </Grid>
        </DialogContent>
        <Divider/>
          <DialogContent style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
            <Button style={{...webStyle.modalBtn,backgroundColor:"#F0F0F0",textTransform:"capitalize",padding:"0 3%"}} onClick={this.handleCloseTemplateModal}>
              Cancel
            </Button>
            <Button variant="contained" style={{...webStyle.modalBtn,textTransform:"capitalize",padding:"0 3%"}} onClick={()=>{this.saveAsTemplateReport(reportId)}}>
              Save Template
            </Button>
          </DialogContent>
      </Dialog>
    )
  }

  tabConfigurations = [
    { label: 'User Analytics', value: 'UserAnalytics' },
    { label: 'Client-Wide Analytics', value: 'ClientWideAnalytics' },
    { label: 'Course Analytics', value: 'CourseAnalytics' },
    { label: 'Feedback Aggregation', value: "Analytics" },
    { label: 'Reports', value: 'ReportsAnalytics' },
  ];

  /* istanbul ignore next */ 
  renderHeader=()=>{
    return(
      <>
       <Box  justifyContent={"space-between"} display={"flex"} alignItems={"center"} minHeight={"90px"}>
              <Box alignItems={"center"} display={"flex"} gap="10px">
                <Typography
                  style={{  fontSize: '24px', lineHeight: '32px', color: '#1C1C1C',fontFamily: 'Heebo', fontWeight: '700' }}
                >Analytics</Typography>
                <>
                  <Typography
                    style={{ fontWeight: '700', lineHeight: '24px', color: '#475569',fontSize: '16px', fontFamily: 'Heebo',  }}> Jul 30 - Aug 5 </Typography>
                  <KeyboardArrowDown />
                </>
              </Box>
             <Box display={"flex"}  gap={"8px"} justifyContent={"space-between"}> 
             <Button
              onClick={()=>{this.handleCreateReport(null,"Add")}}
              style={{
                height: '100%',
                display: 'flex', textAlign: 'center', gap: '10px',
                alignItems: 'center', fontWeight: '700',
                padding: '10px 16px', borderRadius: '4px',
                fontSize: '16px', lineHeight: '24px',
                backgroundColor: '#000', color: '#fff',
                textTransform: 'none',
                width:"100%"
              }}
               >
               <AddCircleOutline />
             <Typography
               style={{  fontSize: '14px', color: '#fff', fontWeight: '700' }}
             >
               Create New Report
             </Typography>
             </Button>
             </Box>

            </Box>
            <Box width={"fit-content"}>
            <AppBar style={{ maxHeight:'60px',padding:'10px' ,width: "60vw", boxShadow: 'none', borderRadius: '4px' }} color="secondary" position="static" >
                <Tabs
                  value="ReportsAnalytics"
                  aria-label="analytics tabs"
                  style={{ gap: '10px' }}
                  data-testID="Tabs"
                  textColor="secondary"
                  TabIndicatorProps={{
                    style: {
                      display: 'none'
                    },
                  }}
                >
                  {this.tabConfigurations.map((tab, index) => (
                    <Tab
                      key={index}
                      data-test-id={`tab-${index}`}
                      onClick={() => this.handleChangeTabAnalytics(tab.value)}
                      label={
                        <Typography
                            sx={{
                              width:'max-content',
                              textTransform: 'none',
                              fontSize: '12px',
                              lineHeight: '18px',
                              padding: '12px 0',
                              fontFamily: 'Heebo-bold',
                              fontWeight: '700',
                            }}
                          >
                            {tab.label}
                          </Typography>
                      }
                      value={tab.value}
                      sx={{
                        borderRadius: '4px',
                        color: tab.value === 'ReportsAnalytics' ? 'white' : '#6A6A6A',
                        backgroundColor: tab.value === 'ReportsAnalytics' ? 'black' : 'inherit',
                        boxSizing:'border-box',
                        flex:1,
                        padding:'0 16px',
                        '&.Mui-selected': {
                          backgroundColor: 'black',
                          color: 'white',
                          borderBottom: 'none',
                        },
                        minHeight:0,
                        height:'100%',
                        maxHeight:'60px',
                      }}
                    />
                  ))}
                </Tabs>
              </AppBar>
            </Box>
      </>
    )
  }

 // Customizable Area End
  render() {
   // Customizable Area Start
    {/* istanbul ignore next */}
    const { anchorEl, menuRowId, rowsPerPage, Reportdata,reportModal ,metaData,openReportTable,reportPreview } = this.state;
    const open = Boolean(anchorEl); 
    const startResult = metaData.page * rowsPerPage + 1;
    const endResult = Math.min((metaData.page + 1) * rowsPerPage, metaData.total_count);
    const total_pages = Math.ceil(16 / rowsPerPage)
   // Customizable Area End
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box display={"flex"} overflow={'hidden'}>
          <Box>
            <Sidebar navigation={this.props.navigation} current="analytics" />
          </Box>
          <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"16px"} padding={"24px 18px"}  sx={{backgroundColor:'#F0F0F0', overflow:'auto'}}>
           {this.renderHeader()}
            <Box paddingTop={"50px"}>
            <div>
         {reportModal === true ?
         (<div>
        {this.CreateReportAnalytics()}
         </div>)
        :openReportTable ?
        (<div>
        {/* istanbul ignore next */}
        <Paper sx={{ padding: "16px 16px 0px 16px"}}>
        <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}> 
            <Typography variant="h6" style={{fontWeight:"bold"}} gutterBottom>
            MUI Reports
            </Typography>
            <IconButton
               onClick={(e) => this.handleMenuOpen(e, 1)}>
                <MoreVertIcon />
             </IconButton>
                      {menuRowId === 1 && (
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={this.handleMenuClose}
                        >
                          <MenuItem onClick={this.handleMenuClose}>Edit</MenuItem>
                          <MenuItem style={{color:"red"}} onClick={this.handleMenuClose}>Delete</MenuItem>
                        </Menu>
                      )}
         </Box>
         {this.renderRunReportTable()}
        </Paper>
        </div>)
        :(
        <>
           <Typography variant="h6" style={{color:"#475569",fontWeight:"bold"}} gutterBottom>
            Saved Reports
            </Typography>
         {/* istanbul ignore next */}
        {Reportdata && Reportdata.length > 0 ? (
        <Paper sx={{ padding: "16px"}}>
        <TableContainer>
          <Table sx={{ minWidth: 750}}>
            <TableHead>
              <TableRow>
                <TableCell style={webStyle.tableHeader}>Report Name</TableCell>
                <TableCell style={webStyle.tableHeader}>Reporting Area</TableCell>
                <TableCell style={webStyle.tableHeader}>Created By</TableCell>
                <TableCell style={webStyle.tableHeader}>Date of Creation</TableCell>
                <TableCell style={{...webStyle.tableHeader,textAlign:"center"}}>Action</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Reportdata && Reportdata.length > 0 && Reportdata.map((row:any) => {
                  const {attributes}=row
                  return(
                  <TableRow key={row.id}>
                    <TableCell>{attributes.name}</TableCell>
                    <TableCell>{attributes.report_type	}</TableCell>
                    <TableCell>{attributes.created_by	}</TableCell>
                    <TableCell>{moment(attributes.created_at).format("MM/DD/YYYY")}</TableCell>
                    <TableCell style={{textAlign:"center"}}>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ marginRight: "8px",textTransform:"capitalize",fontWeight:"600" }}
                        onClick={()=>{
                          {/* istanbul ignore next */}
                            this.handleOpenRunReport(row.id)
                        }}
                      >
                        Run Report
                      </Button> 
                    </TableCell>
                    <TableCell>
                    <IconButton
                        onClick={(e) => this.handleMenuOpen(e, row.id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {menuRowId === row.id && (
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={this.handleMenuClose}
                        >
                          <MenuItem onClick={()=>{this.handleMenuClose();this.handleCreateReport(row,"Edit")}}>Edit</MenuItem>
                          <MenuItem onClick={()=>{this.cloneReport(row.id)}}>Clone</MenuItem>
                          <MenuItem style={{color:"red"}} onClick={()=>{this.deleteReport(row.id)}}>Delete</MenuItem>
                        </Menu>
                      )}
                    </TableCell>
                  </TableRow>
                )})}
            </TableBody>
          </Table>
        </TableContainer> 
        <Table>
            <TableFooter>
              <TableRow>
                  <TableCell colSpan={7} align="center" style={{borderBottom:0}} >
                    <div style={webStyle.pagination}>
                      <div  style={{ width:"20%"}}></div>
                      <div  style={{ justifyContent: 'center',display: 'flex', width:"60%"}}>
                                  <CustomPagination
                                    data-test-id="pagination"
                                    count={total_pages}
                                    page={metaData.current_page}
                                    onChange={this.handlePageChange}
                                    siblingCount={1}
                                    boundaryCount={1}
                                  />
                      </div>
                      <div  style={{ textAlign: 'right' ,width:"20%" }}>
                                  {`${startResult}-${endResult} of ${metaData.total_count} results`}
                      </div>
                    </div>
                  </TableCell>
              </TableRow>
            </TableFooter> 
        </Table>
        </Paper>)
        :(
        <div style={{textAlign:"center"}}>
          <Typography variant="h6" style={{color:"#6A6A6A",textAlign:"center",padding:"2% 0%"}}>You dont have any saved reports</Typography>
          <Button
            onClick={()=>{this.handleCreateReport(null,"Add")}}
             style={{ fontFamily: 'Heebo', padding: '10px 16px', borderRadius: '4px', fontWeight: '700', fontSize: '16px', lineHeight: '24px', backgroundColor: '#000', color: '#fff', textTransform: 'none' }}>
             <AddCircleOutline />
           <Typography
             style={{  fontSize: '14px', color: '#fff',fontFamily: 'Heebo', fontWeight: '700' }}
           >
             Create New Report
           </Typography>
           </Button>
          </div>
        )}
        </>
        )
        }
      </div>
            </Box>
          </Box>
        </Box>
        {this.reportPreviewModal()}
        {this.saveTemplateModal()}
        <AdminFooter/>
      </ThemeProvider>
    </StyledEngineProvider>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: '#fff'
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
    tableHeader:{
        color:"#475569",
        fontWeight:"600"
    },
    modalBtn:{
        height:"50px",
        fontWeight:"bold"
    },
    typography:{
      marginBottom:"1%",
      color:"#475569",
      fontWeight:"600"
    },
    pagination:{
      alignItems: 'center',
      justifyContent:"space-between",
      display:"flex",
      width:"100%"
    }

  };
// Customizable Area End
