import React from "react";

// Customizable Area Start
import Header from '../../../components/src/Header.web';
import {
    Box,
    Button,
    Checkbox,
    Typography,
} from "@mui/material";
import {
    styled
} from '@mui/material/styles';
import HtmlReactParser from 'html-react-parser';
import Loader from "../../../components/src/Loader.web";
import { arrowBackIcon } from './assets';
// Customizable Area End

import TermsConditionsUserPartController, {
    Props,
    configJSON,
} from "./TermsConditionsUserPartController";
import { imgcheckboxIcon } from "./assets";

export default class TermsConditionsUserPart extends TermsConditionsUserPartController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderHeaderMobile = (currentPage: string, onBack: () => void) => {
        return (
            <Box sx={styles.headerMobileContainer}>
                <Button
                    sx={styles.headerMobileBackBtn}
                    data-test-id="backBtn"
                    onClick={onBack}
                >
                    <img style={styles.headerMobileBackIcon} src={arrowBackIcon} alt="back" />
                </Button>
                <Typography style={styles.headerMobileTitle}>
                    {currentPage}
                </Typography>
                <Box sx={styles.headerMobileBox}></Box>
            </Box>
        );
    }

    renderTermsAndConditions = () => {
        const { isMobile } = this.state;
        return (
            <Box sx={{ ...styles.container, ...(isMobile ? styles.containerMobile : {}) }}>
                <Box sx={styles.content}>
                    <Box sx={styles.title}>Terms and conditions</Box>
                    <Box sx={styles.textContent}>{HtmlReactParser(this.state.termsCondsValue)}</Box>

                    {!this.state.isUserAgreeTermsConds && this.state.isloggedIn &&
                        <Box sx={styles.agreeBlock}>
                            <Box sx={{ display: "flex", gap: "8px" }}>
                                <Checkbox
                                    data-test-id="checkbox-agree"
                                    style={styles.checkBox}
                                    checked={this.state.checkedAgree}
                                    icon={<img src={imgcheckboxIcon}></img>}
                                    onChange={(event) => this.setAgree(event.target.checked as boolean)}
                                    color="primary"
                                />
                                <Box sx={styles.checkBoxText}>I agree to the Terms and conditions and Privacy Policy</Box></Box>
                            <Box sx={styles.buttons}>
                                <BackButton>Back</BackButton>
                                <AgreeButton disabled={this.state.loading} data-test-id="agree-button" onClick={() => this.onHandleAgree()}>Agree</AgreeButton>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
        );
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { isMobile } = this.state;
        return (
            <div>
                <Loader loading={this.state.loading}></Loader>
                {!isMobile ? (
                    <Header
                        navigation={this.props.navigation}
                        islogin={this.state.isloggedIn}
                        isHeader={false}
                    >
                        {this.renderTermsAndConditions()}
                    </Header>
                ) : (
                    <>
                        {this.renderHeaderMobile('Terms and conditions', () => this.props.navigation.navigate('LandingPage'))}
                        {this.renderTermsAndConditions()}
                    </>
                )}
            </div>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

const styles = {
    container: {
        display:'flex',
        justifyContent:'center',
        alignItems:"center",
        padding:'30px 0'
    },
    containerMobile: {
    },
    content: {
        width:'90%'
    },
    title: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.005em",
    },
    textContent: {
        padding: "24px 0",
        fontFamily: "Heebo",
        fontSize: "16px",
        lineHeight: "24px",
        "& img": {
            maxWidth: '100%',
            width: "auto",
            height: "revert-layer",
        }
    },
    agreeBlock: {

    },
    checkBox: {
        padding: 0,
        margin: 0,
        color: "#64748B",
        width: "20px",
        height: "20px",
    },
    checkBoxText: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
    },
    buttons: {
        padding: "24px 0 0 0",
        display: "flex",
        gap: "24px"
    },
    headerMobileContainer: {
        position: 'sticky',
        top: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '6px 16px',
        backgroundColor: '#FFFFFF',
        zIndex: '1000',
    },
    headerMobileBackBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '48px',
        minWidth: '48px',
        color: '#0F172A',
        borderRadius: '50%',
    },
    headerMobileBackIcon: {
        maxWidth: '24px',
        maxHeight: '24px',
    },
    headerMobileTitle: {
        fontFamily: 'Heebo-Regular',
        fontSize: 14,
        fontWeight: '700',
        textTransform: 'uppercase' as 'uppercase',
        color: '#0F172A',
    },
    headerMobileBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '48px',
    },
}

const AgreeButton = styled(Button)({
    width: 240,
    background: "#000000",
    padding: "10px 16px 10px 16px",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontFamily: "Heebo",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    "&:hover": {
        background: "#000000",
    }
})

const BackButton = styled(Button)({
    width: 240,
    background: "#0000001A",
    padding: "10px 16px 10px 16px",
    borderRadius: "4px",
    fontFamily: "Heebo",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#000000",
    textTransform: "none",
    "&:hover": {
        background: "#0000001A",
    }
})

// Customizable Area End  