import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
import axios from "axios";

interface Image {
  id: number;
  url: string;
}

interface Course {
  id: number;
  title: string;
  duration: number;
  description: string;
  passing_percentage: number;
  lessons: number;
  images: Image[];
}

interface LearningPath {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    created_at: string;
    updated_at: string;
    courses: Course[];
  };
}

interface ApiResponse {
  client_id: string;
  learning_paths: any[];
}

interface Course2 {
  id: number;
  type: string;
  attributes: {
    title: string;
    author_name: string;
    description: string;
    duration: number;
    passing_percentage: number;
    course_requirements: string;
    video: string;
    images: Image[];
    thumbnail_image:'';
    course_modules: any[];
    lesson_count: number;
    learning_paths: {
      id: number;
      title: string;
    }[];
    assign_course_id: string;
  };
}

interface CoursesResponse {
  courses_count: number;
  learning_path_courses: {
    data: Course2[];
  };
  error?:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isEditing: boolean,
  editingType: string,
  editorState: any,
  allContent: any,
  assignedCourses: any;
  adminGreetingTitleHTML?: string | any,
  adminGreetingDescriptionHTML?: string | any,
  providerLogoHTML?: string | any,
  adminGreetingTitleBgColor?: string | any,
  adminGreetingDescriptionBgColor?: string | any,
  providerDescriptionBgColor?: string | any,
  providerDescriptionHTML?: string | any
  selectedBlock: any | null,
  previousHTML: {
    adminGreetingTitle: string | any,
    adminGreetingDescription: string | any,
    providerLogo: string | any,
    providerDescription: string | any,
  }
  loading: boolean,
  showSaveButton: boolean,
  keysToDelete: any,
  ClientName: string | null,
  selectedPath: string | null;
  allCourses: any[];
  response: ApiResponse | null
  showPreview:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ClientContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
    ];

    this.state = {
      isEditing: false,
      editingType: '',
      editorState: null,
      selectedBlock: null,
      allContent: {
        attributes: {
          context: "Here is the context",
          company_logo_context: "here is company logo context"
        }
      },
      assignedCourses: [],
      previousHTML: {
        adminGreetingTitle: '',
        adminGreetingDescription: '',
        providerLogo: '',
        providerDescription: '',
      },
      adminGreetingTitleHTML: null,
      adminGreetingDescriptionHTML: null,
      providerLogoHTML: null,
      providerDescriptionHTML: null,
      adminGreetingDescriptionBgColor:'#FFF',
      adminGreetingTitleBgColor:'#F0F0F0',
      providerDescriptionBgColor:'#FFF',
      loading: false,
      showSaveButton: false,
      keysToDelete: [],
      ClientName: "Client",
      selectedPath: null,
      allCourses: [],
      response: null,
      showPreview:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getContentApiCallId:
            this.handleContentResponse(responseJson, false);
            break;
          case this.getCoursesApiCallId:
            this.handleCoursesResponse(responseJson);
            break;
          case this.updateContentApiCallId:
            this.handleContentResponse(responseJson, true);
            break;
          case this.getAllCoursesApiCallId:
            this.handleAllCoursesResponse(responseJson);
            break;
          case this.getSelectedClientDetailsApiCallId:
            this.handleGetSelectedClientResponse(responseJson);
            break;
        }
        this.setState({ loading: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getContentApiCallId: string = "";
  updateContentApiCallId: string = "";
  getCoursesApiCallId: string = "";
  getAllCoursesApiCallId: string = "";
  getSelectedClientDetailsApiCallId: string = "";

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getContent();
    this.getClientName();
    this.getAllCourses();
    this.getClientSelectedDetail();
  }

  getClientName = async () => {
    const company_name = localStorage.getItem('SelectedClientName')
    this.setState({ ClientName: company_name })
  }

  handleEditorChange = (editorState: any, type: string = '') => {
    this.setState({ editorState });
    switch (type) {
      case 'admin_greeting_title': this.setState({ adminGreetingTitleHTML: editorState });
        break;
      case 'admin_greeting_desc': this.setState({ adminGreetingDescriptionHTML: editorState });
        break;
      case 'provider_desc': this.setState({ providerDescriptionHTML: editorState });
        break;
      default:
    }
  }
  handleBgColorChange = (editorState: any, type: string = '') => {
    this.setState({ editorState });
    switch (type) {
      case 'admin_greeting_title': this.setState({ adminGreetingTitleBgColor: editorState.hex });
        break;
      case 'admin_greeting_desc': this.setState({ adminGreetingDescriptionBgColor: editorState.hex });
        break;
      case 'provider_desc': this.setState({ providerDescriptionBgColor: editorState.hex });
        break;
      default:
    }
  }

  extractTags = (htmlString: string, type: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    let data;
    if (type == 'img') {
      data = Array.from(doc.getElementsByTagName('img'));
    } else {
      data = Array.from(doc.getElementsByTagName('p'));
    }
    return data;
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getContent = async () => {
    this.setState({ loading: true });
    const selectedId = localStorage.getItem('SelectedClientID')
    this.getContentApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_content_management/landing_pages/show_content?client_id=${selectedId}`,
      token: localStorage.getItem("token"),
    });
  }
  handleGetSelectedClientResponse=(res:any)=>{
    this.setState({providerLogoHTML:res.data.attributes.company_logo.url,})
  }
  getClientSelectedDetail = () => {
    let token = localStorage.getItem('token')
    let selectedClientId = localStorage.getItem('SelectedClientID')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_account_groups/clients/${selectedClientId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.getSelectedClientDetailsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getCourses = async () => {
    this.setState({ loading: true });
    const selectedId = localStorage.getItem('SelectedClientID')
    this.getCoursesApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `/bx_block_categories/learning_paths/courses_by_client?client_id=${selectedId}`,
      token: localStorage.getItem("token"),
    });
  }

  updateContent = async () => {
    this.setState({ loading: true });
    const selectedId = localStorage.getItem('SelectedClientID')
    const reqBody = await this.prepareReqBodyToUpdate();
    this.updateContentApiCallId = await apiCall({
      method: "PATCH",
      contentType: "application/json",
      endPoint: `bx_block_content_management/landing_pages/${selectedId}`,
      body: reqBody,
      token: localStorage.getItem("token"),
    });
    this.setState({ showSaveButton: false })
  }

  handleShowPreview =()=>{
   this.setState({showPreview:true})
  }

  handleClosePreview =()=>{
    this.setState({showPreview:false})
   }

  
  preparedText = (tags: any) => {
    let preparedText: any = '';
    tags.forEach((tag: any) => {
      if (tag.innerHTML?.length) {
        preparedText += tag.outerHTML.replace(/<img[^>]*src="[^"]*"[^>]*>/g, '');
      }
    });
    return preparedText.replace('<p></p>', '');
  }

  convertImageToBase64 = async (imageUrl: any) => {
    const response = await axios.get(imageUrl, {
      responseType: 'arraybuffer'
    });
    const base64 = Buffer.from(response.data, 'binary').toString('base64');
    const base64Image: any = `data:${response.headers['content-type']};base64,${base64}`;
    return base64Image;
  }

  createImage = (src: string) => {
    const imgElement = document.createElement('img');
    imgElement.src = src;
    imgElement.alt = 'wave';
    imgElement.style.maxHeight = '60px';
    imgElement.style.maxWidth = '200px';
    return imgElement;
  }

  prepareReqBodyToUpdate = async () => {
    const {heading,context,company_logo_context}= this.state.allContent.attributes
    const headingTags = this.state.adminGreetingTitleHTML || heading;
    const headingContextTags = this.state.adminGreetingDescriptionHTML ||context;
    const companyLogoContextTags = this.state.providerDescriptionHTML || company_logo_context;
    let reqBody: any = {
      landing_page: {
      }
    }
    if (headingTags.length) {
      reqBody.landing_page['heading'] = this.isAddedToDeleteList('admin_greeting_title') ? "<p></p>" : headingTags.toString();
      reqBody.landing_page['heading_color'] =this.state.adminGreetingTitleBgColor;
    }
    if (headingContextTags.length) {
      reqBody.landing_page["context"] = this.isAddedToDeleteList('admin_greeting_desc') ? "<p></p>" : headingContextTags.toString()
      reqBody.landing_page['welcome_context_color'] = this.state.adminGreetingDescriptionBgColor;
    }
    if (companyLogoContextTags.length) {
      reqBody.landing_page["company_logo_context"] = this.isAddedToDeleteList('provider_desc') ? "<p></p>" : companyLogoContextTags.toString()
      reqBody.landing_page['context_color'] =this.state.providerDescriptionBgColor;
    }
    return reqBody;
  }

  isAddedToDeleteList = (key: string) => this.state.keysToDelete.includes(key);

  handleContentResponse = (response: any, isUpdate: boolean) => {
    if (response?.data) {
      this.setState({ keysToDelete: [] });
      const data = isUpdate ? response.data : response.data[0];
      const {  heading, context, company_logo_context,heading_color,context_color,welcome_context_color } = data?.attributes;
      this.setState({ allContent: data });
      this.setState({ 
        adminGreetingTitleHTML: heading, 
        adminGreetingDescriptionHTML: context,
        providerDescriptionHTML: company_logo_context,
        adminGreetingTitleBgColor: heading_color ? heading_color : this.state.adminGreetingTitleBgColor,
        adminGreetingDescriptionBgColor: welcome_context_color ? welcome_context_color : this.state.adminGreetingDescriptionBgColor,
        providerDescriptionBgColor: context_color ? context_color : this.state.providerDescriptionBgColor
      });
      if (isUpdate) {
        toast.success(response?.meta?.message);
      }
    } else {
      toast.error('Content Not found')
      const data = {
        attributes: {
          heading: 'Type the heading here',
          context: 'Type the context here',
          company_logo_context: 'Type the company context here'
        }
      }
      this.setState({ allContent: data });
    }
  }

  getAllCourses = async () => {
    this.setState({ loading: true });
    const selectedId = localStorage.getItem('SelectedClientID')
    this.getAllCoursesApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_categories/learning_paths/total_courses_by_client?client_id=${selectedId}`,
      token: localStorage.getItem("token"),
    });
  }
 
  preparedCourses = (data: any, fromPackages: boolean = false) => {
    const courses: any[] = [];
    data.forEach((item: any) => {
      const isAvailable = fromPackages ? this.state.allCourses.find(course => course.id == item.id) : this.state.allCourses.find(course => course.id == item.course.id);
      if (isAvailable?.id) {
        courses.push(fromPackages ? item : item.course)
      }
    });
    return courses;
  }

  mergeResponse = (response: any) => {
    const formatedLearningPaths = response.learning_paths.map((path: any) => ({
      id: path.learning_path_id,
      title: path.learning_path_title,
      courses: this.preparedCourses(path.data),
    }));
    const formatedCoursePackages = response.course_packages.map((path: any) => ({
      id: path.learning_path_id,
      title: path.learning_path_title,
      courses: this.preparedCourses(path.course, true)
    }));
    return formatedLearningPaths.concat(formatedCoursePackages);
  }

  handleCoursesResponse = (response: any) => {
    if (!response.message && response.client_id) {
      const learningPaths = this.mergeResponse(response);
      const updatedResponse = {
        client_id: response.client_id,
        learning_paths: learningPaths
      } 
      this.setState({ response: updatedResponse });
    } else {
      toast.error(response.message)
    }
  }

  handleAllCoursesResponse = (responseJson: CoursesResponse) => {
    if (!responseJson.error) {
      const allCourses = responseJson.learning_path_courses.data.map(course => ({
        id: course.id,
        title: course.attributes.title,
        duration: course.attributes.duration,
        description: course.attributes.description,
        passing_percentage: course.attributes.passing_percentage,
        lessons: course.attributes.lesson_count,
        images: course.attributes.thumbnail_image || [],
      }));
      this.setState({ allCourses });
      this.getCourses();
    } else {
      toast.error(responseJson.error)
    }
  }

  handlePathClick = (pathId: string | null ) => {
    this.setState({ selectedPath: pathId });
  };

  handleStyle = (styles:any, pathId:string | null)=>{
    return this.state.selectedPath === pathId  ? styles.blackButton : styles.learningPathBtn
  }
  
  courseImgUrl = (course: any) => {
    const { selectedPath, allCourses } = this.state;
    const imgURL = selectedPath ? allCourses?.find(item => item.id == course.id)?.images?.[0]?.url : course?.images?.[0]?.url;
    return imgURL;
  }
  // Customizable Area End
}
