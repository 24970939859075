import React from "react";

// Customizable Area Start
import { AppBar, Tab, Tabs, Box, Button, Typography, Select,Card,CardContent,Rating,Divider, TextField, FormControl, MenuItem, IconButton, InputAdornment, Modal, Container, Grid, ListItemText, ListItemIcon, Checkbox, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import { KeyboardArrowDown, Search, Close, AddCircleOutline, DeleteOutline, ExpandMore, ArrowForwardIos, Delete } from '@mui/icons-material';
import AdminFooter from '../../../../packages/components/src/AdminFooter.web';
import { DeleteIcon } from "./assets";

 /* istanbul ignore next */
const  StyledMenuItem = styled(MenuItem)(({ theme,selected }) => ({
  padding: 0,
  borderRadius: "2px",
  backgroundColor:"#fff",
  margin:"10px",
  "&:hover": {
    backgroundColor: "#fff",
  },
  "& .MuiTypography-root": {
    fontSize: "14px",
    fontWeight: "500",
    color:"#000",
    marginLeft:"10px",
    width:"225px",
    textWrap:"auto"
  }
}));
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

   /* istanbul ignore next */
  FeedbackAggregation = () => {
    const {feedbackForms ,filteredForms} = this.state;
    // const feedbackData= selectedCourse.id !== "0" ? filteredForms : feedbackForms
    const feedbackData =[
      { form: 'UX Design', questions: 3, responses: 99, courses: 3 },
      { form: 'Creative Arts', questions: 4, responses: 65, courses: 5 },
      { form: 'Engineering Course', questions: 3, responses: 23, courses: 2 },
    ]
    return (
      <Box width={"100%"}>
      <Box sx={{ padding:"3% 0px"}}>
        <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography style={webStyle.tableHeader}>
                Feedback Forms
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
                Number of Questions
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
                Number of Responses
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
                Assigned Courses
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {feedbackData.map((row:any, index:any) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="body2" fontWeight="bold">{row.form}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {row.questions}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {row.responses}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {row.courses}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <IconButton style={{color:"#000",fontWeight:"bold"}} 
                onClick={()=>this.handlefeedbackFormOpen(row.id)}
                  >
                  <ArrowForwardIos fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </Box>

      </Box>
    );
  };
  
  /* istanbul ignore next */
  FeedbackReviewModal = () => {
    return (
      <Modal open={this.state.reviewModal} onClose={this.handleReviewModalClose}>
        <Container
          sx={{
            boxShadow: "0px 8px 32px 0px #0000000F",
            width: '640px',
            minWidth: '380px',
            padding: '24px',
            borderRadius: '8px 8px 32px 8px',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            height:"100%",
             overflow:"auto"
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #E2E8F0">
            <Typography
              style={{
              
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              New Feedback Form
            </Typography>
            <IconButton style={{color:"#000",fontWeight:"bold"}} data-testID="close-Modal" onClick={this.handleReviewModalClose}>
              <Close />
            </IconButton>
          </Box>
          <Box sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowY: 'auto',
            gap: '24px'
          }}>
            <FormControl fullWidth>
              <Typography
                style={{marginBottom:"1%", fontWeight: '700', fontSize: '14px', color: '#64748B' }}
              >Course</Typography>
              <Select
                labelId="client-select-label"
                id="client-select"
                placeholder="Select Course"
                value={this.state.selectedCourse.id}
                onChange={this.handleSelectedCourse}
                data-testID="clientSelect"
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>{this.state.selectedCourse.id =="0"?"Select Course":this.state.selectedCourse.name}</span>
                    <ExpandMore sx={{ color: 'white' }} />
                  </Box>
                )}
              >
                {this.state?.courseData?.map((course) => (
                 <StyledMenuItem key={course.id} value={course.id}
                 sx={{
                   display: "flex",
                   alignItems: "center",
                   gap: "8px",
                   backgroundColor:"#fff"
                 }}
                 >
                  <ListItemText
                  primary={course.name}
                  primaryTypographyProps={{
                 fontSize: "14px",
                 }}
                />
                <ListItemIcon sx={{ minWidth: "32px" }}>
               <Checkbox
                 checked={this.state.selectedCourse.id === course.id}
                 value={course.id}
                 name="filter-radio-group"
                 sx={{
                   color: "#000",
                   "&.Mui-checked": { color: "#000" },
                 }}
               />
               </ListItemIcon>

                 </StyledMenuItem>
                ))}
              </Select>
            </FormControl>
            <Box>
              <Typography
                style={{ fontWeight: '700', fontSize: '14px', color: '#334155',marginBottom:"1%" }}
              >Name of Feedback Form</Typography>
              <TextField placeholder="Builder's Feedback Form" data-testID="formName" fullWidth value={this.state.feedbackFormName} onChange={this.handleFeedbackFormName} />
            </Box>
            <Box>
              <Button data-testID="addQuestion" style={{ padding: "0px", display: 'flex', gap: '6px', textTransform: 'none' }} onClick={this.handleAddQuestion}>
                <AddCircleOutline />
                <Typography
                  style={{ fontWeight: '700', fontSize: '14px', color: '#1C1C1C' }}
                >
                  Add question
                </Typography>
              </Button>
            </Box>

            <Box>
              {this.state.questions.map((question, index) => (
                <Box key={index} display={"flex"} flexDirection={"column"}
                  justifyContent={"space-between"} gap={"24px"} padding={"10px 0px"}>
                  <Typography
                    style={{ fontWeight: '700', fontSize: '14px', color: '#334155' }}
                  >Question</Typography>
                  <TextField
                    placeholder="Ease of use"
                    data-testID="question"
                    fullWidth
                    value={question.question}
                    onChange={(e) => this.handleQuestionChange(index, 'question', e.target.value)}
                  />
                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box display={"flex"} alignItems="center" gap={"10px"}>
                      <Typography
                        style={{ fontWeight: '700', fontSize: '14px', color: '#334155' }}
                      >Required: </Typography>
                      <Select
                        style={{ border: '1px solid #1C1C1C' }}
                        value={question.required ? 'Yes' : 'No'}
                        onChange={(e) => this.handleQuestionChange(index, 'required', e.target.value === 'Yes')}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </Box>
                    <Box display={"flex"} alignItems="center" gap={"10px"}>
                      <Typography
                        style={{
                         fontWeight: '700', fontSize: '14px', color: '#334155'
                        }}>Type: </Typography>
                      <Select
                        style={{ border: '1px solid #1C1C1C' }}
                        value={question.type}
                        onChange={(e) => this.handleQuestionChange(index, 'type', e.target.value)}
                      >
                        <MenuItem value="free-text">Free text</MenuItem>
                        <MenuItem value="rating">Numeric 1-10</MenuItem>
                      </Select>
                    </Box>
                    <Box>
                      <IconButton data-testID={`delete${index}`} disabled={index == 0} onClick={() => this.handleDeleteQuestion(index)}>
                        <DeleteOutline style={{ color: 'red' }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <Box display={"flex"} gap={"24px"} paddingTop={"10px"} justifyContent={"end"} borderTop="1px solid #E2E8F0">
            <Button
              style={{
                backgroundColor: '#F0F0F0',
                color: '#1C1C1C',
                padding: '16px 32px',
                borderRadius: '8px',
                textTransform: 'none', fontWeight: '700', fontSize: '16px',
              }}
              data-testID="closeModal"
              onClick={this.handleReviewModalClose}>Cancel</Button>
            <Button style={{
              backgroundColor: '#1C1C1C',
              color: 'white',
              textTransform: 'none',
              padding: '16px 32px',
              borderRadius: '8px', fontWeight: '700', fontSize: '16px',
            }}
            data-testID="saveBtn"
              onClick={this.handleSave}
            >
              Save
            </Button>
          </Box>
        </Container>
      </Modal>
    )
  }
  /* istanbul ignore next */
  FeedbackSummaryQuestion = () => {
    const { reviewSummary } = this.state;
    const questionData = {
      question: 'How would you rate the content quality?',
      averageRating: '8.7/10',
      responses: 9,
    };
    
    const userData = [
      { name: 'Adrien Elba', rating: '9/10' },
      { name: 'Bessie Cooper', rating: '8/10' },
      { name: 'Eleanor Pena', rating: '8/10' },
      { name: 'Wade Warren', rating: '9/10' },
      { name: 'Robert Fox', rating: '8/10' },
      { name: 'Annette Black', rating: '7/10' },
      { name: 'Dianne Russell', rating: '7/10' },
      { name: 'Jerome Bell', rating: '9/10' },
      { name: 'Ronald Richards', rating: '10/10' },
    ];
    return (
      <>
     <TableContainer component={Paper} style={{marginTop:"3%"}}>
      <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography style={webStyle.tableHeader}>
              Questions
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography style={webStyle.tableHeader}>
              Average Rating
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography style={webStyle.tableHeader}>
              Number of Responses
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography variant="body2" fontWeight="bold">{questionData.question}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2" fontWeight="bold">
              {questionData.averageRating}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2" fontWeight="bold">
              {questionData.responses}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
     </TableContainer>
     <TableContainer component={Paper} style={{marginTop:"7%"}}>
     <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography style={webStyle.tableHeader}>
              Name of User
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography style={webStyle.tableHeader}>
              Rating
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {userData.map((user, index) => (
          <TableRow key={index}>
            <TableCell>
              <Typography variant="body2" fontWeight="bold">{user.name}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" fontWeight="bold">
                {user.rating}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
     </TableContainer>
    </>
    )
  }
  /* istanbul ignore next */
  individualFeedBackForm =()=>{
    const questionData = [
      {
        question: 'How would you rate the content quality?',
        averageRating: '8.7/10',
        responses: 9,
      },
      {
        question: "How would you rate the instructor's effectiveness?",
        averageRating: '8.5/10',
        responses: 11,
      },
      {
        question: 'How engaging was the course?',
        averageRating: '7.5/10',
        responses: 8,
      },
    ];
    
    const overallRating = '8.23';
    
    const userData = [
      { name: 'Adrien Elba Adrien Elba', rating: '9/10', comment: 'Great content, very well organized with clear explanations.' },
      { name: 'Bessie Cooper', rating: '8/10', comment: 'Good examples provided, though a few more would enhance understanding.' },
      { name: 'Eleanor Pena', rating: '8/10', comment: 'Satisfactory overall, but some sections felt a bit repetitive.' },
      { name: 'Wade Warren', rating: '9/10', comment: 'Excellent and very engaging content, kept my attention throughout.' },
     ];
    return(
      <>
      <TableContainer component={Paper} style={{marginTop:"3%"}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography style={webStyle.tableHeader}>
                Questions
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
                Average Rating
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
                Number of Responses
              </Typography>
            </TableCell>
            <TableCell align="center">
              <img src={DeleteIcon} alt="delete"/>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questionData.map((item, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="body2" fontWeight="bold">{item.question}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {item.averageRating}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {item.responses}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <IconButton style={{color:"#000",fontWeight:"bold"}}
                onClick={()=>this.handlefeedbackQuestionOpen(item)}
                >
                  <ArrowForwardIos fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={1}>
              <Typography style={{fontSize:"18px"}} fontWeight="bold">
                Overall Rating
              </Typography>
            </TableCell>
            <TableCell align="center" colSpan={1}>
              <Typography style={{fontSize:"18px"}} fontWeight="bold">
                {overallRating}
              </Typography>
            </TableCell>
            <TableCell align="left" colSpan={2}>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </TableContainer>

      <TableContainer component={Paper} style={{marginTop:"7%"}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography style={webStyle.tableHeader}>
                Name of User
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
                Average Rating
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={webStyle.tableHeader}>
                Additional Comment
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userData.map((user, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="body2" fontWeight="bold">{user.name}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {user.rating}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{user.comment}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
       </Table>
      </TableContainer>
      </>
    )
  }

  /* istanbul ignore next */
  renderFeedback =()=>{
    const { selectedCourse, courseData ,sortBy ,duration,summaryModal,questionSummaryModal} = this.state;
    return(
      <Box display={"flex"} overflow={'hidden'}>
            <Box>
              <Sidebar navigation={this.props.navigation} current="analytics" />
            </Box>
            <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"16px"} padding={"24px 18px"}  sx={{backgroundColor:'#F0F0F0', overflow:'auto'}}>
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} minHeight={"90px"}>
                <Box display={"flex"} gap="10px" alignItems={"center"}>
                  <Typography
                    style={{ fontFamily: 'Heebo',fontWeight: '700', fontSize: '24px', lineHeight: '32px', color: '#1C1C1C' }}
                  >Analytics</Typography>
                  <>
                    <Typography
                      style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '16px', lineHeight: '24px', color: '#475569' }}
                    >Jul 30 - Aug 5</Typography>
                    <KeyboardArrowDown />
                  </>
                </Box>

               <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}>
               <Button
              data-testID="feedbackModal"
              style={{
                height: '100%',
                display: 'flex', textAlign: 'center', gap: '10px',
                alignItems: 'center', fontWeight: '700',
                padding: '10px 16px', borderRadius: '4px',
                fontSize: '16px', lineHeight: '24px',
                backgroundColor: '#000', color: '#fff',
                textTransform: 'none',
                width:"100%"
              }}
              onClick={this.handleReviewModalOpen}
            >
              <AddCircleOutline />
              <Typography
                style={{ fontWeight: '700', fontSize: '14px', color: '#fff' }}
              >
                Create New Review
              </Typography>
              </Button>
               </Box>

              </Box>
              <Box width={"fit-content"}>
              <AppBar position="static" style={{ maxHeight:'60px',padding:'10px' ,width: "60vw", boxShadow: 'none', borderRadius: '4px' }} color="secondary">
                  <Tabs
                    data-testID="Tabs"
                    style={{ gap: '10px' }}
                    value="Analytics"
                    textColor="secondary"
                    TabIndicatorProps={{
                      style: {
                        display: 'none'
                      },
                    }}
                    aria-label="analytics tabs"
                  >
                    {this.tabConfigurations.map((tab, index) => (
                      <Tab
                        key={index}
                        onClick={() => this.handleChangeTabAnalytics(tab.value)}
                        data-test-id={`tab-${index}`}
                        label={
                          <Typography
                              sx={{
                                padding: '12px 0',
                                width:'max-content',
                                fontWeight: '700',
                                textTransform: 'none',
                                fontSize: '12px',
                                fontFamily: 'Heebo-bold',
                                lineHeight: '18px',
                              }}
                            >
                              {tab.label}
                            </Typography>
                        }
                        sx={{
                          padding:'0 16px',
                          minHeight:0,
                          height:'100%',
                          borderRadius: '4px',
                          flex:1,
                          color: tab.value === 'Analytics' ? 'white' : '#6A6A6A',
                          backgroundColor: tab.value === 'Analytics' ? 'black' : 'inherit',
                          boxSizing:'border-box',
                          maxHeight:'60px',
                          '&.Mui-selected': {
                            backgroundColor: 'black',
                            color: 'white',
                            borderBottom: 'none',
                          },
                        }}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
              </AppBar>
              </Box>
              <Box paddingTop={"3%"}>
              <Box display={"flex"} gap={"15px"} alignItems={"center"} height={"50px"}>
              <Box style={{
              width: '100%', height: '100%'
             }}>
            <TextField
              variant="outlined"
              placeholder="Search Form"
              style={{
                borderRadius: '4px',
                border: "#E7E7E7 1px solid",
                backgroundColor:"#fff",
                height:"100%",
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height:"100%",
                  '& fieldset': {
                    borderRadius: '4px',
                  },
                },
              }}
              // value={""}
              data-testID="searchQuery"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
              </Box>
              <div style={{marginTop:"3%", marginBottom: 2,width:"100%",display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}>
              <div style={{width:"30%"}}>
              <FormControl>
              <Select
                labelId="client-select-label"
                id="client-select"
                placeholder="Select Course"
                value={selectedCourse.id}
                onChange={this.handleFilterSelectedCourse}
                IconComponent={ExpandMore}
                style={{minWidth:"235px",width:"100%",backgroundColor:"#fff",color:"#000",fontWeight:"bold",height:"50px"}}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>{selectedCourse.id =="0"?`${selectedCourse.name} (${courseData?.length})`:selectedCourse.name}</span>
                    <ExpandMore sx={{ color: 'white' }} />
                  </Box>
                )}
              >
                <StyledMenuItem  value={"0"} style={{backgroundColor:"#fff"}}>
                <ListItemText
                   primary={"All Courses"}
                   primaryTypographyProps={{
                  fontSize: "14px",
                  }}
                 />
                 <ListItemIcon sx={{ minWidth: "32px" }}>
                <Checkbox
                  checked={selectedCourse.id == "0"}
                  value={"0"}
                  name="filter-radio-group"
                  sx={{
                    color: "#000",
                    "&.Mui-checked": { color: "#000" },
                  }}
                />
                </ListItemIcon>
                </StyledMenuItem>
                {courseData?.map((course) => (
                  <StyledMenuItem key={course.id} value={course.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    backgroundColor:"#fff"
                  }}
                  >
                   <ListItemText
                   primary={course.name}
                   primaryTypographyProps={{
                  fontSize: "14px",
                  }}
                 />
                 <ListItemIcon sx={{ minWidth: "32px" }}>
                <Checkbox
                  checked={selectedCourse.id === course.id}
                  value={course.id}
                  name="filter-radio-group"
                  sx={{
                    color: "#000",
                    "&.Mui-checked": { color: "#000" },
                  }}
                />
                </ListItemIcon>

                  </StyledMenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{...webStyle.gridWidth,flexWrap:"wrap"}}>      
            <FormControl>
              <Select
                value={duration}
                onChange={(e:any) => this.handlefilterChange(e,"duration")}
                name="duration"
                style={webStyle.filterSelect}
                IconComponent={ExpandMore}
              >
                {["Published","Unpublished"].map((item)=>(
                  <MenuItem value={item} style={{fontWeight:duration==item?"bold":"",backgroundColor:"#fff"}}>
                  {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
         
            <FormControl>
              <Select
                value={sortBy}
                onChange={(e:any) => this.handlefilterChange(e,"sortBy")}
                name="sortBy"
                style={webStyle.filterSelect}
                IconComponent={ExpandMore}
              >
                {["Sort by Name","Sort by Responses","Sort by Questions"].map((item)=>(
                   <MenuItem value={item} style={{fontWeight:sortBy==item?"bold":"",backgroundColor:"#fff"}}>
                   {item}
                   </MenuItem>
                ))}
              </Select>
            </FormControl>
         
          </div>
           </div>
                {summaryModal?
                this.individualFeedBackForm()
                :questionSummaryModal ?
                this.FeedbackSummaryQuestion()
                :this.FeedbackAggregation()
                }
              </Box>
            </Box>
          </Box>
    )
  }


  tabConfigurations = [
    { label: 'User Analytics', value: 'UserAnalytics' },
    { label: 'Client-Wide Analytics', value: 'ClientWideAnalytics' },
    { label: 'Course Analytics', value: 'CourseAnalytics' },
    { label: 'Feedback Aggregation', value: "Analytics" },
    { label: 'Reports', value: 'ReportsAnalytics' },
  ];

  // Customizable Area End

  render() {
  // Customizable Area Start
  // Customizable Area End
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {this.renderFeedback()}
          {this.FeedbackReviewModal()}
          <AdminFooter/>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: '#fff'
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  filterSelect:{
    width:"100%",
    minWidth:"195px",
    backgroundColor:"#fff",
    color:"#000",
    fontWeight:"bold",
    height:"50px"
  },
  gridWidth:{
     display:"flex",
     justifyContent:"end",
     gap:"10px",
     width:"70%",
  },
  tableHeader:{
    color:"#475569",
    fontWeight:"600",
    fontSize:"12px"
},
};
// Customizable Area End
