import React, { Component } from "react";
import {
    Modal, Box, Button, Typography, DialogTitle,
    TextField, Checkbox} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from "@mui/material/styles";

type IProps = {
    isVisible: boolean;
    formData: any;
    onClose: () => void;
    onSubmit: (answers: any,suggestion:string) => void;
};

type IState = {
    isOpen: boolean;
    feedBackForm: any;
    suggestion:any;
};

export default class CustomFeedbackForm extends Component<IProps, IState> {
    ratings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: this.props.isVisible,
            feedBackForm: null,
            suggestion:''
        };
    }

    componentDidMount(): void {
        this.prepareFormData()
    }

    prepareFormData = () => {
        const rawData = this.props.formData.data.attributes?.feedback_questions_attributes || [];
        let feedbackAnswers: any = [];
        rawData.forEach((raw: any) => {
            feedbackAnswers.push({
                id: raw.data.id,
                question: raw.data.attributes.question,
                type: raw.data.attributes.question_type,
                rating: null,
                status: null
            })
        });
        this.setState({ feedBackForm: feedbackAnswers });
    }

    handleAnswers = (item: any, answer: any) => {
        let updatedArray = this.state.feedBackForm.map((que: any) => {
            if (que.id == item.id) {
                return que.type == 'rating' ? { ...que, rating: answer } : { ...que, status: answer }
            }
            return que;
        })
        this.setState({feedBackForm:updatedArray});
    }

    renderRatings = (que: any) => {
        const isSelected = (item: any) => que.rating === item;
        return (
            this.ratings.map((item) => (
                <Box onClick={() => { this.handleAnswers(que, item) }} sx={isSelected(item) ? styles.selectedrRatingBtn : styles.ratingBtn}><Typography color={isSelected(item) ? '#FFFFFF' : '#1C1C1C'} sx={styles.ratingText}>{item}</Typography></Box>
            ))
        )
    }

    renderStatusBox = (que:any) => (
        <Box display={"flex"} flexDirection={'row'} justifyContent={'space-between'} alignContent={'center'}>
            <Box justifyContent={'center'} alignContent={'center'}>
                <Typography className="label-title-assessment" >Yes</Typography></Box>
            <Box>
                <CustomCheckbox
                    data-test-id="yes-checkbox"
                    checked={que.status}
                    onChange={() => {this.handleAnswers(que,true)}}
                    data-testid={`checkbox-retake`}
                />
                </Box>
                <Box justifyContent={'center'} alignContent={'center'} sx={{paddingLeft:'30px'}}>
                <Typography className="label-title-assessment" >No</Typography></Box>
            <Box >
                <CustomCheckbox
                    data-test-id="no-checkbox"
                    checked={que.status === false}
                    onChange={() => {this.handleAnswers(que,false)}}
                    data-testid={`checkbox-retake`}
                />
                </Box>
        </Box>
    )

    renderTextArea=()=>{
        return(
            <TextField
            variant="outlined"
            placeholder="Please provide feedback here"
            data-test-id="suggestion"
            value={this.state.suggestion}
            onChange={(e: any) => {this.setState({suggestion:e.target.value})}}
            multiline
            rows={4}
            sx={{ minHeight: '160px', width:'100%'}}
          /> 
        )
    }

    renderFeedbackModal = () => {
        return (
            <Modal
                open={this.props.isVisible}
                onClose={this.props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Feedback>
                    <FeedbackHeader>
                        <Typography className="title">{this.props.formData?.data?.attributes?.name}</Typography>
                        <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.props.onClose} />
                    </FeedbackHeader>
                    <Box sx={styles.separator}></Box>
                    <FeedbackContent>
                        <Box className="dialog-content">
                            {this.state.feedBackForm?.map((que: any, index:number) => (
                                 <Box data-test-id={'question' + index} sx={styles.queContainer}>
                                    <Typography sx={styles.questionText}>{que.question}</Typography>
                                    <Box sx={styles.ratingWrapper}>
                                        {que.type === 'rating' && this.renderRatings(que)}
                                        {que.type === 'status' && this.renderStatusBox(que)}
                                    </Box>
                                </Box>
                            ))}
                            <Box data-test-id={'question_coment'} sx={styles.queContainer}>
                            <Typography sx={styles.questionText}>{'Please provide any additional feedback or suggestions for improvement'}</Typography>
                                {this.renderTextArea()}
                            </Box>
                        </Box>
                    </FeedbackContent>
                    <FeedbackFooter>
                        <CancelButton onClick={this.props.onClose} sx={{marginRight:'24px'}}>Skip</CancelButton>
                        <SaveButton data-test-id="submit-button" onClick={() => this.props.onSubmit(this.state.feedBackForm, this.state.suggestion)}>Done</SaveButton>
                    </FeedbackFooter>
                </Feedback>
            </Modal>
        );
    }

    render() {
        return (<>
            {this.renderFeedbackModal()}
        </>)
    }
}

const styles = {
    separator: {
        width: '424.35px',
        height: '6px',
        gap: '0px',
        opacity: '0px',
        backgroundColor: '#1C1C1C'
    },
    queContainer: {
        marginBottom: '24px'
    },
    questionText: {
        fontFamily: 'Heebo',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '24px',
        textAlign: 'left'
    },
    ratingWrapper:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        paddingTop:'8px',
    },
    ratingBtn: {
        height:'44px',
        width:'42px',
        backgroundColor: '#FFFFFF',
        borderRadius:'4px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    selectedrRatingBtn: {
        height:'44px',
        borderRadius:'4px',
        width:'42px',
        backgroundColor: '#1C1C1C',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    ratingText: {
        padding: '10px,16px',
        fontFamily: 'Heebo',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '24px',
        textAlign: 'left'
    }
}

const Feedback = styled(Box)({
    width: 640,
    backgroundColor: "#f6f6f6",
    position: 'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    gap: '16px',
    borderRadius: '8px 8px 32px 8px',
    opacity: '0px',
});

const CustomCheckbox = styled(Checkbox)(() => ({
    '& .MuiSvgIcon-root': {
      width: '24px',
      height: '24px',
      backgroundColor: 'white',
      border: '1px solid #A8A29E',
      borderRadius: '4px',
    },
    '&:not(.Mui-checked) .MuiSvgIcon-root': {
      color: 'transparent',
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        backgroundColor: 'black',
        borderColor: 'black',
      },
      '& .MuiSvgIcon-root path': {
        d: 'path("M5 12l5 5L20 7")',
        stroke: 'white',
        strokeWidth: 2,
        fill: 'none',
      },
    },
  }));

const SaveButton = styled(Button)({
    padding: "16px 32px 16px 32px",
    borderRadius: "4px",
    background: "#000000",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
        background: "#000000",
        color: "#FFFFFF",
    }
})

const CancelButton = styled(Button)({
    marginLeft: "15px !important",
    width: "95px",
    textTransform: "none",
    fontFamily: "Heebo-Bold",
    color: "black !important",
    fontSize: "16px !important",
    padding: "12px 28px !important",
    background: "#F0F0F0",
    borderRadius: "8px !important",
    "&:hover": {
        backgroundColor: "#F0F0F0",
    },
});

const FeedbackHeader = styled(DialogTitle)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "22px 40px",
    borderBottom: "1px solid #E2E8F0",
    "& .title": {
        fontFamily: 'Heebo',
        fontSize: '20px',
        fontWeight: "700",
        lineHeight: '28px',
        textAlign: 'left',
    }
})

const FeedbackContent = styled(Box)({
    padding: '16px 40px'
})

const FeedbackFooter = styled(Box)({
    borderTop: "1px solid #E2E8F0",
    padding: "24px",
    display: "flex",
    justifyContent: "flex-end",
})