import React from "react";
import {
    Box, 
    Typography,
    ListItemButton,
    ListItemText,
    Button,
    Grid,
    CircularProgress,Checkbox,
    FormControlLabel,RadioGroup, Radio
} from "@mui/material";
import {Notes,Loop,Menu, Quiz,RadioButtonUnchecked,CheckCircle} from '@mui/icons-material'
import { styled } from "@mui/material/styles";
import CourseLearningController, { Props } from "./CourseLearningController.web";
import AddIcon from '@mui/icons-material/Add';
import Header from "../../../components/src/Header.web";
import {courseLearningBg,dummyImage,dummyVideo,repeatBtnBlack, repeatBtnWhite} from './assets'
import ReactPlayer from 'react-player';
import CarouselCustom from "../../../components/src/CarouselCustom.web";
import {  TestQuestionType } from "./types";
import DOMPurify from 'dompurify';
import CustomFeedbackForm from "../../../components/src/CustomFeedbackForm.web";

export default class CourseLearning extends CourseLearningController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.scrollContainerRef = React.createRef<HTMLDivElement>();
        // Customizable Area End
    }
    renderSideBar = () => {
        const sanitizeData= (htmlString:string) => {
          const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
          return sanitizedDOM.textContent || '';
        };
        const {courseData} = this.state
        if(!courseData || courseData.attributes === undefined) return
        const {attributes} = courseData
        if(!attributes) return
        return (
            <Box flex={1} display={'flex'} flexDirection={'column'} overflow={'auto'}
                sx={{
                    zIndex: 1,
                    width: "100%",
                    backgroundColor: '#F8FAFC',
                    boxShadow: '0px 4px 20px 0px #0000001A inset',
                }}>
                <Box sx={{ backgroundColor: "black" }} padding={'40px 20px'}>
              <Typography color={'white'} fontWeight={'bolder'} fontSize={'24px'}>
                {
                  <p dangerouslySetInnerHTML={{ __html:sanitizeData(attributes.title) }} />
                }
              </Typography>
                </Box>
                <Box>
                    {
                        attributes.course_modules.map((item, index) => {
                            const data = item
                            if(!data) return
                            return (
                              <Box display={'flex'} flexDirection={'column'}>
                                <ListItemButton
                                  key={data.id}
                                  data-test-id={`lesson-test-${data.id}`}
                                  sx={{
                                    backgroundColor: this.state.selectedCourseModuleId === `${data.id}` ? "#F0F0F0" : "inherit",
                                    py: '10px',
                                    minHeight: 32,
                                    color: '#1C1C1C',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                  }}
                                  onClick={this.state.currentCourseModulePosition >= index || this.checkCompletedPercentage(index)
                                    ?
                                    () => { this.handleChooseCourseModule(`${data.id}`); }
                                    :
                                    ()=>{}}
                                >
                                  <Notes style={{ width: '24px', height: '24px' }} />
                                  <ListItemText
                                    primary={data.title}
                                    primaryTypographyProps={{ fontSize: '12px', fontWeight: 'medium', padding: '0 10px' }} />
                                  {this.checkCompletedPercentage(index) && this.state.selectedCourseModuleId!==`${data.id}` ?
                                    <Checkbox
                                      icon={<RadioButtonUnchecked />}
                                      checkedIcon={<CheckCircle  style={{color:'black'}}/>}
                                      checked={true}
                                      sx={{
                                        padding: 0,
                                        height: '26px',
                                        width: '26px',
                                        color:'black',
                                      }}
                                    />
                                    :
                                    <CircularProgress
                                      value={this.state.selectedCourseModuleId === `${data.id}` ? this.state.lessonProgress :0}
                                      variant="determinate"
                                      style={{
                                        height: '25px',
                                        width: '25px',
                                        boxShadow: 'inset 0 0 1px 1px #D9d9d9',
                                        borderRadius: '50%'
                                      }}
                                      sx={{ 'svg circle': { stroke: 'black' } }} />
                                  }
                                </ListItemButton>
                                {data.assessment &&
                                  <ListItemButton
                                    key={data.assessment.id}
                                    data-test-id={`assessment-test-${data.assessment.id}`}
                                    sx={{
                                      backgroundColor: "inherit",
                                      py: '10px',
                                      minHeight: 32,
                                      color: '#1C1C1C',
                                      display: 'flex',
                                    }}
                                    onClick={this.state.currentCourseModulePosition > index || this.checkCompletedPercentage(index) ?
                                      () => { this.handleChooseAssessmentTest(`${data.id}`); }
                                      :
                                      ()=>{}}
                                  >
                                    <Quiz style={{ width: '24px', height: '24px' }} />
                                    <ListItemText
                                      primary={`Assessment Test`}
                                      primaryTypographyProps={{ fontSize: '12px', fontWeight: 'medium', padding: '0 10px' }} />
                                  </ListItemButton>
                                }
                              </Box>
                            );
                        })}
                </Box>
            </Box>
        )
    }
    renderLessonDetail = ()=>{
      const {courseData, courseModuleSelected} = this.state
      if(!courseData || courseData.attributes === undefined) return
      const {attributes} = courseData
        const numberOfCourseModules = attributes?.course_modules.length
        const indexCourseModuleSelected = attributes?.course_modules.findIndex(item=>`${item.id}` === this.state.selectedCourseModuleId)
        return (
            <LessonWrapper flex={4} ref={this.scrollContainerRef}>
                <Box className="lesson-header">
                  <Menu style={{zIndex:1000, position:'absolute',left: 15, top:15}}/>
                    <img 
                    src={this.state?.courseData?.attributes?.course_cover_image?.url ?? courseLearningBg  }   
                    style={webStyles.courseHeadBg}/>
                    <Box zIndex={1} sx={{position:'relative', margin:'auto', }}>
                        <Typography fontStyle={'italic'} fontSize={'14px'} fontFamily={'Crimson Text'}>Lesson {indexCourseModuleSelected + 1} of {numberOfCourseModules}</Typography>
                        <Typography fontWeight={'bold'} fontSize={'40px'} fontFamily={'Crimson Text'}>{courseModuleSelected?.title}</Typography>
                        <Typography fontSize={'14px'} fontFamily={'Crimson Text'}>by {courseData?.attributes?.author_name}</Typography>
                    </Box>
                </Box>
                {this.renderLessonModule()}
                <ModuleWrapper
                  sx={{
                    paddingTop: `30px`,
                    paddingBottom: `30px`,
                    position: 'relative'
                  }}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                >
                  <Box sx={{ width: '50vw' }}>

                    <Button style={buttonStyle.blackButton} data-test-id={`button-lesson`} onClick={this.handleButtonTypeClick}>
                      {!courseModuleSelected?.assessment && indexCourseModuleSelected===numberOfCourseModules - 1?"Finish":"Continue"}
                    </Button>
                  </Box>
                </ModuleWrapper>
            </LessonWrapper>
        )
    }
    renderLessonModule = () => {
      const {courseModuleSelected} = this.state
        const blocksList = courseModuleSelected?.sections[0]?.content_blocks
        if(!blocksList)return
        const nonButtonBlocks = blocksList.filter(block => block.block_type !== 'button');
            const buttonBlocks = blocksList.filter(block => block.block_type === 'button');
        const lessonList = [...nonButtonBlocks,...buttonBlocks]
        return (
            <Box marginTop="50px">
                {lessonList && lessonList.map((item: any, index: number) => {
                    let renderedElement;

                    switch (item.block_type) {
                        case 'text':
                            renderedElement = this.renderTextType(item, index);
                            break;
                        case 'list':
                            renderedElement = this.renderListType(item, index);
                            break;
                        case 'image':
                            renderedElement = this.renderImageType(item, index);
                            break;
                        case 'video':
                            renderedElement = this.renderVideoType(item, index);
                            break;
                        case 'quote':
                            renderedElement = this.renderQuoteType(item, index);
                            break;
                        case 'carousel':
                            renderedElement = this.renderCarouselType(item, index);
                            break;
                        case 'labeled_graphic':
                            renderedElement = this.renderLabelGraphicType(item, index);
                            break;
                        case 'flashcard':
                            renderedElement = this.renderFlashCardType(item, index);
                            break;
                        case 'tabular':
                            renderedElement = this.renderTableType(item, index);
                            break;
                        case 'test_knowledge':
                            renderedElement = this.renderTestKnowledgeType(item, index);
                            break;
                       
                        default:
                            renderedElement = null;
                            break;
                    }
                
                    return (
                      renderedElement && (
                        <Box key={`${index + 1}`}>
                          {renderedElement}
                        
                        </Box>
                      )
                    );
                }
              )}
            </Box>
        )
    }
    renderTextType = (item:any, index: number) => {
        return (
          <ModuleWrapper
            sx={{
              paddingTop: `${30}px`,
              paddingBottom: `${30}px`,
            }}
          >
            <Box width={`70vw`}>
              <div style={{ marginBottom: "30px" }} dangerouslySetInnerHTML={{ __html:item.heading}} className="margin0" />
              <div dangerouslySetInnerHTML={{ __html: item.content }} className="margin0" />
            </Box>
          </ModuleWrapper>
        );
      }
    renderListType = (item:any, index: number) => {
        return (
          <ModuleWrapper
            data-test-id="list-wrapper"
            sx={{
              paddingTop: `${item.paddingTop}px`,
              paddingBottom: `${item.paddingBottom}px`,
            }}
          >
            <Box width={`${item.width}`}>
              <div style={{ marginBottom: "30px" }} dangerouslySetInnerHTML={{ __html: item.heading }} className="margin0" />
              <div dangerouslySetInnerHTML={{ __html: item.content }} className="margin0 list-content" />
            </Box>
          </ModuleWrapper>
        );
      }
    
      
    renderImageType = (item: any, index: number) => {
        return (
          <ModuleWrapper
            data-test-id="image-wrapper"
            sx={{
              paddingTop: `${item.paddingTop}px`,
              paddingBottom: `${item.paddingBottom}px`,
            }}
          >
            <Box width={`${item.width}`}>
              <Box textAlign="center">
                <img src={item.image || item.image_url || dummyImage} style={{maxWidth:'100%'}} className="margin0 image" />
                <Box sx={{ marginTop: "30px" }} dangerouslySetInnerHTML={{ __html: item.caption }} />
              </Box>
            </Box>
          </ModuleWrapper>
        );
      }
    
    renderVideoType = (item: any, index: number) => {
        return (
          <ModuleWrapper
            sx={{
              paddingTop: `${item.paddingTop}px`,
              paddingBottom: `${item.paddingBottom}px`,
            }}
          >
            <Box width={`${item.width}`} height="100%">
              <Box textAlign="center">
                <Box width="100%" height={{ xs: "fit-content", sm: "500px" }}>
                  <ReactPlayer
                    controls
                    url={item.video || item.video_url}
                    width="100%"
                    height="100%"
                    style={{ maxHeight: "500px" }}
                  />
                </Box>
                <Box sx={{ marginTop: "30px" }} dangerouslySetInnerHTML={{ __html: item.caption }} />
              </Box>
            </Box>
          </ModuleWrapper>
        );
      };
    
    renderQuoteType = (item: any, index: number) => {
        return (
          <ModuleWrapper
            sx={{
              paddingTop: `${item.paddingTop}px`,
              paddingBottom: `${item.paddingBottom}px`,
            }}
          >
            <Box width={`${item.width}`}>
              <Box>
                <div style={{ marginBottom: "30px" }} dangerouslySetInnerHTML={{ __html: item.content }} className="margin0" />
                <Box textAlign="center">
                  <img width="88px" height="88px" style={{ borderRadius: "50%", textAlign: "center" }} src={item.image || item.image_url} />
                </Box>
                <div dangerouslySetInnerHTML={{ __html: item.quote_owner_name }} />
              </Box>
            </Box>
          </ModuleWrapper>
        );
      }
    
    renderLabelGraphicType = (item: any, index: number) => {
        return (
          <ModuleWrapper
            data-test-id="image-wrapper"
            sx={{
              paddingTop: `${item.paddingTop}px`,
              paddingBottom: `${item.paddingBottom}px`,
            }}
          >
            <Box width={`${item.width}`}>
              <Box
                textAlign="center"
                sx={{ position: 'relative', overflow: 'hidden' }}
                data-test-id="backgroundLabelImage"
              >
                <img
                  src={item.image || item.image_url || dummyImage}
                  className="margin0 image"
                  alt="Background"
                  style={{ display: 'block', maxWidth: '100%' }}
                />
                {item.labels.map((button: { y_position: any; x_position: any; id: any; visible_content: any; contentRightSide: any; title: any; description: any; }, index2: any) => (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: `${button.y_position}px`,
                      left: `${button.x_position}px`,
                      cursor: 'move',
                      width: 'max-content',
                    }}
                    data-test-id={`draggable-btn-${index2}`}
                    display={'flex'}
                    flexDirection={'column'}
                  >
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} position="relative">
                      <Button
                        style={{
                          ...webStyles.contentBtn,
                        }}
                      >
                        <AddIcon 
                        data-test-id={`show-content-${index2}`} 
                        style={webStyles.plusIcon} 
                        onClick={ ()=>{this.toggleContentVisibility(index2,`${item.id}`,button.visible_content,'visible_content')}} />
                      </Button>
                      <Button
                        data-test-id={`reverse-btn-${index2}`}
                        onClick={()=>{this.toggleContentVisibility(index2,`${item.id}`,button.contentRightSide,'contentRightSide')}}>
                        <Loop style={{ color: "#fff" }} />
                      </Button>
                      <div
                        data-test-id={`content-show-${index2}`}
                        style={{
                          ...webStyles.labelContent,
                          display: button.visible_content ? 'flex' : 'none',
                          position: 'absolute',
                          top: 0,
                          [button.contentRightSide ? 'left' : 'right']: '100%',
                          marginLeft: button.contentRightSide ? '10px' : '0',
                          marginRight: button.contentRightSide ? '0' : '10px',
                        }}
                      >
                        <div style={{ marginBottom: "15px" }} dangerouslySetInnerHTML={{ __html: button.title }} className="margin0" />
                        <div dangerouslySetInnerHTML={{ __html: button.description }} className="margin0" />
                      </div>
                    </Box>
                  </Box>
    
                ))}
              </Box>
            </Box>
          </ModuleWrapper>
        );
      }
      renderCarouselContent = (item: any) => {
        return item.slides.map((obj: any, index: number) => {
          return (
            <Box  width="100%" boxSizing={'border-box'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} sx={{maxHeight:'600px'}}>
              <Box dangerouslySetInnerHTML={{ __html: obj.title }} />
              {(obj.media_type === "image" || !obj.media_type) && <div style={{padding:'0 50px',boxSizing:'border-box', height:'450px', maxHeight:'100%'}}><img src={obj.media || obj.media_url || dummyImage} className="margin0 image" style={{width:'100%', height:'100%'}} /></div>}
              {obj.media_type === "video" &&
                <Box width="100%" paddingRight={'50px'} paddingLeft={'50px'} sx={{boxSizing:'border-box', height:'450px', maxHeight:'100%'}}>
                  <ReactPlayer
                    controls
                    url={obj.media || obj.media_url || dummyVideo}
                    width="100%"
                    height="100%"
                  />
                </Box>}
              <Box mt="30px" dangerouslySetInnerHTML={{ __html: obj.description }} />
            </Box>
          );
        })
      }
                
      renderCarouselType = (item:any, index: number) => {
        return (
          <ModuleWrapper
            sx={{
              paddingTop: `${item.paddingTop}px`,
              paddingBottom: `${item.paddingBottom}px`,
            }}
          >
            <Box width={`100%`}>
              <Box className="carousel">
                <Box textAlign="center" position="relative">
                 <CarouselCustom>
                  {this.renderCarouselContent(item)}
                 </CarouselCustom>
                </Box>
              </Box>
            </Box>
          </ModuleWrapper>
        );
      }
    
      renderFlashCardType = (item: any, index: number) => {
    
        return (
          <ModuleWrapper
            data-test-id="flash-card-wrapper"
            sx={{
              paddingTop: `30px`,
              paddingBottom: `30px`,
            }}>
            <Box sx={{ width: '70vw' }}>
              <Grid container spacing={4} >
                {item.cards.map((card: { flipped: any; front_side: any; back_side: any; }, childIndex: number) => {
                  return (
                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={childIndex}>
                      <FlipCard className={card.flipped ? "flipped" : ""}>
                        <div className="flip-card-inner">
                          <div data-test-id={`front-card-${childIndex}`} className="flip-card-front" >
                            <div className="card-content">
                              <div dangerouslySetInnerHTML={{ __html: card.front_side }} className="margin0" />
                            </div>
                            <img data-test-id={`flip-front-${childIndex}`} src={repeatBtnBlack} 
                            onClick={() => {this.handleFlipCard(childIndex,`${item.id}`,true)}} />
    
                          </div>
                          <div data-test-id={`back-card-${childIndex}`} className="flip-card-back">
                            <div className="card-content">
                              <div dangerouslySetInnerHTML={{ __html: card.back_side }} className="margin0" />
                            </div>
                            <img data-test-id={`flip-back-${childIndex}`} src={repeatBtnWhite} 
                            onClick={() => {this.handleFlipCard(childIndex, `${item.id}`, false)}} />
                          </div>
                        </div>
                      </FlipCard>
    
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </ModuleWrapper>
        );
      }
    
      renderTableType = (item: any, index: number) => {
        
        const rows =[...item.table_rows];
        return (
          <ModuleWrapper
            data-test-id="image-wrapper"
            sx={{
              paddingTop: `30px`,
              paddingBottom: `30px`,
            }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Box sx={{
              width: `${item.width}`
            }}>
          <div style={{ overflow: 'auto',marginBottom:'30px',paddingBottom:'20px',maxHeight:'350px' }}>
          <StyledTable>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr key={row.id}>
                      {Object.entries(row).map(([key,cell]:[any,any], cellIndex) => (
                       key!=='id'&& <td style={{minWidth:'100px'}} key={cell.id}>
                              <div dangerouslySetInnerHTML={{ __html: cell}} className="margin0" />
                          
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
              </div>
            </Box>
          </ModuleWrapper>
        )
      }
    
     renderTestKnowledgeType = (item: any, index: number) => {
        const listQuestion = item.test_questions as TestQuestionType[]
        return (
          <ModuleWrapper
            key={item.id}
            data-test-id="test-knowledge-wrapper"
            sx={{
              paddingTop: `30px`,
              paddingBottom: `30px`,
            }}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            {listQuestion.map((question,questionIndex) => {
                return (
                    <Box width={'70vw'} sx={webStyles.testCard} >
                        <Box display={"flex"} borderBottom={"1px solid #D7D7D7"} justifyContent={"space-between"} gap={"10px"} padding={"40px"}>
                            <Typography style={webStyles.quizBlockQuestion}>
                                <div dangerouslySetInnerHTML={{ __html: question.question_text }} className="margin0" />
                            </Typography>
                        </Box>
                        <Box padding={"40px 72px"} gap={"32px"} display={"flex"} flexDirection={"column"}>
                        {question.question_type === 'multiple_response' ?
                          question.options.map((option) => {
                            return <FormControlLabel
                              data-test-id={`check-answer-${option.id}`}
                              key={option.id}
                              style={{ gap: '40px' }}
                              value={option.id}
                              control={<CustomCheckbox
                                onChange={(e) => { this.handleSelectAnswer(questionIndex,`${item.id}`,e.target.value)}} />}
                              label={<Typography style={webStyles.checkboxLabel}><div dangerouslySetInnerHTML={{ __html: option.text }} /></Typography>}
                            />
                          })
                          :
                          <RadioGroup>
                            {question.options.map((option,index) => {
                              return <FormControlLabel
                                style={{ gap: '40px', padding: '20px 0' }}
                                data-test-id={`radio-answer-${index}}`}
                                key={option.id}
                                value={option.id}
                                control={<Radio
                                  data-test-id="test"
                                  onChange={(e) => { this.handleSelectAnswer(questionIndex,`${item.id}`,e.target.value)}}
                                  style={{
                                    color: 'black',
                                    width: '32px',
                                    height: '32px',
                                  }} />}
                                label={<Typography style={webStyles.checkboxLabel}><div dangerouslySetInnerHTML={{ __html: option.text }} /></Typography>}
                              />
                            })}
                          </RadioGroup>
                        }
                        </Box>
                        {question.isSubmit &&
                          <Typography style={{ ...webStyles.checkboxLabel, margin: '15px 0', textAlign: 'center' }}>
                            <div dangerouslySetInnerHTML={{ __html: question.isAnswersCorrect ? question.correct_feedback : question.incorrect_feedback }} />
                          </Typography>}
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: '40px',
                        }}>
                            <Button style={webStyles.submitButton} data-test-id="submit-test-button" onClick={() => { this.handleSubmitQuestion(questionIndex,`${item.id}`)}} variant="contained">Submit</Button>
                        </Box>
                    </Box>
                )
            })}
            
          </ModuleWrapper>
        )
      }
      renderButtonType = (item:any) => {
        return (
          <ModuleWrapper
            sx={{
              paddingTop: `30px`,
              paddingBottom: `30px`,
              position: 'relative'
            }}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Box sx={{ width: '50vw' }}>
              
              <Button style={buttonStyle.blackButton} data-test-id={`button-type-${item.id}`} onClick={this.handleButtonTypeClick}>{item.is_finish_button ? "Finish" : "Continue"}</Button>
              {item.is_finish_button?
              <p style={{textAlign:'center', color:'#78716C'}}>Adding the <b>‘Finish’</b> button marks all lessons as completed. No additional lessons can be added after this point.</p>
              :<></>}
            </Box>
          </ModuleWrapper>
        )
      }

  renderFeedbackForm = () => {
    return (
      <CustomFeedbackForm
        isVisible={this.state.isFeedbackFormVisible}
        formData={this.state.feedbackFormData}
        onClose={this.onCloseFeedbackForm}
        onSubmit={(answers,suggestion)=>this.onSubmitFeedbackForm(answers,suggestion)}/>
    )
  }


    render() {
        return (
            <Header
                navigation={this.props.navigation}
                islogin={true}
                isHeader={true}
                isMenuItemsUnVisible={true}
                isLogoUnVisible={true}
                goBackLabel="My Learning"
                goBackFunction={()=>{this.props.navigation.navigate('MyLearning')}}
            >
               {this.state.isLoadingContent ?
                    <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <CircularProgress
                            style={{
                                height: '100px',
                                width: '100px',
                                boxShadow: 'inset 0 0 1px 1px #D9d9d9',
                                borderRadius: '50%'
                            }}
                            sx={{ 'svg circle': { stroke: '#1c1c1c' } }}
                        />
                    </Box>
               : <Wrapper>
                    {this.renderSideBar()}
                    {!this.state.selectedCourseModuleId || !this.state.courseModuleSelected?
                    <Box 
                    flex={4} 
                    sx={{ width: '100%', height: '100%' }} 
                    display={'flex'} 
                    alignItems={'center'} 
                    justifyContent={'center'}>
                    <CircularProgress
                            style={{
                                height: '100px',
                                width: '100px',
                                boxShadow: 'inset 0 0 1px 1px #D9d9d9',
                                borderRadius: '50%'
                            }}
                            sx={{ 'svg circle': { stroke: '#1c1c1c' } }}
                        />
                    </Box>
                    :
                    this.renderLessonDetail()}
                    {this.state.isFeedbackFormVisible && this.renderFeedbackForm()}
                </Wrapper>}
            </Header>
        );
    }
}

// Customizable Area Start
const ModuleWrapper = styled(Box)({
    padding:'20px 60px',
    display:'flex',
    justifyContent:'center',
})
const CustomCheckbox = styled(Checkbox)(() => ({
    '& .MuiSvgIcon-root': {
      width: '32px',
      height: '32px',
      backgroundColor: 'white',
      border: '1px solid black',
      borderRadius: '4px',
    },
    '&:not(.Mui-checked) .MuiSvgIcon-root': {
      color: 'transparent',
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        backgroundColor: 'black',
        borderColor: 'black',
      },
      '& .MuiSvgIcon-root path': {
        d: 'path("M5 12l5 5L20 7")',
        stroke: 'white',
        strokeWidth: 2,
        fill: 'none',
      },
    },
  }));
const StyledTable = styled('table')({
    borderCollapse: 'collapse',
    width: '100%',
    '& th, & td ': {
      border: '1px solid #d0d0d0',
      padding: '8px',
      textAlign: 'left',
    },
  
    ' & th': {
      backgroundColor: '#f1f1f1',
      fontWeight: 'bold',
    },
  
    '& tr:nth-child(even)': {
      backgroundColor: '#f9f9f9',
    },
  
    ' & tr:hover': {
      backgroundColor: '#e0e0e0',
    },
  })
const FlipCard = styled('div')({
    perspective: '1000px',
    width: '200px',
    height: '100%',
    position: 'relative',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.5s',
    "&.flipped": {
      transform: 'rotateY(180deg)',
    },
    '.flip-card-inner': {
      width: '200px',
      height: '240px',
      transformStyle: 'preserve-3d',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  
    '.flip-card-front,.flip-card-back': {
      boxSizing: 'border-box',
      padding: '30px 20px 35px',
      width: '100%',
      position: 'absolute',
      backfaceVisibility: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&>img': {
        width: '24px',
        height: '24px',
        position: 'absolute',
        right: '20px',
        bottom: '10px'
      }
    },
  
    '.flip-card-front': {
      backgroundColor: '#fff',
      color: 'black',
      height: '90%',
      border: '1px solid black',
      borderBottom: '3px solid black'
    },
  
    '.flip-card-back ': {
      height: '100%',
      backgroundColor: '#1C1C1C',
      color: 'white',
      transform: 'rotateY(180deg)',
    },
  
    '.card-content': {
      boxSizing: 'border-box',
      overflow: 'scroll',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      "&>div": {
        margin: 'auto'
      }
    }
  
  })
const Wrapper = styled(Box)({
    display:'flex',
    backgroundColor:'#F8FAFC',
    height:'100%',
    width:'100%'
})
const LessonWrapper = styled(Box)({
    '& .lesson-header':{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        height:'100%',
        maxHeight:'240px',
        position:'relative',
        borderBottom:'1px solid #9D9D9D',
    },
    height:'100vh',
    overflow:'scroll',
    paddingBottom:'40px'
})
const webStyles = {
    courseHeadBg:{
        position:'absolute',
        backgroundColor:'#fff',
        opacity:'60%',
        width:'100%',
        height:'100%',
        maxHeight:'240px',
        top:0, 
        right:0,
        left:0,
        zIndex:0
    } as React.CSSProperties,
    contentBtn: {
        width: '52px',
        minWidth: '0',
        height: '52px',
        borderRadius: '50%',
        border: '2px solid #fff',
        backgroundColor: '#FFFFFF80',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContents: 'center'
      }as React.CSSProperties,
      plusIcon: {
        width: '32px',
        height: '32px',
        color: '#000',
        backgroundColor: '#fff',
        borderRadius: '50%',
        padding: '5px',
        boxSizing: 'border-box',
        boxShadow: "0px 2px 8px 0px #00000099",
    
      }as React.CSSProperties,
      labelContent: {
        flexDirection: "column",
        width: 'max-content',
        maxWidth: "225px",
        alignItems: 'flex-start',
        textAlign: 'left',
        backgroundColor: '#fff',
        padding: '20px'
      }as React.CSSProperties,
      buttonTable: {
        border: '1px solid black',
        textTransform: 'none',
        padding: '5px 20px',
        width: '100%',
        color: 'black'
      } as React.CSSProperties,
      testCard:{
        margin:'10px 0',
        boxShadow: '0px 2px 20px 0px #0000001A',
    
      }as React.CSSProperties,
      checkboxLabel: {
        fontFamily: "Crimson Text",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "32px",
        color: '#1C1C1C'
      }as React.CSSProperties,
      submitButton: {
        minWidth: '248px',
        minHeight: '44px',
        borderRadius: '4px',
        padding: '10px 16px',
        backgroundColor: '#1C1C1C',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '32px'
      }as React.CSSProperties,
      quizBlockQuestion: {
        fontFamily: "Crimson Text",
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "32px",
        color: '#1C1C1C'
      }as React.CSSProperties,

}
const buttonStyle = {
    lessonAddButton: {
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translate(-50%, 50%)",
      "&:hover": {
        backgroundColor: "#1C1C1C",
      }
    } as React.CSSProperties,
    lessonListTypeAddButton: {
      boxShadow: "0px 2px 20px 0px #00000014",
    } as React.CSSProperties,
    lessonListTypeButton: {
      border: "1px solid #6A6A6A",
    } as React.CSSProperties,
    blackButton: {
      textTransform: 'none',
      fontWeight: 'bold',
      backgroundColor: '#1C1C1C',
      width: '100%',
      fontSize: '16px',
      color: "#fff"
    } as React.CSSProperties,
  }
// Customizable Area End