import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import {
    Box,
    Button,
    Grid,
    RadioGroup,
    Radio,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import "@mui/styles";

// Customizable Area Start
import {Notes, Quiz,RadioButtonUnchecked,CheckCircle} from '@mui/icons-material'
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { styled } from "@mui/material/styles";
import {
    Theme,
} from "@mui/material/styles";
import Header from "../../../components/src/Header.web";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import CircularProgress from '@mui/material/CircularProgress';
import HtmlReactParser from 'html-react-parser';
import DOMPurify from 'dompurify';

export interface AttemptedAssessmentInterface {
    id: number,
    title: string,
    assessments: AssessmentItemInterface[]
}

export interface Question {
    id: number,
    SelectedAnswerIndex?: number;
    description_text?: string
}

export interface AssessmentItemInterface {
    id: number,
    isPassed: boolean,
    title: string,
}

// Customizable Area End

import AssessmentTestController, { Props } from "./AssessmentTestPageController";
import { imgBackground, imgCheck, imgClose, imgCorrectImage, imgInProgress, imgIncorrectImage, imgMenu, imgMenuFull, imgMenuGray, imgPassedTest, imgRadioButton, imgRadioButtonChecked, imgRetake, imgSuccess } from "./assets";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

export default class AssessmentTest extends AssessmentTestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    
    renderSideBar2 = () => {
        const sanitizeDataHTML = (htmlString: string) => {
            const sanitizedDOMHTML = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
            return sanitizedDOMHTML.textContent || '';
        };
    
        const { courseDetails, currentCourseModulePosition, selectedCourseModuleId } = this.state;
        if (!courseDetails || !courseDetails.attributes) return;
    
        const { attributes } = courseDetails;
    
        const isClickable = (index: number) => 
            currentCourseModulePosition >= index || this.checkCompletedPercentage(index);
    
        const renderCircularProgress = (dataId: string, index: number) => {
            const isSelected = selectedCourseModuleId === `${dataId}`;
            const progressValue = isSelected ? 100 : 0;
    
            return this.checkCompletedPercentage(index) && !isSelected ? (
                <Checkbox
                    icon={<RadioButtonUnchecked />}
                    checked={true}
                    checkedIcon={<CheckCircle style={{ color: '#000' }} />}
                    sx={{
                        height: '26px',
                        color: 'black',
                        padding: 0,
                        width: '26px',
                    }}
                />
            ) : (
                <CircularProgress
                    variant="determinate"
                    value={progressValue}
                    sx={{ 'svg circle': { stroke: 'black' } }}
                    style={{
                        width: '25px',
                        borderRadius: '50%',
                        height: '25px',
                        boxShadow: 'inset 0 0 1px 1px #D9d9d9',
                    }}
                />
            );
        };
    
        const renderCourseModule = (data: any, index: number) => {
            if (!data) return null;
    
            return (
                <Box display="flex" flexDirection="column" key={`module-${data.id}`}>
                    <ListItemButton
                        data-test-id={`lesson-test-${data.id}`}
                        sx={{
                            backgroundColor: 'inherit',
                            py: '10px',
                            minHeight: 32,
                            color: '#1C1C1C',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        onClick={isClickable(index) ? () => this.handleChooseLesson(`${data.id}`) : undefined}
                    >
                        <Notes style={{ width: '24px', height: '24px' }} />
                        <ListItemText
                            primaryTypographyProps={{
                                fontWeight: 'medium',
                                padding: '0 10px',
                                fontSize: '12px',
                            }}
                            primary={data.title}
                        />
                        {renderCircularProgress(data.id, index)}
                    </ListItemButton>
                    {data.assessment && renderAssessment(data.assessment, data.id, index)}
                </Box>
            );
        };
    
        const renderAssessment = (assessment: any, dataId: string, index: number) => (
            <ListItemButton
                key={`assessment-${assessment.id}`}
                data-test-id={`assessment-test-${assessment.id}`}
                sx={{
                    backgroundColor: this.state.selectedCourseModuleId === `${dataId}` ? '#F0F0F0' : 'inherit',
                    py: '10px',
                    minHeight: 32,
                    color: '#1C1C1C',
                    display: 'flex',
                }}
                onClick={isClickable(index) ? () => this.handleChooseAssessment(`${dataId}`) : undefined}
            >
                <Quiz style={{ width: '24px', height: '24px' }} />
                <ListItemText
                    primary="Assessment Test"
                    primaryTypographyProps={{ fontSize: '12px', fontWeight: 'medium', padding: '0 10px' }}
                />
            </ListItemButton>
        );
    
        return (
            <Box
                flex={1}
                flexDirection="column"
                overflow="auto"
                display="flex"
                sx={{
                    width: '100%',
                    boxShadow: '0px 4px 20px 0px #0000001A inset',
                    zIndex: 1,
                    backgroundColor: '#F8FAFC',
                }}
            >
                <Box padding="40px 20px" sx={{ backgroundColor: 'black' }}>
                    <Typography fontWeight="bolder" color="white" fontSize="24px">
                        <p dangerouslySetInnerHTML={{ __html: sanitizeDataHTML(attributes.title) }} />
                    </Typography>
                </Box>
                <Box>
                    {attributes.course_modules.map((item: any, index: number) => renderCourseModule(item, index))}
                </Box>
            </Box>
        );
    };
    

    renderHTML = (data: any) => {
        const content = data.replace('\n/g', '');
        return content.includes('<p') ? HtmlReactParser(content.replace('\n/g', '')) : content;
    }

    renderCard() {
        if (!this.state.currentQuestion?.data) {
            return null;
        }
        return (
            <Box sx={styles.card}>
                <Box sx={styles.card.header}>
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box>{this.state.currentQuestion.position}.</Box> <Box alignItems={'center'}>{this.renderHTML(this.state.currentQuestion.data.description)}</Box>
                    </Box>
                    <Box>{this.state.currentQuestion.position}/{this.state.assessmentData?.questions?.length}</Box>
                </Box>
                <Box sx={styles.card.content}>
                    <Box>
                        {this.state.currentQuestion?.data?.answer_options.map((option:any) => (
                            <Box sx={{ flexDirection: 'row', display: 'flex', marginBottom: '32px', alignContent: 'center', alignItems: 'center' }} key={option.id}>
                                {this.isMultiSelect(this.state.currentQuestion.data.question_type) ? <CustomCheckbox
                                    data-test-id={`checkbox-${option.id}`}
                                    checked={this.state.submitedAnswers?.includes(option.id)}
                                    onChange={() => this.onChangeOption(option)}
                                /> : <Box sx={{ marginRight: '40px' }} data-test-id={`radio-button-${option.id}`} onClick={() => this.onChangeOption(option)}>
                                    {this.customRadioBtn(this.state.submitedAnswers?.includes(option.id))}
                                </Box>}
                                <Box>{this.renderHTML(option.description)}</Box>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                       {!this.state.currentResponse?.attributes && <BlackButton data-test-id={'submitButton'}  style={{ marginTop: "40px" }} onClick={() => { this.submitAnswer() }}>Submit</BlackButton>}                    
                       {this.showNextButton() && <WhiteButton data-test-id={'nextButton'}  style={{ marginTop: "40px", marginLeft: '30px' }} onClick={() => { this.onNext() }}>Next</WhiteButton>}
                    </Box>
                </Box>
            </Box>
        )
    }

    renderCorrectAnswerAlert() {
        return (
            <Box sx={styles.alert}>
                <Box className="alert-image"><img src={imgCorrectImage} alt="" /></Box>
                <Box className="alert-label">Correct Answer</Box>
                <Box className="alert-description">{this.renderHTML(this.state.currentQuestion.data.correct_feedback || '')}</Box>
            </Box>
        )
    }

    renderIncorrectAnswerAlert() {
        return (
            <Box sx={styles.alert}>
                <Box className="alert-image"><img src={imgIncorrectImage} alt="" /></Box>
                <Box className="alert-label">Incorrect Answer</Box>
                <Box className="alert-description">{this.renderHTML(this.state.currentQuestion.data.incorrect_feedback || '')}</Box>
            </Box>
        )
    }

    renderPassedTest = (obtainedPercentage:number,passingPercentage:number) => {
        return (
            <Box sx={styles.passedTest}>
                <Box sx={styles.passedTest.block}>
                    <Box sx={styles.passedTest.item}>
                        <div className="passedTest-title">Congratulations!</div>
                        <div className="passedTest-icon"><img src={imgPassedTest} alt="" /></div>
                    </Box>
                    <Box sx={{ ...styles.passedTest.item, padding: "0 0 48px 0" }}>
                        <div className="passedTest-default-text">You have successfully completed the course</div>
                        <div className="passedTest-default-text">{this.getResultDate()}</div>
                    </Box>
                </Box>
                <Box sx={styles.passedTest.block}>
                    <Box sx={{ ...styles.passedTest.item, padding: "32px 0" }}>
                        <div className="passedTest-default-text">Completion Requirement: <b>{passingPercentage}%</b></div>
                        <div className="passedTest-default-text">You Completed: <b>{obtainedPercentage}%</b></div>
                    </Box>
                </Box>
                <Box sx={styles.passedTest.buttons}>
                    <WhiteButton onClick={()=>{this.props.navigation.navigate('MyLearning')}}>My Learning</WhiteButton>
                    <BlackButton data-test-id="download-btn" onClick={() => this.navigateToCertificate()}>Download Certificate</BlackButton>
                </Box>
            </Box>
        )
    }

    getProgressStyles = (isPassed: boolean) => {
        const circularProgressbarStyle = {
            // Customize the root svg element
            root: {},

            // Customize the path, i.e. the "completed progress"
            path: {
                // Path color
                stroke: isPassed ? `rgb(28, 28, 28)` : `rgb(248, 113, 113)`,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt' as "butt",
                // Rotate the path
                transform: 'rotate(0.5turn)',
                transformOrigin: 'center center',
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
                // Trail color
                stroke: '#E8E8E8',
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt' as 'butt',
                // Rotate the trail
                transform: 'rotate(0.5turn)',
                transformOrigin: 'center center',
            },
            // Customize the text
            text: {
                // Text color
                fill: "#1d1d1d",
                // Text size
                fontSize: "2.5rem",
            },
            // Customize background - only used when the `background` prop is true
            background: {
                fill: '#3e98c7',
            }
        }
        return circularProgressbarStyle;
    }

    renderEndTest = (obtainedPercentage: number,passingPercentage:number) => {
        const isPassed = obtainedPercentage >= passingPercentage;
        return (
            <Box sx={styles.endTest}>
                <Box sx={styles.endTest.wrapper}>
                    <Box sx={styles.endTest.progress}>
                        <CircularProgressbarWithChildren styles={this.getProgressStyles(isPassed)} strokeWidth={2} value={obtainedPercentage}>
                            <img src={isPassed ? imgCheck : imgClose} alt="" />
                            <div style={{ color: isPassed ? "#1C1C1C" : "#F87171" }} className="progressText">Your score {obtainedPercentage}%</div>
                        </CircularProgressbarWithChildren>
                    </Box>
                    {this.state.assessmentData?.retake_option &&
                        <RetakeButton data-test-id={'retakeButton'} onClick={() => this.retakeAssessment()}>
                            <img src={imgRetake} alt="" />
                            <span>Retake</span>
                        </RetakeButton>
                    }
                </Box>
                <BlackButton data-test-id={'finishButton'} disabled={!isPassed} onClick={()=>this.onFinish()}>Finish Assessment</BlackButton>
            </Box>
        )
    }

    renderLoader = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}
            >
                <CircularProgress />
            </Box>
        );
    };

    customRadioBtn = (isChecked:boolean) => {
        return (
            <Box height={isChecked ? '12.8px' : '30px'} width={isChecked ? '12.8px' : '30px'} borderRadius={'50%'} border={isChecked ? '12px solid #1C1C1C' : '2.3px solid #1C1C1C'}> </Box>
        )
    }

    renderContent(background: boolean = false) {
        const result = this.state.assessmentResult?.attributes || null;
        return (
            <Box sx={styles.content} flex={4}>
                <Box sx={styles.content}>
                    <Button className="menu-button"><img src={imgMenuFull}></img></Button>
                    {this.state.activeAssessmentId.listItem === 1 && <>
                        {background &&
                            <Box sx={styles.content.background}>
                                <div className="bg-image"></div>
                                <div className="counter">Lesson 1 of 7</div>
                                <div className="title">Introducing user experience design</div>
                                <div className="author">by Brooklyn Simmons</div>
                            </Box>
                        }
                        <Box sx={styles.content.information}>
                            <Box sx={styles.content.title}>
                                What is UX Design? This is Heading
                            </Box>
                            <Box sx={styles.content.description}>
                                At its core, UX design is about enhancing the overall experience a user has while interacting with a product, whether it's a website, an app, or any digital tool. UX design involves understanding the users' needs, behaviors, and motivations to create products that solve real problems in an intuitive way.
                            </Box>
                        </Box>
                    </>}
                    <Box sx={{ padding: "44px 0 0 0" }}>
                        {this.renderCard()}
                         {(this.state.currentResponse?.attributes?.is_correct && this.state.assessmentData.show_answer) && this.renderCorrectAnswerAlert()}
                        {(this.state.currentResponse?.attributes && !this.state.currentResponse?.attributes.is_correct && this.state.assessmentData.show_answer) && this.renderIncorrectAnswerAlert()}
                         {(result && !this.state.isFinished) && this.renderEndTest(result?.obtain_percentage,result?.passing_percentage)}
                        {this.isPassedAssessment() && this.renderPassedTest(result?.obtain_percentage,result?.passing_percentage)}
                    </Box>
                </Box>
            </Box>
        );
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            // <Wrapper>
                <Header
                    navigation={this.props.navigation}
                    islogin={true}
                    isFooterUnVisible={true}
                    isMenuItemsUnVisible={true}
                    isLogoUnVisible={true}
                goBackFunction={()=>{this.props.navigation.navigate('MyLearning')}}
                goBackLabel={"My Learnings"}>
                    <Box sx={{
                         display:'flex',
                         backgroundColor:'#F8FAFC',
                         height:'100%',
                         width:'100%'
                    }}>
                        {this.renderSideBar2()}
                        {(this.state.loading && !this.state.currentQuestion?.data) ? this.renderLoader() : this.renderContent(true)}
                    </Box>
                </Header>
            // </Wrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const Wrapper = styled((Box))({
    "& .content-grid": {
        display: "grid",
        gridTemplateColumns: "300px 1fr",
        gridTemplateRows: "1fr",
    }
});

const RetakeButton = styled(Button)({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    color: "#1C1C1C",
    fontFamily: "Heebo",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    cursor: "pointer",
    "& img": {
        width: 32,
        height: 32,
    }
})

const CustomRadio = styled((Radio))({
    width: 32,
    height: 32,
    margin: "0 40px 0 0"
})

const BlackButton = styled((Button))({
    width: "248px",
    background: "#1C1C1C",
    padding: "10px 16px 10px 16px",
    borderRadius: "4px",
    color: "#FFFFFF",
    fontFamily: "Heebo",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    "&:hover": {
        background: "#1C1C1C",
    },
    "&:disabled": {
        color: "#FFFFFF",
        background: "#D7D7D7"
    }
})

const CustomCheckbox = styled(Checkbox)(() => ({
    '& .MuiSvgIcon-root': {
      width: '32px',
      height: '32px',
      backgroundColor: 'white',
      border: '1px solid black',
      borderRadius: '4px',
      marginRight:'15px'
    },
    '&:not(.Mui-checked) .MuiSvgIcon-root': {
      color: 'transparent',
    },
    '&.Mui-checked': {
      '& .MuiSvgIcon-root': {
        backgroundColor: 'black',
        borderColor: 'black',
      },
      '& .MuiSvgIcon-root path': {
        d: 'path("M5 12l5 5L20 7")',
        stroke: 'white',
        strokeWidth: 2,
        fill: 'none',
      },
    },
  }));

const WhiteButton = styled((Button))({
    width: "248px",
    background: "#FFFFFF",
    border: "1px solid #000000",
    padding: "10px 16px 10px 16px",
    borderRadius: "4px",
    color: "#1C1C1C",
    fontFamily: "Heebo",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
})

const styles = {
    tabBar: {
        wrapper: {
            width: "100%",
            height: "100%",
            border: "1px solid #F8FAFC",
            background: "#FAFAFA",
        },
        header: {
            background: "#1C1C1C",
            fontFamily: "Crimson Text",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "36px",
            color: "#FFFFFF",
            padding: "40px 24px 40px 24px",
            gap: "10px",
            borderBottom: "1px solid #D7D7D7",
        },
        content: {
            list: {
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "32px 0 0 0"
            }
        },
        dropdown: {
            title: {
                margin: "0 15.5px",
                padding: "4px 8px 8px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #D7D7D7",
                fontFamily: "Crimson Text",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "24px",
                color: "#1C1C1C",

            },
            itemTitle: {
                fontFamily: "Heebo",
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "17.63px",
            },
            items: {

            },
            item: {
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                gap: "16px",
                padding: "20px 16px 20px 16px",
            },
            progressImage: {
                width: "26px",
                height: "26px",
            }
        },
    },
    content: {
        paddingBottom: "20px",
        position: "relative",
        "& .menu-button": {
            position: "absolute",
            top: "14px",
            left: 0,
            zIndex: 1,
        },
        background: {
            height: "auto",
            padding: "64px 140px 60px 140px",
            position: "relative",
            color: "#1C1C1C",
            fontFamily: "Crimson Text",
            borderBottom: "1px solid #9D9D9D",
            "& .bg-image": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#FFFFFF99",
                backgroundImage: `url(${imgBackground})`,
                backgroundRepeat: "no-repeat",
                filter: "blur(6px)",
                backgroundSize: "cover",
                zIndex: -1,
            },
            "& .counter": {
                fontSize: "14px",
                fontStyle: "italic",
                fontWeight: 600,
                lineHeight: "18.2px",
            },
            "& .title": {
                fontSize: "40px",
                fontWeight: 700,
                lineHeight: "40px",
                margin: "12px 0 16px 0"
            },
            "& .author": {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "23.4px",
            },
        },
        information: {
            padding: "72px 0 100px 0",
            width: "856px",
            color: "#1C1C1C",
            fontFamily: "Crimson Text",
            margin: "0 auto"
        },
        title: {
            fontSize: "32px",
            fontWeight: 600,
            lineHeight: "40px",
            marginBottom: "32px",
        },
        description: {
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "28px",

        }
    },
    card: {
        boxShadow: "0px 2px 20px 0px #0000001A",
        margin: "4px auto",
        width: "856px",
        background: "#FFFFFF",
        header: {
            padding: "40px",
            borderBottom: "1px solid #D7D7D7",
            color: "#1C1C1C",
            fontFamily: "Crimson Text",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "32px",
            display: "flex",
            justifyContent: "space-between"
        },
        content: {
            padding: "40px 72px",
            "& .radio-group": {
                display: "flex",
                flexDiraction: "column",
                gap: "32px"
            },
            "& .radio-label": {
                color: "#1C1C1C",
                fontFamily: "Crimson Text",
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "32px",

            }
        }
    },
    alert: {
        maxWidth: "856px",
        paddingTop: "32px",
        paddingBottom: '32px',
        background: `rgb(240, 240, 240)`,
        margin: "24px auto",
        "& .alert-image": {
            width: 80,
            height: 80,
            margin: "0 auto"
        },
        "& .alert-label": {
            textAlign: "center",
            marginTop: "20px",
            color: "#1C1C1C",
            fontFamily: "Crimson Text",
            fontSize: "20px",
            fontWeight: 700,
            lineHeight: "32px",
        },
        "& .alert-description": {
            textAlign: "center",
            marginTop: "32px",
            color: "#1C1C1C",
            fontFamily: "Crimson Text",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "32px",

        }
    },
    passedTest: {
        width: "856px",
        margin: "4px auto",
        block: {
            borderBottom: "1px solid #D7D7D7",
            display: "flex",
            flexDirection: "column",
            gap: "20px"
        },
        item: {
            display: "flex",
            justifyContent: "space-between",
            "& .passedTest-title": {
                fontFamily: "Crimson Text",
                fontSize: "40px",
                fontWeight: 400,
                lineHeight: "40px",
                color: "#1C1C1C"

            },
            "& .passedTest-default-text": {
                fontFamily: "Crimson Text",
                fontSize: "24px",
                fontWeight: 400,
                lineHeight: "40px",
                color: "#1C1C1C"
            }
        },
        buttons: {
            display: "flex",
            justifyContent: "center",
            gap: "40px",
            padding: "72px 0 0 0"
        }
    },
    endTest: {
        margin: "0 auto",
        width: "856px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        wrapper: {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "56px",
            borderBottom: "1px solid #6A6A6A",
            padding: "0 0 28px 0",
            margin: "0 0 46px 0",
        },
        progress: {
            margin: "0 auto",
            width: 300,
            height: 300,
            "& .progressText": {
                fontFamily: "Crimson Text",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "32px",
            }
        }
    }
}

const Flag = styled(Box)({
    position: "absolute",
    left: 0,
    top: 0,
    width: 6,
    height: "100%",
    backgroundColor: "#1C1C1C",
})


// Customizable Area End
