import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { apiCall } from '../../../components/src/ApiCall.web';
import { createRef } from "react";
export interface IValue {
  companyLogo: string;
  companyName: string;
  contactName: string;
  jobTitle: string;
  email: string;
  contactPhone: string;
}
interface NewUserData {
  firstName: string,
  lastName: string,
  jobTitle: string,
  email: string,
}
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  openDialog: string;
  loading: boolean;
  selectedLearningPaths: any[];
  selectedPackages: any[];
  companyLogo: string;
  contactError: string;
  emailError: string;
  newUserData: NewUserData;
  addNewUserModal: boolean;
  sortBy:string;
  notificationModalOpen: boolean;
  notifications: any[];
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class AdminDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createClientApiCallId: string = "";
  fileInputRef: React.RefObject<HTMLInputElement>;
  addNewUserDoneApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    this.state={
      openDialog: "",
      loading: false,
      selectedLearningPaths: [],
      selectedPackages: [],
      companyLogo: "",
      contactError: "",
      emailError: "",
      newUserData: {
        firstName: "",
        lastName: "",
        jobTitle: "",
        email: "",
      },
      addNewUserModal: false,
      sortBy:"",
      notificationModalOpen: false,
      notifications: [
          {
            id: 1,
            message: "A new user, John Doe, has registered as a student. Click to review their profile.",
            timestamp: "Thursday 3:12pm",
            isRead:'true'
          },
          {
            id: 2,
            message: "A new course, ‘Cybersecurity Essentials,’ is awaiting your approval. Click to review details.",
            timestamp: "Thursday 3:12pm",
            isRead:'true'
          },
          {
            id: 3,
            message: "The LMS will undergo maintenance on Saturday from 1 AM to 3 AM. Ensure backups are up-to-date.",
            timestamp: "Thursday 3:12pm",
            isRead:'false'
          },
          {
            id: 4,
            message: "A weekly activity report for ‘Data Science Basics’ has been submitted. Click to analyze.",
            timestamp: "Thursday 3:12pm",
            isRead:'false'
          },
          {
            id: 5,
            message: "A flagged comment in the ‘Web Development’ discussion forum requires your moderation.",
            timestamp: "Thursday 3:12pm",
            isRead:'true',
          },
          {
            id: 6,
            message: "Instructor John Smith has submitted feedback regarding the assessment process. Click to review.",
            timestamp: "Thursday 3:12pm",
            isRead:'true',
          },
          {
            id: 7,
            message: "Instructor John Smith has submitted feedback regarding the assessment process. Click to review.",
            timestamp: "Thursday 3:12pm",
            isRead:'false'
          },
          {
            id: 8,
            message: "Your announcement to all enrolled students in ‘Advanced Marketing’ has been successfully delivered.",
            timestamp: "Thursday 3:12pm",
            isRead:'true'
          },
        ],
      };
      this.fileInputRef = createRef<HTMLInputElement>();
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  createClient = async (values: IValue) => {
    this.setState({ loading: true });
    this.createClientApiCallId = await apiCall({
      method: "POST",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_account_groups/clients`,
      body: {
        "client": {
          "company_logo": this.state.companyLogo,
          "company_name": values.companyName,
          "contact_name": values.contactName,
          "contact_job_title": values.jobTitle,
          "contact_email": values.email,
          "contact_phone": values.contactPhone
        }
      }
    });
    this.setState({ openDialog: "", selectedLearningPaths: [], selectedPackages: [] });
  }
  handleLogo = (file: Blob | undefined) => {
    const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png'];
    if (!file) return;
    if (supportedFormats.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.onload = () => {
          this.setState({ companyLogo: reader.result as string });
        }
        img.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  }
  deleteLogo = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
    }
    this.setState({ companyLogo: "" });
  }
  handleDragOverLogo = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
  };
  handleDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files) {
      const file = event.dataTransfer.files[0];
      this.handleLogo(file);
    }
  }
  handleNewClick = () => {
    this.setState({ openDialog: "create" });
  }
  handleNewUserClick = () => {
    this.setState({ openDialog: "create" });
  }
  handleAuditTrailClick = () => {
    this.props.navigation.navigate('AuditTrail')
  };
  handleNotificationClick = () => {
    this.props.navigation.navigate('Notifications')
  };
  handleEventManagementClick = () => {
    this.props.navigation.navigate('EventManagement')
  };
  handleNewCourseClick = () => {
    this.props.navigation.navigate('CourseCreation')
  };
  onNameChange = () => {
    this.setState({ emailError: "" });
  }

  onPhoneChange = () => {
    this.setState({ contactError: "" });
  }
  onCloseDialog = () => {
    this.setState({ openDialog: "", selectedLearningPaths: [], selectedPackages: [] });
  }

  handleAddNewUserModalClose = () => {
    this.setState({
      addNewUserModal: false,
      emailError: "",
      newUserData: {
        firstName: "",
      lastName: "",
      jobTitle: "",
      email: "",
      }
    })
  }
  handleAddNewUserModalOpen = () => {
    this.setState({
      addNewUserModal: true,
    })
  }
  handleAddNewUserDoneClick = async () => {
    this.setState({ loading: true });
    const { newUserData } = this.state;
    const clientId = localStorage.getItem('SelectedClientID')
    const dataToSubmit = {
      data: {
        first_name: newUserData.firstName,
        last_name: newUserData.lastName,
        email: newUserData.email,
        job_title: newUserData.jobTitle,
        client_id: clientId,
      },
    };

    this.addNewUserDoneApiCallId = await apiCall({
      method: "POST",
      navigation: this.props.navigation,
      contentType: 'application/json',
      token: localStorage.getItem("token"),
      endPoint: `bx_block_admin/user_managements`,
      body: dataToSubmit,
    });
    this.setState({
      addNewUserModal: false,
      emailError: "",
      newUserData: {
        firstName: "",
      lastName: "",
      jobTitle: "",
      email: "",
      }
    })
  };
  handleUserDataChange = (field: keyof NewUserData, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState((prevState) => ({
      newUserData: {
        ...prevState.newUserData,
        [field]: event.target.value,
      },
    }));
    if(field === "email"){
      this.setState({ emailError: "" });
    }
  };
  handleSortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const sortBy = event.target.value as string;
    this.setState({ sortBy }, () => {
    });
  }
  handleNotificationModalOpen = () => { 
    this.setState({ notificationModalOpen: true }); 
  }; 
  handleNotificationModalClose = () => { 
    this.setState({ notificationModalOpen: false }); 
  }; 
  handleMarkAllAsRead = () => { 
    console.log("All messages are set to read"); 
  }; 
  // Customizable Area End
}