import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { apiCall } from '../../../components/src/ApiCall.web';
import { dummyImage} from './assets';
import { CommonSuccessResponse, CourseDataType, CourseModuleType, CourseProcessData, QuestionOptionType,  } from "./types";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isListLessonOpen:boolean
    courseData?:CourseDataType
    courseModuleSelected?: CourseModuleType
    isLoadingContent:boolean
    isSelectedCourseModule:boolean
    selectedCourseModuleId?:string
    currentCourseModulePosition:number
    courseProcessData?: CourseProcessData
    lessonProgress:number
    feedbackFormData:any;
    isFeedbackFormVisible:boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

// Customizable Area Start
export type CommonModuleType<T  = {}> = {
    paddingTop: any;
    paddingBottom: any;
    width: any;
    content?: T;
  };
const defaultCarouselContent = {
    media: dummyImage,
    type: "image",
    heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">This is heading for carousel</p>`,
    description: `<p style="font-family:'Crimson Text';font-size:18px">This is subtext</p>`,
  };
// Customizable Area End


export default class EditorController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCourseDataApiCall:string
    getCourseModuleDataApiCall:string
    getProcessApiCall:string
    showCourseProcessApiCall:string
    updateCourseProcessApiCall:string
    scrollContainerRef: React.RefObject<HTMLDivElement>;
    getFeedbackFormApiCallId:string
    userFeedbackAnswerApiCallId:string
    // Customizable Area End

    

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isListLessonOpen:true,
            courseData:undefined,
            isLoadingContent:false,
            isSelectedCourseModule:false,
            selectedCourseModuleId:undefined,
            courseModuleSelected:undefined,
            currentCourseModulePosition:0,
            courseProcessData:undefined,
            lessonProgress:0,
            feedbackFormData: {
              "data": {
                  "id": "21",
                  "type": "feedback_form",
                  "attributes": {
                      "id": 21,
                      "course_id": 240,
                      "name": "Test tejas",
                      "description": "This is test feedback form",
                      "admin_user_id": 1,
                      "feedback_questions_attributes": [
                          {
                              "data": {
                                  "id": "47",
                                  "type": "feedback_question",
                                  "attributes": {
                                      "question": "How would you rate this course?",
                                      "question_type": "rating"
                                  }
                              }
                          },
                          {
                              "data": {
                                  "id": "48",
                                  "type": "feedback_question",
                                  "attributes": {
                                      "question": "Would you recommend this course?",
                                      "question_type": "rating"
                                  }
                              }
                          },
                          {
                              "data": {
                                  "id": "49",
                                  "type": "feedback_question",
                                  "attributes": {
                                      "question": "Would you recommend this course?",
                                      "question_type": "rating"
                                  }
                              }
                          },
                          {
                              "data": {
                                  "id": "50",
                                  "type": "feedback_question",
                                  "attributes": {
                                      "question": "Would you recommend this course?",
                                      "question_type": "status"
                                  }
                              }
                          }
                      ]
                  }
              }
          },
            isFeedbackFormVisible:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.getCourseDataApiCall=''
        this.getCourseModuleDataApiCall=''
        this.getProcessApiCall=''
        this.showCourseProcessApiCall=''
        this.updateCourseProcessApiCall=''
        this.scrollContainerRef = React.createRef<HTMLDivElement>();

        this.getFeedbackFormApiCallId=''
        this.userFeedbackAnswerApiCallId=''
        // Customizable Area End
    }
    async componentDidMount() {
      const courseId = this.props.navigation.getParam('courseId')
      this.handleGetCourseData(courseId)
      this.handleGetProcess()
  }
  componentDidUpdate(prevProps: {}, prevState:S) {
    if (prevState.isLoadingContent && !this.state.isLoadingContent) {
      const scrollContainer = this.scrollContainerRef.current;
      if (scrollContainer) {
        scrollContainer.addEventListener('scroll', this.handleScrollLesson);
      }
    }
  }
  async componentWillUnmount() {
    const scrollContainer = this.scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.removeEventListener('scroll', this.handleScrollLesson);
    }
  }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
              switch (apiRequestCallId) {
                case this.getCourseDataApiCall:
                return this.handleGetCourseDataResponse(responseJson)
                case this.getProcessApiCall:
                  return this.handleGetProcessResponse(responseJson)
                case this.showCourseProcessApiCall:
                  return this.handleShowCourseProcessResponse(responseJson)  
                case this.updateCourseProcessApiCall:
                  return this.handleUpdateProcessResponse(responseJson)
                case this.getFeedbackFormApiCallId: this.handleFeedbackFormData(responseJson);
                  break;
                case this.userFeedbackAnswerApiCallId: this.handleFeedbackSubmitResponse(responseJson);
                  break;
              }
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    handleScrollLesson = () => {
      const scrollContainer = this.scrollContainerRef.current;
      if (scrollContainer) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        const progress = Math.round((scrollTop / (scrollHeight - clientHeight)) * 100);
        this.setState({ lessonProgress: progress });
      }
    };
    handleUpdateProcessResponse=(res:any)=>{
     
      this.handleShowCourseProcess(res.data.data?.id)
    }
    handleGetProcessResponse=(res:any)=>{
      if(res.data){
        localStorage.setItem('processId',res.data?.data?.id)
      this.handleShowCourseProcess(res.data?.data?.id)
       }
     }
     handleShowCourseProcessResponse = (res:any)=>{
       if(res.data){
         this.setState({courseProcessData:res.data?.data?.attributes})
       }
     }
    handleGetCourseDataResponse= (res:CommonSuccessResponse<CourseDataType>)=>{
      const courseModuleId = this.props.navigation.getParam('courseModuleId')
      if(res.data){
        const courseModuleList = res.data.attributes.course_modules;
        const currentIndex = courseModuleList.findIndex(item=>`${item.id}`===`${courseModuleId}`)
      const currentModule = courseModuleList.find(item => `${item.id}` === `${courseModuleId}`);
            this.setState({
                courseData:res.data, 
                isLoadingContent:false,
                selectedCourseModuleId:courseModuleId,
                currentCourseModulePosition:currentIndex,
                courseModuleSelected:currentModule
            })
    }
    }
  
    handleFlipCard = (cardIndex: number, contentId: string, valueUpdate: boolean) => {
      const { courseModuleSelected } = this.state;
      if (!courseModuleSelected) return;
  
      // Copy courseModuleSelected to avoid direct mutation
      const currentModule: CourseModuleType = { ...courseModuleSelected };
  
      if (currentModule.sections && currentModule.sections.length > 0) {
          const section = currentModule.sections[0];
  
          // Find the 'flashcard' block with the matching content ID
          const flashcardBlock = section.content_blocks.find(
              block => block.block_type === 'flashcard' && `${block.id}` === contentId
          );
  
          if (flashcardBlock && 'cards' in flashcardBlock && flashcardBlock.cards && flashcardBlock.cards[cardIndex]) {
              // Update the flipped status for the specified card
              flashcardBlock.cards[cardIndex] = {
                  ...flashcardBlock.cards[cardIndex],
                  flipped: valueUpdate
              };
  
              // Set the modified module back to state
              this.setState({ courseModuleSelected: currentModule });
          }
      }
  };
  
      toggleContentVisibility = (labelIndex: number, contentId: string, valueUpdate: boolean, key:string) => {
        const { courseModuleSelected } = this.state;
        if (!courseModuleSelected) return;
    
        const currentModule: CourseModuleType = { ...courseModuleSelected };
    
        if (currentModule.sections && currentModule.sections.length > 0) {
            const section = currentModule.sections[0];
    
            const labelGraphicBlock = section.content_blocks.find(
                block => block.block_type === 'labeled_graphic' && `${block.id}` === contentId
            );
            if (labelGraphicBlock && 'labels' in labelGraphicBlock && labelGraphicBlock.labels && labelGraphicBlock.labels[labelIndex]) {
                labelGraphicBlock.labels[labelIndex] = {
                    ...labelGraphicBlock.labels[labelIndex],
                    [key]: !valueUpdate
                };
    
                this.setState({ courseModuleSelected: currentModule });
            }
        }
    };
    

   
  handleSelectAnswer = (questionIndex: number, contentId: string, value: string) => {
    const { courseModuleSelected } = this.state;
    if (!courseModuleSelected) return; 

    const currentModule :CourseModuleType = { ...courseModuleSelected };
    if (currentModule && currentModule.sections) {
      const section = currentModule.sections[0];

      const testKnowledgeBlock = section.content_blocks.find(block => block.block_type === 'test_knowledge' && `${block.id}` === contentId);
      if (testKnowledgeBlock && testKnowledgeBlock.test_questions && testKnowledgeBlock.test_questions[questionIndex]) {
        const question = testKnowledgeBlock.test_questions[questionIndex];
        question.selectedAnswers = question.selectedAnswers || []
        const answerIndex = question.selectedAnswers.findIndex((answer: string) => answer === value);

        if (answerIndex !== -1) {
          question.selectedAnswers = question.selectedAnswers.filter((answer: string) => answer !== value);
        } else if (question.question_type === 'multiple_response') {
          question.selectedAnswers = [...question.selectedAnswers, value];
        } else {
          question.selectedAnswers = [value];
        }
      }

      this.setState({ courseModuleSelected: currentModule });
    }
  };
  handleSubmitQuestion = (questionIndex:number,contentId:string)=>{
    const { courseModuleSelected } = this.state;
    if (!courseModuleSelected) return; 

    const currentModule :CourseModuleType = { ...courseModuleSelected };
    if (currentModule && currentModule.sections) {
      const section = currentModule.sections[0];
      const testKnowledgeBlock = section.content_blocks.find(block => block.block_type === 'test_knowledge' && `${block.id}` === contentId);
      console.log(testKnowledgeBlock)
      if (testKnowledgeBlock && testKnowledgeBlock.test_questions && testKnowledgeBlock.test_questions[questionIndex]) {
        const question = testKnowledgeBlock.test_questions[questionIndex];
        const correctAnswers = question.options
        .filter((option:QuestionOptionType) => option.is_correct)
        .map((option:QuestionOptionType) => `${option.id}`);

        const selectedAnswers = question.selectedAnswers || [];

        let isAnswersCorrect = false;
        if (question.question_type === 'multiple_response') {
          isAnswersCorrect =
            correctAnswers.length === selectedAnswers.length &&
            correctAnswers.every((answerId:number) => selectedAnswers.includes(answerId));
        } else if (question.question_type === 'single_response') {
          isAnswersCorrect =
            selectedAnswers.length === 1 && correctAnswers.includes(selectedAnswers[0]);
        }
      
        question.isAnswersCorrect = isAnswersCorrect;
        question.isSubmit=true
  
        this.setState({ courseModuleSelected: currentModule });
      }
    }
  }
  handleGetProcess = async ()=>{
    const assignCourseId = localStorage.getItem('assign_course_id')
    const body = {
      "user_process": {
        "assign_course_id": assignCourseId
      }
    }
    this.getProcessApiCall = await apiCall({
      method:"POST",
      contentType: 'application/json',
      token: localStorage.getItem('token'),
      endPoint: `bx_block_profile/user_processes`,
      body
    })
  }
  handleShowCourseProcess = async(id:string) =>{
    this.showCourseProcessApiCall = await apiCall({
      method: 'GET',
      contentType: 'application/json',
      endPoint: `bx_block_profile/user_processes/${id}`,
      token: localStorage.getItem('token'),
    });
  }
  handleGetCourseData = async (id: string) => {
      this.setState({ isLoadingContent: true, })
      this.getCourseDataApiCall = await apiCall({
        method: 'GET',
        contentType: 'application/json',
        endPoint: `bx_block_admin/courses/get_course?id=${id}`,
        token: localStorage.getItem('token'),
      });
    }
    handleChooseCourseModule = async (courseModuleId: string) => {
      const {courseData} = this.state
      if(!courseData) return
      const courseModuleList = courseData.attributes.course_modules;
      const currentModule = courseModuleList.find(item => `${item.id}` === `${courseModuleId}`);
      const courseId = this.props.navigation.getParam('courseId')
      this.setState(prev=>({...prev, selectedCourseModuleId: courseModuleId, courseModuleSelected:currentModule }),()=>{
        this.props.navigation.navigate('CourseLearning',{path:{courseId,courseModuleId}})
      })
    }
    handleChooseAssessmentTest = async (courseModuleId: string) => {
      const {courseData} = this.state
      if(!courseData) return
      const courseModuleList = courseData.attributes.course_modules;
      const currentModule = courseModuleList.find(item => `${item.id}` === `${courseModuleId}`);
      const courseId = this.props.navigation.getParam('courseId')
      this.setState(prev=>({ ...prev,selectedCourseModuleId: courseModuleId, courseModuleSelected:currentModule }))
      this.props.navigation.navigate('AssessmentTest', { path: { courseId, courseModuleId } })      
    }

    getFeedBackForm = async () => {
      this.setState({isFeedbackFormVisible:true});
      const id = this.state.courseData?.attributes.feedback_forms[0];
      this.getFeedbackFormApiCallId = await apiCall({
        method: 'GET',
        contentType: 'application/json',
        endPoint: `bx_block_profile/feedback_forms/${id}`,
        token: localStorage.getItem('token'),
      });
  }

  handleFeedbackFormData = (responseJson: any) => {

  }

  handleFeedbackSubmitResponse = (responseJson: any) => {
    this.setState({ isFeedbackFormVisible: false });
    return this.props.navigation.navigate('MyLearning')
  }

  onCloseFeedbackForm = () => {
    this.setState({ isFeedbackFormVisible: false })
  }

  getFeedbackAnswers = (answers: any) => {
    let preparedAns:any = [];
    answers.forEach((item: any) => {
      preparedAns.push({
        feedback_question_id: item.id,
        rating: item.rating,
        status: item.status
      })
    });
    return preparedAns;
  }

  onSubmitFeedbackForm = async (answers: any, suggestion: string) => {
    const reqBody = {
      feedback_form_id: this.state.feedbackFormData?.data?.id,
      suggestion: suggestion,
      feedback_answers: this.getFeedbackAnswers(answers)
    }
    this.getCourseDataApiCall = await apiCall({
      method: 'POST',
      contentType: 'application/json',
      endPoint: `bx_block_profile/feedback_forms/user_answer`,
      body: reqBody,
      token: localStorage.getItem('token'),
    });
  }


    handleButtonTypeClick =()=>{
      const { courseData, selectedCourseModuleId, courseModuleSelected } = this.state
      if (!courseModuleSelected || !courseData || !selectedCourseModuleId) return
      if (courseModuleSelected.assessment) {
        this.handleChooseAssessmentTest(selectedCourseModuleId)
        return
      }
      const currentIndex = courseData.attributes.course_modules?.findIndex(item => `${item.id}` === selectedCourseModuleId)
      const nextCourseModule = courseData.attributes.course_modules[currentIndex + 1]
      this.handleUpdateProcess(currentIndex+1)
      if (!nextCourseModule || !nextCourseModule.id){
        if(courseData.attributes?.feedback_forms?.length){
         return this.getFeedBackForm();
        }
        return this.props.navigation.navigate('MyLearning')
      }
      const nextCourseModuleId = nextCourseModule.id
    this.handleGetCourseData(courseData.id)
      this.handleChooseCourseModule(nextCourseModuleId)
    }
    checkCompletedPercentage:(index:number) => boolean = (index:number)=>{
      const {courseProcessData} = this.state
      if(!courseProcessData) return false
      const process = courseProcessData?.process
      const modulesCount = courseProcessData?.module_count
      if(process === 0){
        return false
      }else{
        const numberOfCourseModuleCompleted = Math.round(process * modulesCount /100)
        return (index<=numberOfCourseModuleCompleted -1) 
      }
    }
    handleUpdateProcess = async(courseModulePosition:number)=>{
      const processId = localStorage.getItem('processId')
      const assignedCourseId = localStorage.getItem('assign_course_id')
      const body ={
        "user_process":
        {
            "course_modules": courseModulePosition,
            "assign_course_id": assignedCourseId
        }
    }
    
     this.updateCourseProcessApiCall = await apiCall({
        method: 'PUT',
        contentType: 'application/json',
        endPoint: `bx_block_profile/user_processes/${processId}`,
        token: localStorage.getItem('token'),
        body
      })
    }
    // Customizable Area End
}
