// Customizable Area Start
import React from "react";
import AdminDashboardController,{
    Props,
} from "././AdminDashboardController.web";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from "@mui/icons-material/Notifications";
import * as Yup from "yup";
import {
  Box,
  Button,
  Select as MuiSelect,
  Dialog,
  InputBase,
  createTheme,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
  styled,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
  Modal,
  List,
  ListItem,
  ListItemText,
  Divider,
  Badge,
} from "@mui/material";
import Sidebar from "../../../components/src/sidebar/Sidebar.web";
import AdminFooter from "../../../components/src/AdminFooter.web";
import { Close, FilterList } from "@mui/icons-material";
import { Formik } from "formik";
import Loader from "../../../components/src/Loader.web";
const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
        contrastText: "#fff",
      },
      secondary: {
        main: '#fff'
      }
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
// Customizable Area End
export default class AdminDashboard extends AdminDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  createClientSchema = () => {
    return Yup.object().shape({
      companyLogo: Yup.string().required("*Company logo is required"),
      companyName: Yup.string().required("*Company name is required"),
      contactName: Yup.string().required("*Contact name is required"),
      jobTitle: Yup.string().required("*Job title is required"),
      email: Yup.string().required("*Email is required").email("*Enter valid email"),
      contactPhone: Yup.string().required("*Contact phone is required"),
    });
  }
  userSchema = () => {
    return Yup.object().shape({
        firstName: Yup.string().required("*First name is required")
            .min(2, "*First name is too short (minimum is 2 characters)"),
        lastName: Yup.string().required("*Last name is required")
            .min(2, "*Last name is too short (minimum is 2 characters)"),
        jobTitle: Yup.string().required("*Job title is required")
            .min(2, "*Job title is too short (minimum is 2 characters)"),
        email: Yup.string().required("*Email is required")
            .email("*Please enter a valid email address"),
    });
  }
  renderCreateClientDialog = () => {
    return (
      <CustomDialog
        data-test-id="dialog"
        open={this.state.openDialog === "create"}
        scroll="body"
        onClose={this.onCloseDialog}
      >
        <Loader loading={this.state.loading} />
        <DialogTitle className="dialog-title">
          <Typography className="heading">Create New Client</Typography>
          <Close className="close-icon" data-test-id="close-btn" onClick={this.onCloseDialog} />
        </DialogTitle>
        <Formik
          data-test-id="formik"
          initialValues={{
            companyLogo: "",
            companyName: "",
            contactName: "",
            jobTitle: "",
            email: "",
            contactPhone: ""
          }}
          enableReinitialize
          validationSchema={this.createClientSchema}
          onSubmit={(values) => {
            this.createClient(values);
          }}
        >
          {({ errors, touched, values, setFieldValue, handleSubmit }) => (
            <form
              noValidate
              className="form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <DialogContent className="content">
                <Grid container columnSpacing={1} rowGap={1}>
                  <Grid item xs={12} className='upload-logo'>
                    <Typography className="label-title">Company Logo</Typography>
                    <input
                      type="file"
                      ref={this.fileInputRef}
                      accept=".png, .jpg, .jpeg"
                      style={{ display: "none" }}
                      id="client-logo"
                      data-test-id="logo-image"
                      onChange={(e) => {
                        this.handleLogo(e.target.files?.[0]);
                        setFieldValue("companyLogo", e.target.files?.[0]);
                      }}
                    />
                    {values.companyLogo &&
                      <Box className="uploaded">
                        <img src={this.state.companyLogo} className='logo' />
                        <Box className="button-wrapper">
                          <label htmlFor="client-logo">
                            <Box className='reupload-btn'>Reupload</Box>
                          </label>
                          <Box
                            className='delete-btn'
                            data-test-id="delete-btn"
                            onClick={() => {
                              this.deleteLogo();
                              setFieldValue("companyLogo", "");
                            }}
                            >
                              Delete
                          </Box>
                        </Box>
                      </Box>
                    }
                    {!values.companyLogo &&
                      <label htmlFor="client-logo">
                        <Box
                          data-test-id="drag-and-drop"
                          onDragOver={this.handleDragOverLogo}
                          onDrop={(e) => {
                            setFieldValue("companyLogo", "file");
                            this.handleDrop(e);
                          }}
                          className="logo-wrapper"
                        >
                          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" rx="8" fill="#F8FAFC" />
                            <path d="M18.5 32C16.9833 32 15.6877 31.475 14.613 30.425C13.5377 29.375 13 28.0917 13 26.575C13 25.275 13.3917 24.1167 14.175 23.1C14.9583 22.0833 15.9833 21.4333 17.25 21.15C17.6667 19.6167 18.5 18.375 19.75 17.425C21 16.475 22.4167 16 24 16C25.95 16 27.604 16.679 28.962 18.037C30.3207 19.3957 31 21.05 31 23C32.15 23.1333 33.1043 23.629 33.863 24.487C34.621 25.3457 35 26.35 35 27.5C35 28.75 34.5627 29.8127 33.688 30.688C32.8127 31.5627 31.75 32 30.5 32H25C24.45 32 23.9793 31.8043 23.588 31.413C23.196 31.021 23 30.55 23 30V24.85L21.4 26.4L20 25L24 21L28 25L26.6 26.4L25 24.85V30H30.5C31.2 30 31.7917 29.7583 32.275 29.275C32.7583 28.7917 33 28.2 33 27.5C33 26.8 32.7583 26.2083 32.275 25.725C31.7917 25.2417 31.2 25 30.5 25H29V23C29 21.6167 28.5127 20.4373 27.538 19.462C26.5627 18.4873 25.3833 18 24 18C22.6167 18 21.4377 18.4873 20.463 19.462C19.4877 20.4373 19 21.6167 19 23H18.5C17.5333 23 16.7083 23.3417 16.025 24.025C15.3417 24.7083 15 25.5333 15 26.5C15 27.4667 15.3417 28.2917 16.025 28.975C16.7083 29.6583 17.5333 30 18.5 30H21V32H18.5Z" fill="#64748B" />
                          </svg>
                          <Typography className='upload-image'>
                            Upload Image
                          </Typography>
                          <Typography className='drag'>
                            Drag and drop or upload from device
                          </Typography>
                          <Typography className='support-text'>
                            Supported formats: png, jpg, jpeg
                          </Typography>
                        </Box>
                      </label>}
                      {touched.companyLogo && errors.companyLogo &&
                        <div className="text-danger">{errors.companyLogo}</div>
                      }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="label-title">Company Name</Typography>
                    <CustomInput
                      fullWidth
                      data-test-id="company-name"
                      placeholder="Company Name"
                      value={values.companyName}
                      onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        setFieldValue("companyName", e.target.value);
                      }}
                    />
                    {touched.companyName && errors.companyName &&
                      <div className="text-danger">{errors.companyName}</div>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="label-title">Contact Name</Typography>
                    <CustomInput
                      fullWidth
                      data-test-id="contact-name"
                      placeholder="Contact Name"
                      value={values.contactName}
                      onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        setFieldValue("contactName", e.target.value);
                      }}
                    />
                    {touched.contactName && errors.contactName &&
                      <div className="text-danger">{errors.contactName}</div>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="label-title">Contact Job Title</Typography>
                    <CustomInput
                      fullWidth
                      value={values.jobTitle}
                      data-test-id="job-title"
                      placeholder="Contact Job Title"
                      onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        setFieldValue("jobTitle", e.target.value);
                      }}
                    />
                    {touched.jobTitle && errors.jobTitle &&
                      <div className="text-danger">{errors.jobTitle}</div>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="label-title">Contact Email</Typography>
                    <CustomInput
                      fullWidth
                      value={values.email}
                      data-test-id="email"
                      placeholder="Contact Email"
                      onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        this.onNameChange();
                        setFieldValue("email", e.target.value);
                      }}
                    />
                    {touched.email && errors.email &&
                      <div className="text-danger">{errors.email}</div>
                    }
                    {this.state.emailError &&
                      <div className="text-danger">{this.state.emailError}</div>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="label-title">Contact Phone</Typography>
                    <CustomInput
                      fullWidth
                      value={values.contactPhone}
                      data-test-id="phone"
                      placeholder="Contact Phone"
                      onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        const value = e.target.value;
                        if(/^\d+$/.test(value) || value === '') {
                          this.onPhoneChange();
                          setFieldValue("contactPhone", value);
                        }
                      }}
                    />
                    {touched.contactPhone && errors.contactPhone &&
                      <div className="text-danger">{errors.contactPhone}</div>
                    }
                    {this.state.contactError &&
                      <div className="text-danger">{this.state.contactError}</div>
                    }
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="dialog-action">
                <CancelButton data-test-id="cancel-btn" onClick={this.onCloseDialog}>
                  Cancel
                </CancelButton>
                <DoneButton
                  type="submit"
                >
                  Create Client
                </DoneButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </CustomDialog>
    );
  }
  AddNewUserModal = () => {
    const { newUserData } = this.state
    return (
        <CustomDialog
            scroll="body"
            data-test-id="add-formik"
            open={this.state.addNewUserModal}
            onClose={this.handleAddNewUserModalClose}
        >
            <DialogTitle className="dialog-title">
                <Typography className="heading">Add New Client User</Typography>
                <CloseIcon className="close-icon" data-testID="close-modal" onClick={this.handleAddNewUserModalClose} />
            </DialogTitle>
            <Formik
                data-test-id="add-formik"
                initialValues={{
                    firstName: "",
                    lastName: "",
                    jobTitle: "",
                    email: ""
                }}
                enableReinitialize
                validationSchema={this.userSchema}
                onSubmit={() => {
                    this.handleAddNewUserDoneClick();
                }}
            >
                {({ errors, touched, setFieldValue, handleSubmit }) => (
                    <form
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        className="form"
                    >
                        <DialogContent className="heading">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box ml="8px" mt="15px">
                                        <Select defaultValue="All Clients" fullWidth
                                            sx={{width:'100%', height: '3em',marginBottom:'5px',}}
                                        >
                                            <MenuItem value="All Clients">Select Client</MenuItem>
                                        </Select>
                                        <Typography className="label-title">First name</Typography>
                                        <CustomInput
                                            fullWidth
                                            placeholder="First name"
                                            data-testID="addFirstName"
                                            value={newUserData.firstName}
                                            onChange={(e) => {
                                                setFieldValue("firstName", e.target.value);
                                                this.handleUserDataChange("firstName", e);
                                            }}
                                        />
                                        {touched.firstName && errors.firstName &&
                                            <div className="error">{errors.firstName}</div>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box ml="8px">
                                        <Typography className="label-title">Last name</Typography>
                                        <CustomInput
                                            fullWidth
                                            placeholder="Last name"
                                            data-testID="addLastName"
                                            value={newUserData.lastName}
                                            onChange={(e) => {
                                                setFieldValue("lastName", e.target.value);
                                                this.handleUserDataChange("lastName", e);
                                            }}
                                        />
                                        {touched.lastName && errors.lastName &&
                                            <div className="error">{errors.lastName}</div>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box ml="8px">
                                        <Typography className="label-title">Job Title</Typography>
                                        <CustomInput
                                            fullWidth
                                            placeholder="Job title"
                                            data-testID="addJobTitle"
                                            value={newUserData.jobTitle}
                                            onChange={(e) => {
                                                setFieldValue("jobTitle", e.target.value);
                                                this.handleUserDataChange("jobTitle", e);
                                            }}
                                        />
                                        {touched.jobTitle && errors.jobTitle &&
                                            <div className="error">{errors.jobTitle}</div>
                                        }
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box ml="8px">
                                        <Typography className="label-title">Email</Typography>
                                        <CustomInput
                                            fullWidth
                                            placeholder="Email"
                                            data-testID="addEmail"
                                            value={newUserData.email}
                                            onChange={(e) => {
                                                setFieldValue("email", e.target.value);
                                                this.handleUserDataChange("email", e);
                                            }}
                                        />
                                        {touched.email && errors.email &&
                                            <div className="error">{errors.email}</div>
                                        }
                                        <Box className="error">{this.state.emailError}</Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box ml="8px" mb="12px">
                                        <Typography className="label-title">Role</Typography>
                                        <Box className="role">Trainee</Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className="dialog-action">
                            <CancelButton sx={{ padding: "12px 30px !important" }} onClick={this.handleAddNewUserModalClose}>
                                Cancel
                            </CancelButton>
                            <DoneButton type="submit">
                                Done
                            </DoneButton>
                        </DialogActions>
                    </form>)}
            </Formik>
        </CustomDialog>
    );
}
NotificationModal = () => { 
  return ( 
    <Modal 
      open={this.state.notificationModalOpen} 
      onClose={this.handleNotificationModalClose} 
      aria-labelledby="notification-modal-title" 
      aria-describedby="notification-modal-description" 
    > 
      <Box 
        sx={{ 
          position: "absolute", 
          top: 16, 
          right: 16, 
          width: "504px", 
          height: "754px", 

          bgcolor: "background.paper", 
          border: "none", 
          boxShadow: 24, 
          p: 4, 
          maxHeight: "80vh", 
          overflowY: "auto", 
        }} 
      > 
        <Box display="flex" justifyContent="space-between" alignItems="center"> 
          <Typography id="notification-modal-title" variant="h4" component="h2"> 
            Notifications 
          </Typography> 
          <Button 
            color="primary" 
            fullWidth 
            sx={{border:'none', paddingLeft:'90px'}} 
            onClick={this.handleMarkAllAsRead} 
          > 
            Mark all as read 
          </Button> 
           
        </Box> 
        <Box mt={2}> 
          <List sx={{ mt: 2 }}> 
            {this.state.notifications.map((notification) => ( 
              <Box key={notification.id}> 
                <ListItem button> 
                  <ListItemText 
                    primary={notification.message} 
                    secondary={notification.timestamp} 
                  /> 
                  { notification.isRead ==='false' && 
                  <Badge color="error" variant="dot" invisible={false} /> 
                  } 
                </ListItem> 
                <Divider /> 
              </Box> 
            ))} 
          </List> 
        </Box> 
        <Box sx={{textAlign:'center',justifyContent:'center'}}> 
          <Button data-testid="notification-button" onClick={this.handleNotificationClick} > 
            View all notification 
          </Button> 
        </Box> 
      </Box> 
    </Modal> 
  ); 
};
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      {this.renderCreateClientDialog()}
      <Box display={"flex"} overflow={'hidden'}>
          <Box>
              <Sidebar navigation={this.props.navigation} current="admin_dashboard" />
            </Box>
          <Grid item xs={10} sx={{overflow:'auto',height: '100%',width:'100%',padding:'20px 20px 20px 20px'}}>
          <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
          >
              <Typography variant="h4" color="primarybrand-color700-main">
              Dashboard
              </Typography>
              <Box display="flex" alignItems="center" gap={2}>
              <IconButton>
                  <NotificationsIcon onClick={this.handleNotificationModalOpen}/>
              </IconButton>
              <Button data-testid="audit-trail-button" onClick={this.handleAuditTrailClick} variant="contained" color="primary">
                  Audit trail
              </Button>
              </Box>
          </Box>
          <Box mt={4}>
              <Box display="flex" alignItems="center" gap={2}>
              <img
                  src="https://c.animaapp.com/hyS7dnhh/img/image-@2x.png"
                  alt="User"
                  width={40}
                  height={40}
              />
              <Typography variant="h5">
                  <span>Welcome</span> <span>Damodar</span>
              </Typography>
              </Box>
              <Typography variant="h6" color="primarybrand-color700-main" mt={4}>
              Quick links
              </Typography>
              <Grid container spacing={2} mt={2} sx={{marginLeft:'48px',}}>
              <Grid item sx={{width:'186px',height:'44px',marginRight:'48px'}}>
                  <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNewCourseClick}
                  startIcon={<AddIcon />}
                  fullWidth
                  sx={{
                      fontSize: "14px",
                      textTransform:'none',
                    }}
                  >
                  New Course
                  </Button>
              </Grid>
              <Grid item sx={{width:'186px',height:'44px',marginRight:'48px'}}>
                  <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNewClick}
                  startIcon={<AddIcon />}
                  fullWidth
                  sx={{
                      fontSize: "14px",
                      textTransform:'none',
                    }}
                  >
                  New Client
                  </Button>
              </Grid>
              <Grid item sx={{width:'186px',height:'44px',marginRight:'48px'}}>
                  <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleAddNewUserModalOpen}
                  startIcon={<AddIcon />}
                  fullWidth
                  sx={{
                      fontSize: "14px",
                      textTransform:'none',
                    }}
                  >
                  New Client User
                  </Button>
              </Grid>
              <Grid item sx={{width:'186px',height:'44px',marginRight:'48px'}}>
                  <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleEventManagementClick}
                  startIcon={<AddIcon />}
                  fullWidth
                  sx={{
                      fontSize: "14px",
                      textTransform:'none',
                    }}
                  >
                  New Event
                  </Button>
              </Grid>
              </Grid>
              <Typography variant="h6" color="primarybrand-color700-main" mt={4}>
              User stats
              </Typography>
              <Box display="flex" alignItems="center" gap={2} mt={2}>
              <Select defaultValue="All Clients" fullWidth
                  sx={{width:'150vh'}}
              >
                  <MenuItem value="All Clients">All Clients</MenuItem>
              </Select>
              <Box display="flex" alignItems="center" gap={1}>
                  <Typography>Filter</Typography>
                  <FilterList />
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                  <Typography>Sort by:</Typography>
                  <MuiSelect
                      data-testid="sort-select"
                      value={this.state.sortBy}
                      onChange={(e: any) => this.handleSortByChange(e)}
                      defaultValue="relevance"
                      disableUnderline
                      variant='standard'
                      sx={{
                        paddingLeft: '10px',
                        width: '150px',
                        backgroundColor: 'transparent',
                        '& .MuiSelect-icon': {
                          color: 'gray',
                        },
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: 'transparent',
                          border: '1px solid #ccc',
                          '& input': {
                            backgroundColor: 'transparent',
                            padding: ''
                          },
                        },
                      }}
                    >
                      <MenuItem value="Name (A-Z)">Name (A-Z)</MenuItem>
                      <MenuItem value="Name (Z-A)">Name (Z-A)</MenuItem>
                      <MenuItem value="Oldest to newest">Oldest to newest</MenuItem>
                      <MenuItem value="Newest to oldest">Newest to oldest</MenuItem>
                      <MenuItem value="status">Status</MenuItem>
                    </MuiSelect>
              </Box>
              </Box>
              <Grid container spacing={2} mt={2}>
              <Grid item xs={3}>
                  <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  p={2}
                  bgcolor="basicwhite"
                  borderRadius={2}
                  border={1}
                  borderColor="neutralswarm-gray300"
                  >
                  <Typography color="neutralscool-gray500" fontWeight="bold">
                      Live Users
                  </Typography>
                  <Typography variant="h4" color="primarybrand-color700-main">
                      1000
                  </Typography>
                  </Box>
              </Grid>
              <Grid item xs={3}>
                  <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  p={2}
                  bgcolor="basicwhite"
                  borderRadius={2}
                  border={1}
                  borderColor="neutralswarm-gray300"
                  >
                  <Typography color="neutralscool-gray500" fontWeight="bold">
                      Users Assigned Courses
                  </Typography>
                  <Typography variant="h4" color="primarybrand-color700-main">
                      700
                  </Typography>
                  </Box>
              </Grid>
              <Grid item xs={3}>
                  <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  p={2}
                  bgcolor="basicwhite"
                  borderRadius={2}
                  border={1}
                  borderColor="neutralswarm-gray300"
                  >
                  <Typography color="neutralscool-gray500" fontWeight="bold">
                      Courses Left to Complete
                  </Typography>
                  <Typography variant="h4" color="statusamber-400">
                      300
                  </Typography>
                  </Box>
              </Grid>
              <Grid item xs={3}>
                  <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  p={2}
                  bgcolor="basicwhite"
                  borderRadius={2}
                  border={1}
                  borderColor="neutralswarm-gray300"
                  >
                  <Typography color="neutralscool-gray500" fontWeight="bold">
                      Total Learning Time
                  </Typography>
                  <Typography variant="h4" color="primarybrand-color700-main">
                      124h
                  </Typography>
                  </Box>
              </Grid>
              </Grid>
              <Button
              variant="outlined"
              color="primary"
              sx={{ mt: 4, borderColor: "primarybrand-color700-main" , marginLeft:'50%'}}
              >
              Full Analytics
              </Button>
          </Box>
          </Grid>
          {this.AddNewUserModal()}
          {this.NotificationModal()}
          </Box>
          <AdminFooter/>
          </ThemeProvider>
          </StyledEngineProvider>
    );
    // Customizable Area Start
  }
}
// Customizable Area Start
const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: '720px',
    borderRadius:'8px 8px 8px 8px',
    gap:'8px',
  },
  '& > .MuiPaper-root': {
    width: '100%',
    borderRadius: "8px 8px 25px",
    [theme.breakpoints.down('md')]: {
      maxWidth: "80% !important",
    },
  },
  "& .MuiGrid-root > .MuiGrid-item": {
    paddingTop: "7px",
  },
  "& .dialog-title": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 15px 12px 30px",
    borderBottom: "1px solid #E2E8F0",
    [theme.breakpoints.down('sm')]: {
      padding: "20px 15px 25px 20px",
      borderBottom: "0px",
    },
    "& .heading": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
    },
    "& .close-icon": {
      cursor: "pointer",
      color: "#334155",
    },
  },
  "& .content": {
    padding: "9px 30px 15px !important",
    [theme.breakpoints.down('sm')]: {
      padding: "15px !important",
    },
  },
  "& .course-content": {
    padding: "0px 7px !important",
    "& .loader": {
      color:"#000"
    }
  },
  "& .loader-wrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "790px",
    height: "295px",
  },
  "& .upload-logo": {
    "& .uploaded": {
      marginTop: "15px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: '17px 15px 20px',
      border: "1px dashed #CBD5E1",
      borderRadius: "8px",
      "& .logo": {
        width: "150px",
        height: "150px",
        borderRadius: "4px",
        objectFit: "cover",
      },
      "& .button-wrapper": {
        display: "flex",
        marginTop: "17px",
        gap: "15px",
      },
      "& .reupload-btn": {
        cursor: "pointer",
        fontSize: "14px",
        textTransform: "none !important",
        fontFamily: "Heebo-SemiBold",
        color: "black !important",
        textDecoration: "underline"
      },
      "& .delete-btn": {
        cursor: "pointer",
        fontSize: "14px",
        textTransform: "none !important",
        fontFamily: "Heebo-SemiBold",
        color: "red !important",
        textDecoration: "underline"
      }
    },
    "& .logo-wrapper": {
      gap: '3px',
      marginTop: "15px",
      padding: '25px 15px 25px',
      cursor: "pointer",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      border: "1px dashed #CBD5E1",
      "& .upload-image": {
        marginTop: "15px",
        fontFamily: 'Heebo-Bold',
        fontSize: '18px',
        color: "#000"
      },
      "& .drag": {
        fontFamily: 'Heebo',
        fontSize: '16px',
        color: '#334155',
      },
      "& .support-text": {
        marginTop: "10px",
        fontFamily: 'Heebo',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: '400',
        color: '#94A3B8',
      }
    },
  },
  "& .label-title": {
    fontSize: "14px",
    color: "#64748B",
    paddingBottom: '1px',
    fontFamily: "Heebo-SemiBold",
  },
  "& .dialog-action": {
    padding: "10px 16px 10px",
    borderTop: "1px solid #E2E8F0",
  },
  '& .package-wrapper .package-card:nth-of-type(odd)': {
    paddingRight: "15px",
    [theme.breakpoints.down('sm')]: {
      paddingRight: "0px",
    }
  },
  "& .package-wrapper .package-card": {
    paddingTop: "15px"
  },
  "& .packages": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    borderRadius: '8px',
    backgroundColor:"#fff",
    boxShadow: "0px 2px 20px 0px #00000014",
  }
}));
const CustomInput = styled(InputBase)({
  border: "1px solid #CBD5E1",
  borderRadius: "5px",
  fontFamily: "Heebo-Regular",
  backgroundColor: "#fff",
  marginTop: "3px",
  fontSize: "14px",
  "& input": {
    padding: "12px 10px",
    '&:placeholder': {
      fontWeight: 500
    }
  },
  "& .MuiInputBase-inputMultiline": {
    fontSize: "14px",
    padding: "10px 8px",
  },
});

const CancelButton = styled(Button)({
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "12px 30px !important",
  backgroundColor: "#F0F0F0",
  borderRadius: "5px !important",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

const DoneButton = styled(Button)({
  marginLeft: "15px !important",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "12px 22px !important",
  backgroundColor: "black",
  borderRadius: "5px !important",
  "&:hover": {
    backgroundColor: "black",
  },
});
// Customizable Area End