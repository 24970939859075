//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { createRef } from 'react';
import { toast } from "react-toastify";
import { dummyImage, dummyVideo, userImage } from './assets';
import { v4 as uuidv4 } from 'uuid';
import { apiCall } from '../../../components/src/ApiCall.web';

const defaultCarouselContent = {
  media: dummyImage,
  type: "image",
  heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">This is heading for carousel</p>`,
  description: `<p style="font-family:'Crimson Text';font-size:18px">This is subtext</p>`,
};

const defaultContent = {
  "Text": {
    type: "Text",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">Heading</p>`,
    content: `<p style="font-family:'Crimson Text';font-size:20px">At its core, UX design is about enhancing the overall experience a user has while interacting with a product, whether it's a website, an app, or any digital tool. UX design involves understanding the users' needs, behaviors, and motivations to create products that solve real problems in an intuitive way.</p>`,
  },
  "List": {
    type: "List",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    listType: "unordered",
    heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">This is the subheading with bullet points.</p>`,
    content: `<ul>
        <li style="font-family:'Crimson Text';font-size:18px;">Research: Gathering information about users and their needs through interviews, surveys, and observations.</li>
        <li style="font-family:'Crimson Text';font-size:18px;">Empathy: Understanding and sharing the feelings of users, which helps in creating user-focused designs.</li>
        <li style="font-family:'Crimson Text';font-size:18px;">Defining the Problem: Based on the research, identifying the core issues users face that need to be addressed.</li>
      </ul>`
  },
  "Image": {
    type: "Image",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    image: dummyImage,
    caption: "This is the image caption, you can left it empty if not needed"
  },
  "Video": {
    type: "Video",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    video: dummyVideo,
    caption: "This is the caption of the video you can add, if not needed then leave empty"
  },
  "Quote": {
    type: "Quote",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    image: userImage,
    content: `<blockquote style="text-align:center;"><span style="font-size:16px;font-family:Crimson Text;">"Consistency is one of the most powerful usability principles: when things always behave the same, users don't have to worry about what will happen."</span></blockquote>`,
    name: `<p style="text-align:center;"><span style="font-size:20px;font-family:Crimson Text;">Jakob Nielson</span></p>`,
  },
  "Labeled graphic":{
    type: "Label",
    paddingTop: 30,
    width:'70vw',
    paddingBottom: 30,
    image: dummyImage,
    content:[
      {
        id:'1',
        x:100,
        y:100,
        title:`<p style="font-family:'Crimson Text';font-size:16px;font-weight:bold;">Heading of Card</p>`,
        description: `<p style="font-family:'Crimson Text';font-size:14px">This is the subtext that you can edit in the editor</p>`,
        visibleContent:false,
        contentRightSide:true
      }
    ]
  },
  "Carousel": {
    type: "Carousel",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    content: [defaultCarouselContent,defaultCarouselContent]
  },
  "Table":{
    type:"Table",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    table_rows_attributes: [
      {
        id:'row-1',
        "column1": {id:'1',value:"<p>Row 1 Column 1</p>"},
        "column2": {id:'2',value:"<p>Row 1 Column 2</p>"},
        "column3": {id:'3',value:"<p>Row 1 Column 3</p>"},
        "column4": {id:'4',value:"<p>Row 1 Column 4</p>"}
      },
      {
        id:'row-2',
        "column1": {id:'5',
          value:"<p>Row 2 Column 1</p>"},
        "column2": {id:'6',
          value:"<p>Row 2 Column 2</p>"},
        "column3": {
          value:"<p>Row 2 Column 3</p>",
          id:'7',},
        "column4": {
          value:"<p>Row 2 Column 4</p>",id:'8',}
      },
      {
        id:'row-3',
        "column1": {
          value:"<p>Row 3 Column 1</p>",
          id:'9',
        },
        "column2": {
          value:"<p>Row 3 Column 2</p>",
          id:'10',
        },
        "column3": {
          id:'11',
          value:"<p>Row 3 Column 3</p>"
        },
        "column4": {
          id:'12',
          value:"<p>Row 3 Column 4</p>"
        }
      }
    ]
  },
  "Flash card":{
    type:"Card",
    paddingTop: 30,
    paddingBottom: 30,
    cards:[
      {
        id:'1',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 1</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">True. Both UX and UI designers often use these tools for different purposes—UX for wireframes/prototypes and UI for visual designs.</p>`,
        flipped:true
      },
      {
        id:'2',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 2</p>`,
        back:`<p style="font-family:'Crimson Text'font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'3',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 3</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'4',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 4</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'5',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 5</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'6',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 6</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
    ]
  },
  "Test knowledge":{
    type:'Test',
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    content:{
      question:'Enter your question here ',
      isSubmit:false,
      answerCorrect:false,
      isMultiple:false,
      supportingText:'This is the text that will appear after the submission of correct answer.',
      supportingTextInCorrect:'This is the text that will appear after the submission of incorrect answer.',
      options:[
        {
          id:'1',
          answer:'This is the option one to be selected if right.',
          isSelected:false,
          isCorrect:false
        },
        {
          id:'2',
          answer:'This is the option two to be selected if right.',
          isSelected:false,
          isCorrect:false
        },
        {
          id:'3',
          answer:'This is the option three to be selected if right or wrong.',
          isSelected:false,
          isCorrect:false
        },
        {
          id:'4',
          answer:'All of the above.',
          isSelected:false,
          isCorrect:false
        },
      ]
    }
  },
  "Button":{
    type:"Button",
    paddingTop: 50,
    paddingBottom: 50,
    width: "70vw",
    finishButton:false,
  }
};

export type PaginationProps = {
  pages: number[];
  activePage: number;
  onClick: (page: number) => void;
}

export type CarouselButtonProps = {
  type: "PREV" | "NEXT";
  isEdge: boolean;
  onClick: () => void;
}

export interface DefaultInputTitle {
  title: string,
  isActiveToChange: boolean
}

export interface LessonItem {
  id: number,
  lessonTitle: DefaultInputTitle,
  isPopUpActive: boolean;
  courseData: any[];
  quizData: any[];
  assessmentData: any;
}

export interface LessonCreationBar {
  title: string,
  isActive: boolean,
}

export interface Position {
  x: number,
  y: number
}

export type LessonListType = "ordered" | "unordered" | "grid";
export const crypto = require('crypto');

export interface ICourseImage {
  id?: string,
  image?: string,
  fileName?: string
};
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation: object;
  idContent: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  description: string;
  lessonCreationBar: LessonCreationBar;
  lessonList: LessonItem[];
  lessonListType: LessonListType;
  courseTitle: string;
  authorName: string
  lessonTitle: string;
  lessonAuthorName: string;
  currentLessonId: number;
  isLessonVisible: boolean;
  isToolbarVisible: boolean;
  isFormatVisible: boolean;
  courseData: any;
  currentObj: any;
  currentIndex: number;
  isDrawerOpen: boolean;
  buttonType: string;
  isSidebarOpen: boolean;
  top: number;
  bottom: number;
  isPreview: boolean;
  draggedItemIndex: null | number;
  isQuizVisible: boolean;
  isCourseLearning: boolean;
  selectedCourseId: number;
  selectedCourseIndex: number;
  isFromMainPage: boolean;
  selectedId: number;
  quizData: any;
  quizObj: any;
  isQuizEditorVisibile: boolean;
  draggingIndex:number;
  currentParentIndex:number;
  offset:{x:number,y:number};
  selectedRowIndex: number,
  selectedColumnIndex: number,
  selectedCellId:string,
  flip:boolean,
  currentQuestion: any;
  errors: null | Array<string>;
  isErrorVisible: boolean;
  learningPath: string[];
  requirement: {
    "duration": string,
    "course_requirements": string,
  }
  imageObj: ICourseImage;
  coverImg: ICourseImage;
  coverDescImg: ICourseImage;
  assessmentTitle: string;
  assessmentRequirements: any;
  currentAssessmentData: any;
  isFinalAssessment: boolean;
  isCurrentAssessmentFinal: boolean;
  finalAssessmentQuizData: null;
  assessmentsList: any;
  assessmentDataSaved: boolean;
  courseId:number,
  courseModuleIDs:Array<number>;
  titleLoader: boolean;
  isCourseSidebarOpen: boolean;
  plusAnchorEl: HTMLElement | null;
  isUpload: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  lessonPopupMenuRef: React.RefObject<HTMLDivElement>;
  lessonTypeMenuRef: React.RefObject<HTMLDivElement>;
  bottomRef: React.RefObject<HTMLDivElement>;
  formatRef: React.RefObject<HTMLDivElement>;
  createAssessmentApiCallId: string;
  publishApiCallId: string = "";
  getCourseInfoApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      description: `<p>This is the starting of the course description. Following are the numbered formatting.</p>`,
      lessonCreationBar: { title: "", isActive: true },
      isLoading: false,
      lessonList: [],
      lessonListType: "unordered",
      courseTitle: "",
      authorName: "",
      lessonTitle: "",
      lessonAuthorName: "",
      isInitialLoad: true,
      currentLessonId: -1,
      isLessonVisible: false,
      isToolbarVisible: false,
      courseData: [],
      currentObj: {},
      top: 0,
      bottom: 0,
      currentIndex: -1,
      isDrawerOpen: false,
      isSidebarOpen: false,
      buttonType: "",
      isPreview: false,
      draggedItemIndex: null,
      isQuizVisible: false,
      isCourseLearning: false,
      selectedCourseId: 0,
      courseId: -1,
      selectedCourseIndex: -1,
      isFromMainPage: false,
      selectedId: -1,
      quizData: [],
      quizObj: {},
      isQuizEditorVisibile: false,
      offset: { x: 0, y: 0 },
      draggingIndex:null,
      selectedRowIndex: null,
      selectedColumnIndex: null,
      selectedCellId:null,
      flip:false,
      currentQuestion: null,
      errors: null,
      isErrorVisible: false,
      learningPath: [],
      requirement: {
        "duration": "",
        "course_requirements": "",
      },
      imageObj: { id: "", image: "", fileName: "" },
      coverImg: { id: "", image: "", fileName: "" },
      coverDescImg: { id: "", image: "", fileName: "" },
      assessmentTitle: "",
      assessmentRequirements: null,
      assessmentsList:[],
      currentAssessmentData: null,
      isFinalAssessment: false,
      isCurrentAssessmentFinal: false,
      finalAssessmentQuizData: null,
      assessmentDataSaved:false,
      titleLoader: false,
      isCourseSidebarOpen: true,
      plusAnchorEl: null,
      isUpload: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
        this.lessonPopupMenuRefs = {};
        this.lessonTypeMenuRef = createRef<HTMLDivElement>();
        this.bottomRef = createRef<HTMLDivElement>();
        this.formatRef = createRef<HTMLDivElement>();
        this.createAssessmentApiCallId = '';
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAssessmentApiCallId) {
            this.handleCreateAssessmentResponse(responseJson);
        } else if (apiRequestCallId === this.publishApiCallId) {
         this.handlePublishAPIResponse(responseJson);
        } else if (apiRequestCallId === this.getCourseInfoApiCallId) {
          this.handleCourseInfoApiResponse(responseJson);
        }
        this.setState({ isLoading: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleTableResponse = (rows: {[key]: string}[]) => {
    return rows.map((row, index) => {
      const transformedRow = {
        id: (index + 1).toString(),
      };
      Object.keys(row).forEach((key, colIndex) => {
        if(row[key]) {
          transformedRow[`column${colIndex + 1}`] = {
            id: (colIndex + 1).toString(),
            value: row[key],
          };
        }
      });
      return transformedRow;
    })
  }

  getCourseDataFromResponse = (contentArr: {}) => {
    return contentArr.map((item) => {
      if (item.block_type === "text") {
        return {
          type: "Text",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          heading: item.heading,
          content: item.content,
        }
      } else if (item.block_type === "list") {
        return {
          type: "List",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          listType: item.list_type,
          heading: item.heading,
          content: item.content
        }
      } else if (item.block_type === "image") {
        return {
          type: "Image",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          image: item.image ?? item.image_url,
          isMediaUrl: true,
          caption: item.caption
        }
      } else if (item.block_type === "video") {
        return {
          type: "Video",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          video: item.video ?? item.video_url,
          isMediaUrl: true,
          caption: item.caption
        }
      } else if (item.block_type === "tabular") {
        return {
          type: "Table",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          table_rows_attributes: this.handleTableResponse(item.table_rows)
        }
      } else if (item.block_type === "carousel") {
        return {
          type: "Carousel",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          content: item.slides.map((item) => ({
            id: item.id,
            isMediaUrl: true,
            type: item.media_type,
            media: item.media ?? item.media_url,
            heading: item.title,
            description: item.description,
          }))
        }
      } else if (item.block_type === "flashcard") {
        return {
          type: "Card",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          cards: item.cards.map((card) => ({ id: card.id, front: card.front_side, back: card.back_side, flipped: false }))
        }
      } else if (item.block_type === "labeled_graphic") {
        return {
          type: "Label",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          image: item.image ?? item.image_url,
          isMediaUrl: true,
          content: item.labels.map((item, index: number) => ({
            id: index,
            x: item.x_position,
            y: item.y_position,
            title: item.title,
            contentRightSide: false,
            description: item.description,
            visibleContent: item.visible_content,
          }))
        }
      } else if (item.block_type === "quote") {
        return {
          type: "Quote",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          image: item.image,
          content: item.content,
          name: item.name,
        }
      } else if (item.block_type === "test_knowledge") {
        return {
          type: "Test",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          content: {
            isSubmit: false,
            answerCorrect: false,
            question: item.test_questions[0].question_text,
            isMultiple: item.test_questions[0].question_type !== "single_response",
            supportingText: item.test_questions[0].correct_feedback,
            supportingTextInCorrect: item.test_questions[0].incorrect_feedback,
            options: item.test_questions[0].options.map((item) => ({
              id: item.id,
              answer: item.text,
              isSelected: false,
              isCorrect: item.is_correct,
            }))
          }
        }
      } else if (item.block_type === "button") {
        return {
          type: "Button",
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          finishButton: item.is_finished,
        }
      }
    });
  }

  handleCourseInfoApiResponse = (responseJson: {}) => {
    if(responseJson.error) {
      toast.error(responseJson.error);
      return;
    }
    const response = responseJson.data.attributes;
    const lessonArray = response.course_modules.map((item) => {
      return {
        id: item.id,
        courseData: this.getCourseDataFromResponse(item.sections[0].content_blocks),
        lessonTitle: {
          isActiveToChange: false,
          title: item.title
        },
      }
    });
    this.setState({
      lessonList: lessonArray,
      titleLoader: true,
      courseTitle: response.title,
      description: response.description,
      authorName: response.author_name,
      lessonListType: response.lesson_format,
      learningPath: response.learning_paths_ids,
      requirement: {
        "duration": response.duration,
        "course_requirements": response.course_requirements,
      }
    },() => {
      this.setState({ isLoading: false });
    });
  }

  handleCourseApiError = (errors: Array<string> | Array<{token?: string}>) => {
    if (errors[0]?.token) {
      this.props.navigation.navigate("AdminLogin");
    } else {
      this.setState({ isErrorVisible: true, errors: errors });
    }
  }

  onGoBack = () => {
    if(this.state.isFromMainPage) {
      this.setState({ isFromMainPage: false, isCourseLearning: false });
    } else if(this.state.isCourseLearning) {
      this.setState({ isCourseLearning: false, isLessonVisible: true, isCourseSidebarOpen: true, isPreview: false, selectedId: -1 });
    } else if(this.state.isPreview) {
      this.setState({ isPreview: false });
    } else if(this.state.isQuizVisible) {
      this.setState({
        quizData: [],
        quizObj: {},
        assessmentTitle: "",
        isQuizVisible: false,
        currentAssessmentData: null,
        isQuizEditorVisibile: false,
        isCurrentAssessmentFinal: false,
      });
    } else {
      this.handleLessonGoBack()
    }
  };

  handlePublishAPIResponse = (responseJson: any) => {
    if (responseJson.message) {
      toast.success(responseJson.message);
      this.setState({
        courseId: responseJson.course_id,
        courseModuleIDs: responseJson.course_module_ids
      })
      const isAssessmentDataAvailable = this.state.lessonList.find(lessonItem => lessonItem?.assessmentData);
      if (isAssessmentDataAvailable) {
        this.createAssessment();
      } else {
        this.props.navigation.navigate("CourseLibrary");
      }
    } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
      this.handleCourseApiError(responseJson.errors);
    }
  }

  onCloseErrorDialog = () => {
    this.setState({ isErrorVisible: null });
  }

  handleCreateAssessmentResponse = (responseJson) => {
    if (responseJson.data) {
      if (this.state.assessmentDataSaved) {
        toast.success('Assessment data saved successfully')
        this.props.navigation.navigate("CourseLibrary");
      }
    } else {
      toast.error(responseJson?.errors?.join(' '));
    }
  };

  onUploadButtonClick = () => {
    this.setState({ isUpload: !this.state.isUpload });
  }

  onCourseImageChange = (e: React.ChangeEvent<HTMLInputElement>, currentState: string, index: number) => {
    const file = e.target.files?.[0];
    const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png'];

    if (!file) return;

    if (supportedFormats.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          this.setState(prevState => ({
            [currentState]: {
              ...prevState[currentState],
              fileError: "",
              fileName: file.name,
              image: reader.result as string
            }
          }));
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      this.setState(prevState => ({
        [currentState]: { ...prevState[currentState], fileError: "Only .png, .jpg, .jpeg allowed" }
      }));
    }
  }

  handleLessonGoBack = ()=>{
    let lessonList = [...this.state.lessonList];
    lessonList = lessonList.map((item) => (
      item.id === this.state.currentLessonId
        ? { ...item, courseData: this.state.courseData }
        : item
    ));
    this.setState({
      lessonList,
      courseData: [],
      currentObj: {},
      buttonType: "",
      isPreview: false,
      currentIndex: -1,
      currentLessonId: -1,
      isDrawerOpen: false,
      isSidebarOpen: false,
      isLessonVisible: false,
      isToolbarVisible: false,
      currentAssessmentData: null,
    });
  }

  handleQuizGoBack = () => {
    let lessonList = [...this.state.lessonList];
    if(this.state.isCurrentAssessmentFinal) {
      this.setState({ finalAssessmentQuizData: this.state.currentAssessmentData });
    } else {
    lessonList = lessonList.map((item) => (
      item.id === this.state.currentLessonId
        ? { ...item, quizData: this.state.quizData, assessmentData: this.state.currentAssessmentData || null }
        : item
    ));
    }
    this.setState({
      lessonList: lessonList,
      isQuizEditorVisibile: false,
      isQuizVisible: false,
      quizData: [],
      currentAssessmentData: null,
      quizObj: {},
      assessmentTitle: "",
      isCurrentAssessmentFinal: false,
    });
  }

  sidebarHandler = () => {
    this.setState({
      currentIndex: this.state.courseData.length - 1,
      isSidebarOpen: !this.state.isSidebarOpen,
    });
  }

  openToolbar = () => {
    this.setState({ 
      buttonType: "",
      currentIndex: -1,
      isToolbarVisible: true,
    },() => {
      this.bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    });
  }

  onScrollToBottom = (id: string) => {
    setTimeout(() => {
      const container = document.getElementById(id);
      if (container) {
        container.scrollIntoView({ behavior: 'smooth', block: "end" });
      }
    }, 0);
  }

  handleTypeButtonClick = (label: string, isFromSidebar?: boolean) => {
    let courseData = [...this.state.courseData];
    const obj = { ...defaultContent[label], id: uuidv4() };

    const isButtonTypeVisible = courseData.findIndex(item=>item.type === 'Button') !== -1
    if(label === "Button" && (isButtonTypeVisible || this.state.currentIndex !== -1)){
      return
    }
    if(isButtonTypeVisible && this.state.currentIndex===this.state.courseData.length -1) return
    if (obj) {
      const isScroll = isFromSidebar && this.state.currentIndex === this.state.courseData.length - 1;
      const index = isFromSidebar ? this.state.currentIndex : this.state.courseData.length - 1;
      courseData.splice(index + 1, 0, obj);
      this.setState({ courseData, currentIndex: -1, isToolbarVisible: false, isSidebarOpen: false }, () => {
        if (isScroll) {
        this.onScrollToBottom('bottom-content');
        }
      });
    }
  }

  handleEditClick = (index: number) => {
    this.setState({ 
      currentIndex: index,
      buttonType: "edit",
      isDrawerOpen: true,
      currentObj: this.state.courseData[index],
    });
  }

  handleFormatClick = (index: number) => {
    if(this.state.buttonType === "format" && this.state.currentIndex === index) {
      this.hideFormatOption();
    } else {
      this.setState({
        currentIndex: index,
        buttonType: "format",
      }, () => {
        if (this.formatRef.current) {
          this.formatRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      });
    }
  }

  handleCopyClick = (index: number) => {
    let courseData = [...this.state.courseData];
    let obj = courseData[index];
    courseData.splice(index + 1, 0, obj);
    this.setState({ courseData });
  }

  handleDeleteClick = (index: number) => {
    let courseData = [...this.state.courseData];
    courseData.splice(index, 1);
    this.setState({ courseData });
  }

  hideFormatOption = () => {
    this.setState({ currentIndex: -1, buttonType: "" });
  }

  handlePadding = (e, key: string) => {
    let courseData = [...this.state.courseData];
    let obj = courseData[this.state.currentIndex];
    obj = { ...obj, [key]: e.target.value };
    courseData[this.state.currentIndex] = obj;
    this.setState({ courseData });
  }

  handleBlockPadding = (e) => {
    let courseData = [...this.state.courseData];
    let obj = courseData[this.state.currentIndex];
    const value = parseInt(e.target.value);
    obj = {...obj, paddingTop: value, paddingBottom: value};
    courseData[this.state.currentIndex] = obj;
    this.setState({ courseData });
  }

  getCurrentContent = () => {
    const { currentObj } = this.state;
    const { type, isMediaUrl, image, video } = currentObj;
    let obj = {
      ...currentObj,
      fileError: undefined,
      urlError: undefined,
    };
    if (type === "Image") {
      obj.mediaUrl = isMediaUrl ? image : undefined;
    } else if (type === "Video") {
      obj.mediaUrl = isMediaUrl ? video : undefined;
    } else if (type === "Carousel") {
      obj.content = obj.content.map((item) => ({
        ...item,
        fileError: undefined,
        urlError: undefined,
        mediaUrl: item.isMediaUrl ? item.media : undefined
      }));
    }
    return obj;
  }

  handleDrawerToggle = () => {
    const courseData = [...this.state.courseData];
    courseData[this.state.currentIndex] = this.getCurrentContent();
    this.setState({
      courseData,
      buttonType: "",
      currentObj: {},
      currentIndex: -1,
      isDrawerOpen: !this.state.isDrawerOpen
    });
  }

  onLessonCreation = (lessonItem: LessonItem, index: number) => {
    this.setState({
      isLessonVisible: true,
      selectedCourseIndex: index,
      selectedCourseId: index,
      currentLessonId: lessonItem.id,
      lessonTitle: lessonItem.lessonTitle.title,
      lessonAuthorName: this.state.authorName,
      courseData: this.state.lessonList[index].courseData,
      lessonCreationBar:{isActive:false}
    }, () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  deleteAssessment = (id: number | string) => {
    this.setState(prevState => ({
      lessonList: prevState.lessonList.map(item =>
        item.id === id ? { ...item, quizData: [] } : item
      )
    }));
  }

  changeChapter = (index: number) => {
    this.setState({ selectedCourseId: index }, () => {
      const container = document.getElementById('learning-wrapper');
      if (container) {
        container.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  }

  onStartCourse = () => {
    this.setState({ isCourseLearning: true, selectedCourseId: 0, isFromMainPage: true });
  }

  handleCourseSidebar = () => {
    this.setState({ isCourseSidebarOpen: !this.state.isCourseSidebarOpen });
  }

  onCreateFinalAssessment = () => {
    const quizData = this.state.finalAssessmentQuizData?.questionbanks_attributes ?? [];
    const requirementData = {
      noOfQuestions: this.state.finalAssessmentQuizData?.no_of_questions,
      noOfAnswers: this.state.finalAssessmentQuizData?.no_of_answers,
      immediateFeedback: this.state.finalAssessmentQuizData?.immediate_feedback,
      showAnswer: this.state.finalAssessmentQuizData?.show_answer,
      scoreOnly: this.state.finalAssessmentQuizData?.score_only,
      retakeOption: this.state.finalAssessmentQuizData?.retake_option 
    };
    this.setState({
      isQuizVisible: true,
      isQuizEditorVisibile: true,
      isCurrentAssessmentFinal: true,
      quizData: quizData,
      assessmentRequirements: requirementData,
      currentAssessmentData: this.state.finalAssessmentQuizData,
    },() => {
      if(quizData.length === 0) {
        this.handleAddNewQuestion();
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  onFinalAssessmentClick = () => {
    this.setState({
      quizData: [],
      plusAnchorEl: null,
      isFinalAssessment: true,
      currentAssessmentData: null,
    });
  }

  onDeleteFinalAssessment = () => {
    this.setState({
      quizData: [],
      isFinalAssessment: false,
      currentAssessmentData: null,
      finalAssessmentQuizData: null,
      isCurrentAssessmentFinal: false,
    });
  }

  onQuizCreation = (lessonItem: LessonItem, index: number) => {
  
    this.setState(
      {
        currentLessonId: lessonItem.id,
        isQuizVisible: true,
        isQuizEditorVisibile: true,
        quizData: this.state.lessonList[index]?.quizData,
        currentAssessmentData: this.state.lessonList[index]?.assessmentData || null,
      },
      () => {
        if (!lessonItem.quizData?.length) {
          this.setState({currentAssessmentData:{}},()=>{
            this.handleAddNewQuestion();
          })
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    );
  };

  handleModalClose = () => {
    this.setState({ currentQuestion: null });
  };

  onClickDeleteQuestion = (index) => {
    this.setState({ currentQuestion: index });
  };

  handleDeleteQuestion = () => {
    const data = Object.assign(this.state.quizData);
    data.splice(this.state.currentQuestion, 1);
    this.setState({ quizData: data,currentQuestion:null });
  };

  isMultiSelect = (questionType) => questionType === "multiple_correct_options";
  
  validateQuestions = () => {
    let valMsg = [];
    let descriptionNotAvailable = false;
    let optionDescNotAvailable=false;
    let multiSelectValidation = false;
    this.state.quizData.forEach((item, index) => {
      if(!item.description?.replaceAll('<p>','').replaceAll('</p>','')?.replaceAll('\n','')?.length) {
        descriptionNotAvailable = true;
      }
      let count = 0;
      let singleSelected = false;
      item.answer_options_attributes.forEach(option => {
        if (!option.description?.replaceAll('<p>','').replaceAll('</p>','')?.replaceAll('\n','')?.length) {
          optionDescNotAvailable = true;
        }
        if (option.is_correct && this.isMultiSelect(item.question_type)) {
          count = count + 1;
        } else if (option.is_correct && !this.isMultiSelect(item.question_type)) {
          singleSelected = true;
        }
      })
      valMsg = this.checkSelectVal(item, count, index, singleSelected, valMsg);
    })
    valMsg = this.checkDescVal(descriptionNotAvailable, optionDescNotAvailable, valMsg);
    return valMsg;
  }

  checkDescVal = (descriptionNotAvailable, optionDescNotAvailable, valMsg) => {
    let errors = valMsg;
    if (descriptionNotAvailable) {
      errors.unshift('Please add description to all question.')
    }
    if (optionDescNotAvailable) {
      errors.push('Please add description to all options ');
    }
    return errors;
  }

  checkSelectVal = (item, count, index, singleSelected, valMsg) => {
    let errors = valMsg;
    if (this.isMultiSelect(item.question_type) && count < 2) {
      errors.push('Please select more than one option for question ' + (1 + index) + '.');
    }
    if (!singleSelected && !this.isMultiSelect(item.question_type)) {
      errors.push('Please select correct answer for question ' + (1 + index) + '.');
    }
    return errors;
  }

  updateValidData = () => {
    if (this.state.assessmentRequirements) {
      let assessment = {
        title: this.state?.assessmentTitle || '',
        no_of_questions: this.state.assessmentRequirements?.noOfQuestions,
        no_of_answers: this.state.assessmentRequirements?.noOfAnswers,
        show_answer: this.state.assessmentRequirements?.showAnswer,
        immediate_feedback: this.state.assessmentRequirements?.immediateFeedback,
        score_only: this.state.assessmentRequirements?.scoreOnly,
        retake_option: this.state.isCurrentAssessmentFinal ? this.state.assessmentRequirements?.retakeOption : undefined,
        assessment_type: this.state.isCurrentAssessmentFinal ? "final_assessment" : "lesson_assessment",
        questionbanks_attributes: Object.assign(this.state.quizData),
      };
      this.setState({ currentAssessmentData: Object.assign(assessment) })
    }
    this.setState({ assessmentRequirements: null, assessmentTitle: '' }, () => {
      this.handleQuizGoBack();
    });
  }

  saveAssessment = () => {
    const result = this.validateQuestions();
    const isReqValid = ((this.state.assessmentRequirements?.noOfAnswers && this.state.assessmentRequirements?.noOfQuestions) || (this.state.currentAssessmentData?.no_of_questions && this.state.currentAssessmentData?.no_of_answers))
    if (result.length) {
      toast.error(result.join(' '));
      return;
    } else if (!isReqValid) {
      toast.error('Please add assessment requirements.');
      return;
    } else if (!this.state.assessmentTitle.length && !this.state.currentAssessmentData?.title?.length) {
      toast.error('Assessment title cant be blank.')
      return;
    } else {
      this.updateValidData();
    }
  }

  prepareAssessmentReq = (lesson, index) => {
    let data = lesson.assessmentData;
    data['course_id'] = this.state.courseId;
    data['course_module_id'] = this.state.courseModuleIDs[index];
    return data;
  }

  createAssessment = async () => {
    this.setState({ assessmentDataSaved: false });
    for (const [index, lesson] of this.state.lessonList.entries()) {
      if (lesson.assessmentData) {
        const reqBody = this.prepareAssessmentReq(lesson, index);
        this.createAssessmentApiCallId = await apiCall({
          method: 'POST',
          contentType: 'application/json',
          endPoint: `bx_block_assessmenttest/assessments`,
          body: reqBody,
          token: localStorage.getItem('token'),
        });
      }
      this.setState({ assessmentDataSaved: true });
    }
    if (this.state.isFinalAssessment) {
      let reqBody = this.state.finalAssessmentQuizData;
      reqBody['course_id'] = this.state.courseId;
      this.createAssessmentApiCallId = await apiCall({
        method: 'POST',
        contentType: 'application/json',
        token: localStorage.getItem('token'),
        endPoint: `bx_block_assessmenttest/assessments`,
        body: reqBody,
      });
    }
  };

  addLeadingZero(num) {
    return num < 10 ? String(num).padStart(2, '0') : num;
  }

  handleQuestionTypeChange = (data, index) => {
    const quizData = [...this.state.quizData];
    quizData[index].question_type = data.value;
    const question = quizData[index];
    question.answer_options_attributes = question.answer_options_attributes.map((opt, idx) => ({
      ...opt,
      is_correct: false
    }));
    quizData[index] = question;
    this.setState({ quizData });
  };

  handleQuestionChange = (value, index) => {
    const quizData = [...this.state.quizData];
    quizData[index].description = value;
    this.setState({ quizData });
  };

  updateRequirements = (values) => {
    this.setState({ assessmentRequirements: values })
  }

  onSaveCourseRequirements = (duration: string, course_requirements: string) => {
    this.setState({ requirement: { duration, course_requirements } });
  }

  updateAssessmentTitle = (value) => {
    this.setState({ assessmentTitle: value })
  }

  handleSupportingTextChange = (value, index, isCorrect = false) => {
    const quizData = [...this.state.quizData];
    if (isCorrect) {
      quizData[index].correct_feedback = value
    } else {
      quizData[index].incorrect_feedback = value
    } 
    this.setState({ quizData });
  };

  handleOptionTextChange = (value, questionIndex, optionIndex) => {
    const quizData = [...this.state.quizData];
    quizData[questionIndex].answer_options_attributes[optionIndex].description =
      value.replace('\n','');
    this.setState({ quizData });
  };

  handleOptionCorrectChange = (questionIndex, optionIndex) => {
    const quizData = [...this.state.quizData];
    const question = quizData[questionIndex];
    if (!this.isMultiSelect(question.question_type)) {
      question.answer_options_attributes = question.answer_options_attributes.map((opt, idx) => ({
        ...opt,
        is_correct: idx === optionIndex
      }));
      quizData[questionIndex] = question;
    } else {
      quizData[questionIndex].answer_options_attributes[optionIndex].is_correct =
      !quizData[questionIndex].answer_options_attributes[optionIndex]
        .is_correct;
    }
    this.setState({ quizData });
  };

  handleAddNewQuestion = () => {
    const newQuestion = {
      question_type: 'multiple_correct_options',
      description: '',
      total_marks: '25',
      correct_feedback: "This is the text that will appear after the submission of answer.",
      incorrect_feedback: "This is the text that will appear after the submission of answer.",
      answer_options_attributes: [
        { description: '', is_correct: false },
        { description: '', is_correct: false },
        { description: '', is_correct: false },
        { description: '', is_correct: false },
      ],
    };
    this.setState((prevState) => ({
      quizData: [...(prevState.quizData || []), newQuestion]
    }));
  };

  handleShowQuizEditor = () => {
    this.setState({
      isQuizEditorVisibile: true
    })
  }

  handleCloseQuizEditor = () => {
    this.setState({
      isQuizEditorVisibile: false
    })
  }

  onChangeLessonTitle = (event) => {
    let lessonList = [...this.state.lessonList];
    lessonList = lessonList.map((item) => (
      item.id === this.state.currentLessonId
        ? { ...item, lessonTitle: { ...item.lessonTitle, title: event.target.value }}
        : item
    ));
    this.setState({ lessonList, lessonTitle: event.target.value });
  }

  onChangeAuthorName = (event) => {
    this.setState({
      authorName: event.target.value,
      lessonAuthorName: event.target.value,
    });
  }

  onChangeValue = (key: string, value: string) => {
    this.setState({ currentObj: { ...this.state.currentObj, [key]: value } });
  }

  onChangeHeading = (value: string) => {
    this.setState({ currentObj: { ...this.state.currentObj, heading: value } });
  }

  handleUpDownClick = (index: number) => {
    const courseData = [...this.state.courseData];
    const isButtonTypeVisible = courseData.findIndex(item=>item.type === 'Button') !== -1;
    if(isButtonTypeVisible && index === courseData.length-1){
      return
    }
    [courseData[index],courseData[index-1]] = [courseData[index-1],courseData[index]];
    this.setState({ courseData });
  }

  onPreview = () => {
    this.setState({
      buttonType: "",
      currentObj: {},
      isQuizVisible: false,
      isToolbarVisible: false,
      isPreview: !this.state.isPreview,
    });
    if(this.state.isLessonVisible) {
      this.setState({
        isCourseLearning: true,
        isLessonVisible: false,
        selectedId: this.state.selectedCourseIndex,
        selectedCourseId: this.state.selectedCourseIndex
      });
    }
  }

  onPlusClick = (index: number) => {
    this.setState({
      isSidebarOpen: true,
      currentIndex: index
    });
  }

  onChangeListValue = (key: string, value: string) => {
    let { content } = this.state.currentObj;
    if (key === "listType") {
      if (value === "ordered") {
        content = content
          .replace('<ul>', '<ol>')
          .replace('</ul>', '</ol>');
      } else {
        content = content
          .replace('<ol>', '<ul>')
          .replace('</ol>', '</ul>');
      }
      this.setState({
        currentObj: {
          ...this.state.currentObj,
          [key]: value,
          content: content,
        }
      });
    } else {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          [key]: value,
        }
      }));
    }
  }

  handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      this.onImportFromLink();
    }
  }

  onChangeUrl = (url: string) => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        mediaUrl: url,
        urlError: ""
      }
    }));
  }

  onRemoveImage = () => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        mediaUrl: "",
        fileError: "",
        fileName: "",
        urlError: "",
        image: null,
        isMediaUrl: false,
      }
    }));
  }

  checkImageResolution = (img: React.HTMLImageElement, callback: (isHighResolution: boolean) => void): void => {
    const { type } = this.state.currentObj;
    const isQuote = type === "Quote";
    const isHighResolution = isQuote
      ? img.width >= 30 && img.height >= 30
      : img.width >= 1000 && img.height >= 300;
    callback(isHighResolution);
  }

  doesImageExist = (url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        this.checkImageResolution(img, (isHighResolution) => {
          this.setState(prevState => ({
            currentObj: {
              ...prevState.currentObj,
              fileError: "",
              fileName: "",
              image: isHighResolution ? url : prevState.currentObj.image,
              isMediaUrl: isHighResolution ? true : prevState.currentObj.isMediaUrl,
              urlError: isHighResolution ? "" : "Image resolution is too low",
            }
          }));
         return resolve(true);
        });
      };
      img.onerror = () => {
        this.setState(prevState => ({
          currentObj: {
            ...prevState.currentObj,
            fileError: "",
            urlError: "Invalid URL or image not fetched"
          }
        }));
        return reject(false);
      };
    });
  }

  onImportFromLink = async () => {
    const url = this.state.currentObj.mediaUrl;
    try {
      await this.doesImageExist(url);
    } catch (err) {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          fileError: "",
          urlError: "Image not fetched"
        }
      }));
    }
  }

  onCaptureMedia = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png'];

    if (!file) return;

    if (supportedFormats.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          this.checkImageResolution(img, (isHighResolution) => {
            if (isHighResolution) {
              this.setState(prevState => ({
                currentObj: {
                  ...prevState.currentObj,
                  mediaUrl: "",
                  fileError: "",
                  urlError: "",
                  isMediaUrl: false,
                  fileName: file.name,
                  [key]: reader.result as string
                }
              }));
            } else {
              this.setState(prevState => ({
                currentObj: {
                  ...prevState.currentObj,
                  urlError: "",
                  fileError: "Image resolution is too low"
                }
              }));
            }
          });
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          urlError: "",
          fileError: "Only .png, .jpg, .jpeg allowed"
        }
      }));
    }
  }

  handleKeyDownForVideo = (key: string) => {
    if (key === 'Enter') {
      this.onImportVideoFromLink();
    }
  }

  onImportVideoFromLink = () => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        fileError: "",
        fileName: "",
        urlError: "",
        video: this.state.currentObj.mediaUrl,
        isMediaUrl: true,
      }
    }));
  }

  onCaptureVideo = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const supportedVideoFormats = ['video/mp4', 'video/webm', 'video/ogg', 'video/x-msvideo', 'video/avi'];

    if (!file) return;

    if (supportedVideoFormats.includes(file.type)) {
      const maxFileSize = 25 * 1024 * 1024;
      if (file.size > maxFileSize) {
        this.setState(prevState => ({
          currentObj: {
            ...prevState.currentObj,
            urlError: "",
            fileError: "Video size exceeds 25 MB",
          }
        }));
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        const video = document.createElement('video');
        video.onloadedmetadata = () => {
          this.setState(prevState => ({
            currentObj: {
              ...prevState.currentObj,
              mediaUrl: "",
              fileError: "",
              urlError: "",
              isMediaUrl: false,
              fileName: file.name,
              [key]: reader.result as string
            }
          }));
        }
        video.onerror = () => {
          this.setState(prevState => ({
            currentObj: {
              ...prevState.currentObj,
              urlError: "",
              fileError: "Invalid video file"
            }
          }));
        };
        video.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    } else {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          urlError: "",
          fileError: "Only .mp4, .webm, .ogg, and .avi formats are allowed"
        }
      }));
    }
  }

  onChangeCarouselHeading = (index: number, key: string, value: string) => {
    this.setState(prevState => {
      const updatedContent = prevState.currentObj.content.map((item, idx) =>
        idx === index ? { ...item, [key]: value } : item
      );
      return {
        currentObj: {
          ...prevState.currentObj,
          content: updatedContent
        }
      };
    });
  }

  onAddCarouselSides = () => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        content: [...prevState.currentObj.content, defaultCarouselContent]
      }
    }));
  }

  processCarouselImageFile = (file: File, key: string, updateContent: Function) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        this.checkImageResolution(img, (isHighResolution) => {
          if (isHighResolution) {
            updateContent({
              mediaUrl: "",
              fileError: "",
              urlError: "",
              type: "image",
              isMediaUrl: false,
              fileName: file.name,
              [key]: reader.result as string
            });
          } else {
            const newContent = [...this.state.currentObj.content];
            updateContent({
              urlError: "",
              fileError: "Image resolution is too low"
            })
          }
        });
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  processCarouselVideoFile = (file: File, key: string, updateContent: Function) => {
    const maxFileSize = 25 * 1024 * 1024;
    if (file.size > maxFileSize) {
      updateContent({
        urlError: "",
        fileError: "Video size exceeds 25 MB",
      })
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const video = document.createElement('video');
      video.onloadedmetadata = () => {
        updateContent({
          mediaUrl: "",
          fileError: "",
          urlError: "",
          isMediaUrl: false,
          type: "video",
          fileName: file.name,
          [key]: reader.result as string
        });
      }
      video.onerror = () => {
        updateContent({
          urlError: "",
          fileError: "Invalid video file"
        });
      };
      video.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  }

  onCaptureCarouselMedia = (key: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const imageFormats = ['image/jpg', 'image/jpeg', 'image/png'];
    const videoFormats = ['video/mp4', 'video/webm', 'video/ogg', 'video/x-msvideo', 'video/avi'];

    if (!file) return;

    const updateContent = (updatedFields: {}) => {
      this.setState(prevState => {
        const newContent = [...prevState.currentObj.content];
        newContent[index] = { ...newContent[index], ...updatedFields };
        return {
          currentObj: {
            ...prevState.currentObj,
            content: newContent,
          }
        };
      });
    }

    if (imageFormats.includes(file.type)) {
      this.processCarouselImageFile(file, key, updateContent);
    } else if (videoFormats.includes(file.type)) {
      this.processCarouselVideoFile(file, key, updateContent);
    } else {
      updateContent({
        urlError: "",
        fileError: "Only .png, .jpg, .jpeg, .mp4, .webm, .avi allowed"
      });
    }
  }

  handleKeyDownForCarousel = (key: number, index: number) => {
    if (key === 'Enter') {
      this.onImportMediaFromLink(index);
    }
  }

  doesMediaImageExist = (updateContentForCarousel: Function, url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        this.checkImageResolution(img, (isHighResolution) => {
          if (isHighResolution) {
            updateContentForCarousel({
              fileName: "",
              type: "image",
              media: url,
              fileError: "",
              urlError: "",
              isMediaUrl: true,
            });
          } else {
            updateContentForCarousel({
              fileError: "",
              urlError: "Image resolution is too low",
            })
          }
          return resolve(true);
        });
      }
      img.onerror = () => {
        return reject(false);
      };
    });
  }

  onImportMediaFromLink = async (index: number) => {
    const url = this.state.currentObj.content[index].mediaUrl;
    const updateContentForCarousel = (updatedCarouselFields: {}) => {
      this.setState(prevState => {
        const newCarouselContent = [...prevState.currentObj.content];
        newCarouselContent[index] = { ...newCarouselContent[index], ...updatedCarouselFields };
        return {
          currentObj: {
            ...prevState.currentObj,
            content: newCarouselContent
          }
        };
      });
    }

    try {
      await this.doesMediaImageExist(updateContentForCarousel, url);
    } catch (err) {
      updateContentForCarousel({
        fileError: "",
        fileName: "",
        urlError: "",
        media: url,
        isMediaUrl: true,
        type: "video"
      });
    }
  }

  scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight
    });
  };

  componentDidMount() {
    const id = this.props.navigation.getParam("id");
    this.setState({ isInitialLoad: false });
    this.handleAddNewQuestion();
    if(id) {
      this.getCourseInfo(id);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.lessonList.length != this.state.lessonList.length) {
      if (this.state.lessonList.length === 0) {
        this.setState({ ...this.state, lessonCreationBar: { title: "", isActive: true } })
        this.scrollToBottom()
      }
    }

    if (!this.state.isInitialLoad && !prevState.lessonCreationBar.isActive && this.state.lessonCreationBar.isActive) {
      this.scrollToBottom();
    }
  }

  addNewLesson = (title: string) => {
    const generatedId = crypto.randomBytes(16).toString('hex');
    if (this.state.lessonList.some(lesson => lesson.lessonTitle?.title === title)) {
      return;
    }

    this.setState({
      lessonCreationBar: { title: "", isActive: false },
      lessonList: [...this.state.lessonList, { lessonTitle: { title, isActiveToChange: false }, id: generatedId, courseData: [], quizData: [] }],
    });

    this.scrollToBottom()
  };

  deleteLesson = (id: number) => {
    this.setState({ lessonList: [...this.state.lessonList.filter((lesson) => lesson.id != id)] })
  }

  handlePlusClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ plusAnchorEl: event.currentTarget });
  }

  handlePlusClose = () => {
    this.setState({ plusAnchorEl: null });
  }

  setCreationBarActive = () => {
    this.setState({ plusAnchorEl: null, lessonCreationBar: { ...this.state.lessonCreationBar, isActive: true } });
  }

  onHandleEnterPress = (event, callback) => {
    if (event.key === 'Enter') {
      callback();
    }
  }

  onToggleLessonTypeMenu = (event: React.FormEvent<HTMLInputElement>) => {
    const { clientX: posX, clientY: posY } = event;
    if (this.lessonTypeMenuRef.current) {
      this.lessonTypeMenuRef.current.openMenu(posX, posY);
    }
  }

  onHandleSetLessonTitleActive = (lessonId, value: boolean) => {
    this.setState(prevState => ({
      lessonList: prevState.lessonList.map(lessonItem =>
        lessonItem.id === lessonId
          ? {
            ...lessonItem,
            lessonTitle: {
              ...lessonItem.lessonTitle,
              isActiveToChange: value
            }
          }
          : lessonItem
      )
    }));
  };

  onHandleLessonTitleChange = (event, lessonId) => {
    this.setState(prevState => ({
      lessonList: prevState.lessonList.map(item =>
        item.id === lessonId
          ? {
            ...item,
            lessonTitle: {
              ...item.lessonTitle,
              title: event.target.value
            }
          }
          : item
      )
    }));
  }

  onHandleCourseTitleChange = (courseTitle: string) => {
    if (courseTitle === "<p></p>\n") {
      this.setState({ courseTitle: "" });
    } else {
      this.setState({ courseTitle });
    }
  }

  onHandleAuthorNameTitleChange = (event) => {
    this.setState({ authorName: event.target.value });
  }

  setLessonListType = (type: LessonListType) => {
    this.setState({ lessonListType: type });
    if (this.lessonTypeMenuRef.current) {
      this.lessonTypeMenuRef.current.closeMenu();
    }
  }

  onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }

    const reorderedList = Array.from(this.state.lessonList);
    const [removed] = reorderedList.splice(source.index, 1);
    reorderedList.splice(destination.index, 0, removed);
    this.setState({ lessonList: reorderedList });
  };

  onOpenLessonPopupMenu = (event: React.FormEvent<HTMLInputElement>, lessonId: number) => {
    const targetElement = event.currentTarget;
    const { x, y, height } = targetElement.getBoundingClientRect();
    if (this.lessonPopupMenuRefs[lessonId] && this.lessonPopupMenuRefs[lessonId].current) {
      this.lessonPopupMenuRefs[lessonId].current.openMenu(x - 180, y + height + 8)
      this.setState(prevState => ({
        lessonList: prevState.lessonList.map(item => ({
          ...item,
          isPopUpActive: item.id === lessonId
        }))
      }));
    }
  };

  onCloseLessonPopupMenu = () => {
    this.setState({
      lessonList: this.state.lessonList.map(item => ({
        ...item,
        isPopUpActive: false
      }))
    });
  }

  onChangeCourseDescription = (value: string) => {
    this.setState({ description: value });
  }

  onEditorSave = (result: string) => {
    this.setState({description: result});
    if (this.editorMenuRef.current) {
      this.editorMenuRef.current.closeMenu();
    }
  }
  handleImageClick = (e,index:number) => {
    const { courseData } = this.state;
    const containerBounds = this.containerRef.current.getBoundingClientRect();

    const x = e.clientX - containerBounds.left;
    const y = e.clientY - containerBounds.top;

    const newButton = {
      title:`<p style="font-family:'Crimson Text';font-size:16px;font-weight:bold;">Heading of Card</p>`,
        description: `<p style="font-family:'Crimson Text';font-size:14px">This is the subtext that you can edit in the editor</p>`,
        visibleContent:false,
      x: Math.max(0, Math.min(x, containerBounds.width)),
      y: Math.max(0, Math.min(y, containerBounds.height)),
      contentRightSide:true,
      id:`button-${Date.now()}`
    };

    const updatedCourseData = [...courseData];
    const labelGraphicData = { ...updatedCourseData[index] };

    if (labelGraphicData.content.length < 6) {
      labelGraphicData.content = [...labelGraphicData.content, newButton];
      updatedCourseData[index] = labelGraphicData;

      this.setState({ courseData: updatedCourseData });
    }
  };

  handleDragStart = (e, buttonIndex, parentIndex) => {
    const { courseData } = this.state;
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    
    const labelGraphicData = courseData[parentIndex];
    const button = labelGraphicData.content[buttonIndex];
    
    const offsetX = e.clientX - containerBounds.left - button.x;
    const offsetY = e.clientY - containerBounds.top - button.y;
    
    this.setState({
      draggingIndex: buttonIndex,
      offset: { x: offsetX, y: offsetY }
    });
  };

  handleDrag = (e, index:number) => {
    const { draggingIndex, courseData, offset } = this.state;
    const updatedCourseData = [...courseData];
    const labelGraphicData = { ...updatedCourseData[index] };
  
    if (draggingIndex === null) return;

    // Calculate new position during drag
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    const x = e.clientX - containerBounds.left - offset.x;
    const y = e.clientY - containerBounds.top - offset.y;
    const buttonRef = document.querySelector(`[data-test-id="draggable-btn-${draggingIndex}"]`);
    const buttonBounds = buttonRef ? buttonRef.getBoundingClientRect() : { width: 0, height: 0 };
  
    // Update button position within container boundaries
    const updatedButtons = [...labelGraphicData.content];
    updatedButtons[draggingIndex] = {
      ...updatedButtons[draggingIndex],
      x: Math.max(0, Math.min(x, containerBounds.width - buttonBounds.width)),
      y: Math.max(0, Math.min(y, containerBounds.height - buttonBounds.height)),
    };
    labelGraphicData.content = updatedButtons;
    updatedCourseData[index] = labelGraphicData;
  
    this.setState({ courseData: updatedCourseData });
  };

  handleDragEnd = () => {
    this.setState({ draggingIndex: null });
  };

  getCourseDataObj = () => {
    let courseData = this.state.courseData;
    if((this.state.isPreview && this.state.isCourseLearning) && (this.state.selectedCourseId !== this.state.selectedId)) {
      courseData = this.state.lessonList[this.state.selectedCourseId].courseData;
    }
    return courseData;
  }

  updateCourseObj = (updatedCourseData) => {
    if((this.state.isPreview && this.state.isCourseLearning) && (this.state.selectedCourseId !== this.state.selectedId)) {
      let lessonList = [...this.state.lessonList];
      lessonList[this.state.selectedCourseId].courseData = updatedCourseData;
      this.setState({ lessonList });
    } else {
      this.setState({ courseData: updatedCourseData });
    }
  }

  handleEditLabelContent = (parentIndex: number, id: string, key: string, valueUpdate: any) => {
    const courseData = this.getCourseDataObj();
    const updatedCourseData = [...courseData];
    updatedCourseData[parentIndex] = {
      ...updatedCourseData[parentIndex],
      content: updatedCourseData[parentIndex].content.map(item =>
        item.id === id ? { ...item, [key]: valueUpdate } : item
      )
    };
    this.updateCourseObj(updatedCourseData);
  }

  handleEditContent = (key:string,value:string, id: string)=>{
    const currentObj = { ...this.state.currentObj };
  
    const index = currentObj.content.findIndex(item => item.id === id);
  
    if (index !== -1) {
      const updatedContent = [...currentObj.content];
      updatedContent[index] = {
        ...updatedContent[index],
        [key]: value
      };
  
      currentObj.content = updatedContent;
      this.setState({ currentObj });
    }
  }
  deleteContentItem = (id: string) => {
    const currentObj = { ...this.state.currentObj };
    
    const updatedContent = currentObj.content.filter(item => item.id !== id);
  
    currentObj.content = updatedContent;
    this.setState({ currentObj });
  };
  handleAddNewBtn = () => {
    const { currentObj } = this.state;

    const newButton = {
      contentRightSide:true,
      id:`button-${Date.now()}`,
      title:`<p style="font-family:'Crimson Text';font-size:16px;font-weight:bold;">Heading of Card</p>`,
        description: `<p style="font-family:'Crimson Text';font-size:14px">This is the subtext that you can edit in the editor</p>`,
        visibleContent:false,
      x: 100,
      y: 100,
    };

    const updatedObj = {...currentObj};

    if (updatedObj.content.length < 6) {
      updatedObj.content = [...updatedObj.content, newButton];

      this.setState({ currentObj: updatedObj });
    }
  };

  handleFlipCard = (index:number,parentIndex:number,valueUpdate:boolean)=>{
    const courseData = this.getCourseDataObj();
    const updatedCourseData = [...courseData];
    const flashCardData = { ...updatedCourseData[parentIndex] };
    const updatedContent = [...flashCardData.cards];
    updatedContent[index] = {
      ...updatedContent[index],
      flipped: valueUpdate
    };
    flashCardData.cards = updatedContent;
    updatedCourseData[parentIndex] = flashCardData;
    this.updateCourseObj(updatedCourseData);
  }

  handleEditCardContent = (key:string,value:string, id: number)=>{
    const currentObj = { ...this.state.currentObj };
  const updatedCards = currentObj.cards.map((card) => {
    if (card.id === id) {
      return {
        ...card,
        [key]: value,
      };
    }
    return card;
  });

  this.setState({ currentObj: { ...currentObj, cards: updatedCards } });
  }
  handleDeleteCardContent = (id:string)=>{
    const currentObj = { ...this.state.currentObj };
    const updatedCards = [...currentObj.cards].filter(card=>card.id!== id);
    currentObj.cards=updatedCards

    this.setState({ currentObj });
  }
  handleAddNewCard=()=>{
    const { currentObj } = this.state;

    const newCard = {
      id:`card-${Date.now()}`,
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card</p>`,
        back:`<p style="font-family:'Crimson Text'font-size:14px">Back of Card</p>`,
        flipped:false
    };

    const updatedObj = {...currentObj};

    if (updatedObj.cards.length < 6) {
      updatedObj.cards = [...updatedObj.cards, newCard];

      this.setState({ currentObj: updatedObj });
    }
  }
  handleTouchStart = (e, buttonIndex, parentIndex) => {
    const { courseData } = this.state;
    const touch = e.touches[0];
    
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    const labelGraphicData = courseData[parentIndex];
    const button = labelGraphicData.content[buttonIndex];

    const offsetX = touch.clientX - containerBounds.left - button.x;
    const offsetY = touch.clientY - containerBounds.top - button.y;

    this.setState({
      draggingIndex: buttonIndex,
      currentParentIndex:parentIndex,
      offset: { x: offsetX, y: offsetY },
    });

    document.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('touchend', this.handleTouchEnd);
  };

handleTouchMove = (e) => {
    const { draggingIndex, courseData, offset } = this.state;
    const updatedCourseData = [...courseData];
    const labelGraphicData = { ...updatedCourseData[this.state.currentParentIndex] };

    if (draggingIndex === null) return;

    const touch = e.touches[0];
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    const x = touch.clientX - containerBounds.left - offset.x;
    const y = touch.clientY - containerBounds.top - offset.y;
    const buttonRef = document.querySelector(`[data-test-id="draggable-btn-${draggingIndex}"]`);
    const buttonBounds = buttonRef ? buttonRef.getBoundingClientRect() : { width: 0, height: 0 };
    const updatedButtons = [...labelGraphicData.content];
    updatedButtons[draggingIndex] = {
      ...updatedButtons[draggingIndex],
      x: Math.max(0, Math.min(x, containerBounds.width -  buttonBounds.width)),
      y: Math.max(0, Math.min(y, containerBounds.height - buttonBounds.height)),
    };

    labelGraphicData.content = updatedButtons;
    updatedCourseData[this.state.currentParentIndex] = labelGraphicData;

    this.setState({ courseData: updatedCourseData });
  };

handleTouchEnd = () => {
    this.setState({ draggingIndex: null });
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('touchend', this.handleTouchEnd);
  };
  addRow = (position = "after") => {
    const { selectedCellId, currentObj} = this.state;
    if (!selectedCellId) return;
  
    const rowsData = [...currentObj.table_rows_attributes];
    const selectedRowIndex = rowsData.findIndex((row) =>
      {
            return Object.values(row).some((cell) => cell?.id === selectedCellId);
        }
    );
  
    if (selectedRowIndex === -1) {
      alert("Please select a cell first!");
      return;
    }
  
    if (position === "before" && selectedRowIndex === 0) {
      alert("Cannot add a row before the first row");
      return;
    }
  
    const newId = uuidv4();
    const newRow = { id: `row-${newId}` };
    const columnKeys = Object.keys(rowsData[0]).filter((key) => key !== "id");
  
    columnKeys.forEach((columnKey) => {
      const columnId = uuidv4(); // Generate a new unique ID for each cell
      newRow[columnKey] = { id: columnId, value: "<p>New Cell</p>" };
    });
  
    const newIndex = position === "after" ? selectedRowIndex + 1 : selectedRowIndex;
  
    rowsData.splice(newIndex, 0, newRow);
  
    this.setState({
      currentObj: {
        ...currentObj,
        table_rows_attributes: rowsData,
      },
    });
  };
  
  addColumn = (position = "after") => {
    const { selectedCellId, currentObj } = this.state;
    if (!selectedCellId) return;
  
    const rowsData = [...currentObj.table_rows_attributes];
  
    let selectedColumnIndex = -1;
    const columnKeys = Object.keys(rowsData[0]).filter((key) => key !== "id"); // Exclude the row id
    for (let i = 0; i < columnKeys.length; i++) {
      const columnKey = columnKeys[i];
      if (rowsData.some((row) => row[columnKey]?.id === selectedCellId)) {
        selectedColumnIndex = i;
        break;
      }
    }
  
    if (selectedColumnIndex === -1) {
      alert("Please select a cell first!");
      return;
    }
  
    if (position === "before" && selectedColumnIndex === 0) {
      alert("Cannot add a column before the first column");
      return;
    }
  
    const newColumnIndex =
      position === "after" ? selectedColumnIndex + 1 : selectedColumnIndex;
  
    const newId = uuidv4();
    const newColumnKey = `column${newId}`;
  
    const updatedContent = rowsData.map((row) => {
      const rowArray = Object.entries(row);
  
      rowArray.splice(newColumnIndex + 1, 0, [
        newColumnKey,
        { id: uuidv4(), value: "New cell" },
      ]);
  
      return Object.fromEntries(rowArray);
    });
  
    this.setState({
      currentObj: {
        ...currentObj,
        table_rows_attributes: updatedContent,
      },
    });
  };
  handleCellSelect = (rowIndex:number, colIndex:number,cellId:string) => {
    this.setState({
      selectedRowIndex: rowIndex,
      selectedColumnIndex: colIndex,
      selectedCellId:cellId
    });
  };
  deleteColumn = () => {
    const { currentObj, selectedCellId } = this.state;
  
    if (!selectedCellId || !currentObj?.table_rows_attributes) return;
  
    // Search for the column key corresponding to the selected cell ID
    let columnToDelete = null;
    for (const row of currentObj.table_rows_attributes) {
      columnToDelete = Object.keys(row).find(
        (key) => row[key]?.id === selectedCellId
      );
      if (columnToDelete) break; 
    }
  
    if (!columnToDelete) {
      alert("Column not found for the selected cell.");
      return;
    }
  
    const firstRow = currentObj.table_rows_attributes[0];
    const totalColumns = Object.keys(firstRow).length;
  
    if (totalColumns <= 2) {
      alert("Cannot delete the last remaining column.");
      return;
    }
  
    const updatedRows = currentObj.table_rows_attributes.map((row) => {
      const updatedRow = { ...row };
      delete updatedRow[columnToDelete];
      return updatedRow;
    });
  
    this.setState({
      currentObj: {
        ...currentObj,
        table_rows_attributes: updatedRows,
      },
      selectedCellId: null,
    });
  };
  
  
  
  deleteRow = () => {
    const { selectedRowIndex, currentObj } = this.state;
  
    // Check if a row is selected
    if (selectedRowIndex === null) return;
  
    const totalRows = currentObj.table_rows_attributes.length;
  
    // Ensure there is more than one row remaining
    if (totalRows <= 1) {
      alert("Cannot delete the last remaining row.");
      return;
    }
  
    const updatedContent = { ...currentObj };
    updatedContent.table_rows_attributes.splice(selectedRowIndex, 1);
  
    this.setState({
      currentObj: updatedContent,
      selectedRowIndex: null,
    });
  };
  handleTableValueChange =   (value:string, rowIndex:number, cellId:string) => {
    const updatedContent = { ...this.state.currentObj };
  const tableData = updatedContent.table_rows_attributes;

  const updatedCell = Object.values(tableData[rowIndex]).find(item=>item.id===cellId);

  if (updatedCell) {
    updatedCell.value = value;  
  }

  // Update the state with the modified content
  this.setState({
    currentObj: updatedContent
  });
  };
  handleCorrectOptionChange = (optionIndex) => {
    this.setState((prevState) => {
      const { currentObj } = prevState;
      const question = { ...currentObj.content };

      // Reset all isCorrect flags if it's a single response question
      if (!question.isMultiple) {
        question.options = question.options.map((opt, idx) => ({
          ...opt,
          isCorrect: idx === optionIndex
        }));
      } else {
        // Toggle the isCorrect flag for multiple response question
        question.options[optionIndex].isCorrect = !question.options[optionIndex].isCorrect;
      }

      return { currentObj: { ...currentObj, content: question } };
    });
  };

  handleTextAnswerChange = (value, optionIndex) => {
    this.setState((prevState) => {
      const updatedOptions = prevState.currentObj.content.options.map((opt, idx) =>
        idx === optionIndex ? { ...opt, answer: value } : opt
      );
      return {
        currentObj: {
          ...prevState.currentObj,
          content: {
            ...prevState.currentObj.content,
            options: updatedOptions
          }
        }
      };
    });
  };
  handleTestContentChange = (key:string,value)=>{
    this.setState((prevState) => ({
      currentObj: {
        ...prevState.currentObj,
        content: {
          ...prevState.currentObj.content,
          [key]: value
        }
      }
    }));
  }
  handleChangeQuestionType = (e)=>{
    const {currentObj} = this.state
    const question = {...currentObj.content}
    const updatedQuestion = question.options.map(ques=>({...ques,isCorrect:false,isSelected:false}))
    this.setState(
      {
        currentObj: {
          ...currentObj,
          content: {
            ...currentObj.content,
            isMultiple: e?.value,
            options: updatedQuestion
          }
        }
      }
    )
  }
  handleDeleteAnswer = (id:string)=>{
    const {currentObj} = this.state
    const question = {...currentObj.content}
    const updatedQuestion = question.options.filter(ques=>ques.id !== id)
    question.options = updatedQuestion
    this.setState({currentObj:{...currentObj,content:question}})
  }
  handleAddAnswer = () => {
    this.setState((prevState) => {
      const { options } = prevState.currentObj.content;

      if (options.length >= 4) {
        return prevState; 
      }

      const newOption = { answer: `New Answer`, isCorrect: false, id:`${uuidv4()}`,isSelected:false };
      return {
        currentObj: {
          ...prevState.currentObj,
          content: {
            ...prevState.currentObj.content,
            options: [...options, newOption]
          }
        }
      };
    });
  };

  handleSelectAnswer = (index, optionId) => {
      let courseData = this.getCourseDataObj();
      const updatedCourseData = [...courseData];
      const testKnowledge = updatedCourseData[index];
  
      const updatedOptions = testKnowledge.content.options.map((option) => {
        if (testKnowledge.content.isMultiple) {
          return option.id === optionId
            ? { ...option, isSelected: !option.isSelected }
            : option;
        } else {
          return { ...option, isSelected: option.id === optionId };
        }
      });
  
      updatedCourseData[index] = {
        ...testKnowledge,
        content: {
          ...testKnowledge.content,
          options: updatedOptions,
        },
      };
      this.updateCourseObj(updatedCourseData);
  };

  submitAnswer = (id: string) => {
    const courseData = this.getCourseDataObj();
    const updatedCourseData = courseData.map(item =>
      item.id === id
        ? {
            ...item,
            content: {
              ...item.content,
              options: item.content.options.map(option => ({ ...option })),
            },
          }
        : { ...item }
    );

    const questionContent = updatedCourseData.find(item => item.id === id)?.content;
    if (!questionContent) return;
  
    const { question_type, options } = questionContent;
    const isAnswerCorrect =
      question_type === 'single_response'
        ? 
          options.some(option => option.isCorrect && option.isSelected)
        : 
          options.every(
            option => option.isCorrect === option.isSelected
          );
    questionContent.isSubmit = true;
    questionContent.answerCorrect = isAnswerCorrect;
   this.updateCourseObj(updatedCourseData);
  };

  handleChangeButtonType = (index:number, value:boolean)=>{
    const { courseData } = this.state;
    const updatedCourseData = [...courseData];
    const buttonData = updatedCourseData[index]
    buttonData.finishButton = value;
    this.setState({courseData:updatedCourseData})
  }

  getCourseInfo = async (id: string | number) => {
    this.setState({ isLoading: true });
    this.getCourseInfoApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_admin/courses/get_course?id=${id}`,
    });
  }

  handleRemainingCourseData = (course: {}, index: number) => {
    if (course.type === "Label") {
      return {
        "block_type": "labeled_graphic",
        "content_block_position": index + 1,
        "padding_top": course.paddingTop,
        "padding_bottom": course.paddingBottom,
        "width": course.width,
        "image": course.isMediaUrl ? undefined : course.image,
        "image_url": course.isMediaUrl ? course.image : undefined,
        "labels_attributes": course.content.map((item) => ({
          "x_position": item.x,
          "y_position": item.y,
          "title": item.title,
          "description": item.description,
          "visible_content": item.visibleContent
        }))
      }
    } else if (course.type === "Test") {
      return {
        "block_type": "test_knowledge",
        "content_block_position": index + 1,
        "padding_top": course.paddingTop,
        "padding_bottom": course.paddingBottom,
        "width": course.width,
        "test_questions_attributes": [{
          "question_text": course.content.question,
          "question_type": course.content.isMultiple ? "multiple_response" : "single_response",
          "correct_feedback": course.content.supportingText,
          "incorrect_feedback": course.content.supportingTextInCorrect,
          "options_attributes": course.content.options.map((obj) => ({
            "text": obj.answer,
            "is_correct": obj.isCorrect
          }))
        }]
      }
    } else if (course.type === "Button") {
      return {
        "block_type": "button",
        "content_block_position": index + 1,
        "padding_top": course.paddingTop,
        "padding_bottom": course.paddingBottom,
        "width": course.width,
        "is_finished": course.finishButton,
      }
    }
  }

  handleCourseData = (course: {}, index: number) => {
    if (course.type === "Carousel") {
      return {
        "block_type": "carousel",
        "content_block_position": index + 1,
        "padding_top": course.paddingTop,
        "padding_bottom": course.paddingBottom,
        "width": course.width,
        "slides_attributes": course.content.map((item) => ({
          "title": item.heading,
          "description": item.description,
          "media_type": item.type,
          "media": item.isMediaUrl ? undefined : item.media,
          "media_url": item.isMediaUrl ? item.media : undefined,
        }))
      }
    } else if (course.type === "Quote") {
      return {
        "block_type": "quote",
        "content_block_position": index + 1,
        "padding_top": course.paddingTop,
        "padding_bottom": course.paddingBottom,
        "width": course.width,
        "content": course.content,
        "quote_owner_name": course.name,
        "image": course.image,
      }
    } else {
      return this.handleRemainingCourseData(course, index);
    }
  }

  getRowsData = (rows: { [key]: string }[]) => {
    return rows.map((row) => {
      const convertedRow = {};
      Object.keys(row).forEach((key,index) => {
        if (key.startsWith("column")) {
          convertedRow[`column${index}`] = row[key].value;
        }
      });
      return convertedRow;
    });
  };

  getCourseData = (courseData: {}) => {
    return courseData.map((course, index: number) => {
      if (course.type === "Text") {
        return {
          "block_type": "text",
          "content_block_position": index + 1,
          "padding_top": course.paddingTop,
          "padding_bottom": course.paddingBottom,
          "width": course.width,
          "heading": course.heading,
          "content": course.content,
        }
      } else if (course.type === "List") {
        return {
          "block_type": "list",
          "content_block_position": index + 1,
          "padding_top": course.paddingTop,
          "padding_bottom": course.paddingBottom,
          "list_type": course.listType,
          "width": course.width,
          "heading": course.heading,
          "content": course.content,
        }
      } else if (course.type === "Image") {
        return {
          "block_type": "image",
          "content_block_position": index + 1,
          "padding_top": course.paddingTop,
          "padding_bottom": course.paddingBottom,
          "width": course.width,
          "caption": course.caption,
          "image": course.isMediaUrl ? undefined : course.image,
          "image_url": course.isMediaUrl ? course.image : undefined,
        }
      } else if (course.type === "Video") {
        return {
          "block_type": "video",
          "content_block_position": index + 1,
          "padding_top": course.paddingTop,
          "padding_bottom": course.paddingBottom,
          "width": course.width,
          "caption": course.caption,
          "video": course.isMediaUrl ? undefined : course.video,
          "video_url": course.isMediaUrl ? course.video : undefined,
        }
      } else if (course.type === "Table") {
        return {
          "block_type": "tabular",
          "content_block_position": index + 1,
          "padding_top": course.paddingTop,
          "padding_bottom": course.paddingBottom,
          "width": course.width,
          "table_rows_attributes": this.getRowsData(course.table_rows_attributes)
        }
      } else if (course.type === "Card") {
        return {
          "block_type": "flashcard",
          "content_block_position": index + 1,
          "padding_top": course.paddingTop,
          "padding_bottom": course.paddingBottom,
          "width": course.width,
          "flashcards_attributes": course.cards.map((item) => ({ "front_side": item.front, "back_side": item.back }))
        }
      } else {
        return this.handleCourseData(course, index);
      }
    });
  }

  onPublish = async (packageIds: string[]) => {
    const errors = [];
    if (!this.state.courseTitle) {
      errors.push("Course title can't be blank!");
    }
    if (!this.state.authorName) {
      errors.push("Author name can't be blank!");
    }
    if (!this.state.coverImg.image && !this.state.coverDescImg.image && !this.state.imageObj.image) {
      errors.push("Course picture can't be blank!");
    }
    if (!this.state.requirement.duration) {
      errors.push("Total hours can't be blank!");
    }
    if (!this.state.requirement.course_requirements) {
      errors.push("Course requirement can't be blank!");
    }
    if (packageIds.length === 0) {
      errors.push("Learning paths must include at least one learning path");
    }
    if (errors.length !== 0) {
      this.setState({ isErrorVisible: true, errors });
      return;
    }
    this.setState({ isLoading: true }, () => {
      this.onPublishApi(packageIds);
    });
  }

  onPublishApi = async (packageIds: string[]) => {
    const lessonArray = this.state.lessonList.map((item: LessonItem, index: number) => ({
      "title": item.lessonTitle.title,
      "sections_attributes": [{
        "content_blocks_attributes": this.getCourseData(item.courseData)
      }]
    }));
    const body = {
      "data": {
        "type": "courses",
        "attributes": {
          "title": this.state.courseTitle,
          "description": this.state.description,
          "author_name": this.state.authorName,
          "thumbnail_image": this.state.imageObj.image || undefined,
          "course_cover_image": this.state.coverImg.image || undefined,
          "course_description_image": this.state.coverDescImg.image || undefined,
          "learning_path_ids": packageIds,
          "duration": this.state.requirement.duration,
          "course_requirements": this.state.requirement.course_requirements,
          "lesson_format": this.state.lessonListType,
          "course_modules_attributes": lessonArray
        }
      }
    }
    this.publishApiCallId = await apiCall({
      method: "POST",
      body: body,
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_admin/courses`,
    });
  }
  // Customizable Area End
}
