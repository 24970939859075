import React from 'react';

// Customizable Area Start
import {
  Modal,
  Select as MuiSelect,
  MenuItem,
  Menu,
  Container,
  Box,
  Button,
  Input,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  Typography,
  InputLabel,
  List,
  Pagination,
  TableFooter,
  TextField,
  styled,
  InputAdornment, CircularProgress,
  Popover, InputBase,Accordion, AccordionDetails,AccordionSummary,
  Dialog, DialogTitle, DialogContent, DialogActions, Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import { Formik } from "formik";
import * as Yup from "yup";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { DeselectIcon, courseImg } from './assets';
import { ExpandMore, ExpandLess, TimerOutlined, Close, Search } from '@mui/icons-material';
import { Account, Group, GroupAccount } from './types';
import FilterListIcon from '@mui/icons-material/FilterList';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Sidebar from "./../../../components/src/sidebar/Sidebar.web";
import Loader from "../../../components/src/Loader.web";
import HtmlReactParser from 'html-react-parser';
const baseURL = require("../../../framework/src/config.js").baseURL;
const theme = createTheme({
  palette: {
    primary: {
      main: '#0000ff',
      contrastText: '#fff',
    },
  },
});
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from './AccountGroupsController';

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHTML = (data: any) => {
    return data.includes('<p') ? HtmlReactParser(data.replaceAll('<p>', '').replaceAll('</p>', '')) : data;
  }

  createClientSchema = () => {
    return Yup.object().shape({
      companyLogo: Yup.string().required("*Company logo is required"),
      companyName: Yup.string().required("*Company name is required"),
      contactName: Yup.string().required("*Contact name is required"),
      jobTitle: Yup.string().required("*Job title is required"),
      email: Yup.string().required("*Email is required").email("*Enter valid email"),
      contactPhone: Yup.string().required("*Contact phone is required"),
    });
  }

  renderAssignCoursesDialog = () => {
    const assignList = [
      { label: "Learning Paths", value: "Learning Paths" },
      { label: "Packages", value: "Packages" },
    ];
    return (
      <CustomDialog
        scroll="paper"
        data-test-id="dialog"
        open={this.state.openDialog === "assign"}
        onClose={this.onCloseDialog}
      >
        <ToastContainer
          autoClose={3000}
          position="top-right"
          className="toast-container"
        />
        <DialogTitle className="dialog-title">
          <Typography className="heading">Assign Courses</Typography>
          <Close className="close-icon" data-test-id="close-btn" onClick={this.onCloseDialog} />
        </DialogTitle>
        <DialogContent className="course-content">
          <Grid container>
            <Grid item xs={12}>
              <Box padding="10px 20px 8px">
                <Typography className="label-title">Assign by</Typography>
                <Select
                  data-test-id="list-type"
                  isSearchable={false}
                  options={assignList}
                  placeholder="Select option"
                  value={assignList.filter((item) => item.value === this.state.assignBy)[0]}
                  onChange={(e) => this.handleAssignByValue(e?.value)}
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: '999',
                    })
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {this.state.loading &&
            <Box className="loader-wrapper">
              <CircularProgress className='loader' />
            </Box>
          }
          {!this.state.loading && this.state.assignBy === "Packages" &&
            <Box padding="0px 20px 25px" className="package-wrapper">
              <Grid container>
                {this.state.packagesList.map((packages: any) => (
                  <Grid item xs={12} sm={6} className='package-card'>
                    <Box key={packages.id} className="packages">
                      <div>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                          {packages.attributes.name}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
                          • {packages.attributes.courses?.length || 0} {packages.attributes.courses?.length > 1 ? 'Courses' : 'Course'}
                        </Typography>
                      </div>
                      <PackageCheckbox
                        data-test-id="package-checkbox"
                        checked={this.state.selectedPackages.find((packageObj) => packageObj.id === packages.id)}
                        onChange={() => this.selectPackage(packages.id, packages.attributes.courses)}
                        icon={<Box className="icons"><div /></Box>}
                        checkedIcon={
                          <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="20" height="20" rx="4" fill="#1C1C1C" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M15.0611 5.42238C15.5183 5.73229 15.6376 6.35411 15.3277 6.81124L10.2432 14.3112C10.0771 14.5562 9.8111 14.715 9.51671 14.745C9.22232 14.7749 8.92977 14.673 8.71777 14.4665L4.80234 10.6536C4.40667 10.2683 4.39827 9.6352 4.78358 9.23953C5.16888 8.84386 5.80199 8.83546 6.19766 9.22077L9.25771 12.2007L13.6723 5.68895C13.9822 5.23182 14.604 5.11247 15.0611 5.42238Z" fill="white" />
                          </svg>
                        }
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          }
          {!this.state.loading && this.state.assignBy === "Learning Paths" &&
            <Box paddingTop="18px">
              {this.state.learningPathList.map((learningPath, index: number) => (
                <StyledAccordion key={`${index + 1}`}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`panel-${index}-content`}
                    id={`panel-${index}-header`}
                    className='summary'
                  >
                    <Typography className='title'>
                      {learningPath.attributes.title}
                    </Typography>
                    <LearningPathCheckbox
                        data-test-id="learning-path-checkbox"
                        checked={
                          this.state.selectedLearningPaths.some((path) =>
                            path.learningPathId === learningPath.id && path.selectedCourses.length === learningPath.attributes.courses.length
                          )
                        }
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => {
                          this.selectLearningPathValue(learningPath.id, learningPath.attributes.courses);
                        }}
                        icon={<Box className="icons"><div /></Box>}
                        checkedIcon={
                          <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="20" height="20" rx="4" fill="#1C1C1C" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M15.0611 5.42238C15.5183 5.73229 15.6376 6.35411 15.3277 6.81124L10.2432 14.3112C10.0771 14.5562 9.8111 14.715 9.51671 14.745C9.22232 14.7749 8.92977 14.673 8.71777 14.4665L4.80234 10.6536C4.40667 10.2683 4.39827 9.6352 4.78358 9.23953C5.16888 8.84386 5.80199 8.83546 6.19766 9.22077L9.25771 12.2007L13.6723 5.68895C13.9822 5.23182 14.604 5.11247 15.0611 5.42238Z" fill="white" />
                          </svg>
                        }
                      />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" className='learning-path-description'>
                      {learningPath.attributes.description}
                    </Typography>
                    <Grid container gap={0}>
                      {learningPath.attributes.courses.map((course: any) => {
                        const image = course.images?.url || courseImg;
                        return (
                          <Grid item xs={12} sm={6} md={4} key={course.id} className='course-wrapper'>
                            <Box className="courseCard">
                              <BoxThumbnail sx={{ position: 'relative' }}>
                                <Box
                                  className="image"
                                  sx={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${image})` }}
                                >
                                  <Typography className="hours">
                                    <TimerOutlined className="time-icon" />
                                    {course.duration} Hours
                                  </Typography>
                                </Box>
                                <Typography className="published">Published</Typography>
                                <LearningPathCheckbox
                                  className='course-checkbox'
                                  data-test-id={`course-check-${course.id}`}
                                  checked={
                                    this.state.selectedLearningPaths.some((path) =>
                                      path.learningPathId === learningPath.id && path.selectedCourses.includes(course.id)
                                    )
                                  }
                                  onChange={() => this.selectSingleCourse(learningPath.id, course.id)}
                                  icon={<Box className="icons"><div /></Box>}
                                  checkedIcon={
                                    <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect width="20" height="20" rx="4" fill="#1C1C1C" />
                                      <path fillRule="evenodd" clipRule="evenodd" d="M15.0611 5.42238C15.5183 5.73229 15.6376 6.35411 15.3277 6.81124L10.2432 14.3112C10.0771 14.5562 9.8111 14.715 9.51671 14.745C9.22232 14.7749 8.92977 14.673 8.71777 14.4665L4.80234 10.6536C4.40667 10.2683 4.39827 9.6352 4.78358 9.23953C5.16888 8.84386 5.80199 8.83546 6.19766 9.22077L9.25771 12.2007L13.6723 5.68895C13.9822 5.23182 14.604 5.11247 15.0611 5.42238Z" fill="white" />
                                    </svg>
                                  }
                                />
                              </BoxThumbnail>
                              <Box className="courseCardDescription">
                                <Box className="course-title">{this.renderHTML(course.title)}</Box>
                                <Box className="course-lesson">{course.lessons} {course.lessons > 1 ? 'Lessons' : 'Lesson'}</Box>
                                <Box className="description">
                                  <b style={{ color: '#1A1A1A' }}>You will learn:</b>
                                  <div className='course-description' dangerouslySetInnerHTML={{ __html: course.description }} />
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        )
                      }
                      )}
                    </Grid>
                  </AccordionDetails>
                </StyledAccordion>
              ))}
            </Box>
          }
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton data-test-id="cancel-btn" onClick={this.onCloseDialog}>
            Cancel
          </CancelButton>
          <DoneButton data-test-id="assign-course" onClick={this.assignCourses}>
            Assign Course
          </DoneButton>
        </DialogActions>
      </CustomDialog>
    )
  }

  renderCreateClientDialog = () => {
    return (
      <CustomDialog
        data-test-id="dialog"
        open={this.state.openDialog === "create"}
        scroll="body"
        onClose={this.onCloseDialog}
      >
        <Loader loading={this.state.loading} />
        <DialogTitle className="dialog-title">
          <Typography className="heading">Create New Client</Typography>
          <Close className="close-icon" data-test-id="close-btn" onClick={this.onCloseDialog} />
        </DialogTitle>
        <Formik
          data-test-id="formik"
          initialValues={{
            companyLogo: "",
            companyName: "",
            contactName: "",
            jobTitle: "",
            email: "",
            contactPhone: ""
          }}
          enableReinitialize
          validationSchema={this.createClientSchema}
          onSubmit={(values) => {
            this.createClient(values);
          }}
        >
          {({ errors, touched, values, setFieldValue, handleSubmit }) => (
            <form
              noValidate
              className="form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <DialogContent className="content">
                <Grid container columnSpacing={1} rowGap={1}>
                  <Grid item xs={12} className='upload-logo'>
                    <Typography className="label-title">Company Logo</Typography>
                    <input
                      type="file"
                      ref={this.fileInputRef}
                      accept=".png, .jpg, .jpeg"
                      style={{ display: "none" }}
                      id="client-logo"
                      data-test-id="logo-image"
                      onChange={(e) => {
                        this.handleLogo(e.target.files?.[0]);
                        setFieldValue("companyLogo", e.target.files?.[0]);
                      }}
                    />
                    {values.companyLogo &&
                      <Box className="uploaded">
                        <img src={this.state.companyLogo} className='logo' />
                        <Box className="button-wrapper">
                          <label htmlFor="client-logo">
                            <Box className='reupload-btn'>Reupload</Box>
                          </label>
                          <Box
                            className='delete-btn'
                            data-test-id="delete-btn"
                            onClick={() => {
                              this.deleteLogo();
                              setFieldValue("companyLogo", "");
                            }}
                            >
                              Delete
                          </Box>
                        </Box>
                      </Box>
                    }
                    {!values.companyLogo &&
                      <label htmlFor="client-logo">
                        <Box
                          data-test-id="drag-and-drop"
                          onDragOver={this.handleDragOver}
                          onDrop={(e) => {
                            setFieldValue("companyLogo", "file");
                            this.handleDrop(e);
                          }}
                          className="logo-wrapper"
                        >
                          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" rx="8" fill="#F8FAFC" />
                            <path d="M18.5 32C16.9833 32 15.6877 31.475 14.613 30.425C13.5377 29.375 13 28.0917 13 26.575C13 25.275 13.3917 24.1167 14.175 23.1C14.9583 22.0833 15.9833 21.4333 17.25 21.15C17.6667 19.6167 18.5 18.375 19.75 17.425C21 16.475 22.4167 16 24 16C25.95 16 27.604 16.679 28.962 18.037C30.3207 19.3957 31 21.05 31 23C32.15 23.1333 33.1043 23.629 33.863 24.487C34.621 25.3457 35 26.35 35 27.5C35 28.75 34.5627 29.8127 33.688 30.688C32.8127 31.5627 31.75 32 30.5 32H25C24.45 32 23.9793 31.8043 23.588 31.413C23.196 31.021 23 30.55 23 30V24.85L21.4 26.4L20 25L24 21L28 25L26.6 26.4L25 24.85V30H30.5C31.2 30 31.7917 29.7583 32.275 29.275C32.7583 28.7917 33 28.2 33 27.5C33 26.8 32.7583 26.2083 32.275 25.725C31.7917 25.2417 31.2 25 30.5 25H29V23C29 21.6167 28.5127 20.4373 27.538 19.462C26.5627 18.4873 25.3833 18 24 18C22.6167 18 21.4377 18.4873 20.463 19.462C19.4877 20.4373 19 21.6167 19 23H18.5C17.5333 23 16.7083 23.3417 16.025 24.025C15.3417 24.7083 15 25.5333 15 26.5C15 27.4667 15.3417 28.2917 16.025 28.975C16.7083 29.6583 17.5333 30 18.5 30H21V32H18.5Z" fill="#64748B" />
                          </svg>
                          <Typography className='upload-image'>
                            Upload Image
                          </Typography>
                          <Typography className='drag'>
                            Drag and drop or upload from device
                          </Typography>
                          <Typography className='support-text'>
                            Supported formats: png, jpg, jpeg
                          </Typography>
                        </Box>
                      </label>}
                      {touched.companyLogo && errors.companyLogo &&
                        <div className="text-danger">{errors.companyLogo}</div>
                      }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="label-title">Company Name</Typography>
                    <CustomInput
                      fullWidth
                      data-test-id="company-name"
                      placeholder="Company Name"
                      value={values.companyName}
                      onChange={(e) => {
                        setFieldValue("companyName", e.target.value);
                      }}
                    />
                    {touched.companyName && errors.companyName &&
                      <div className="text-danger">{errors.companyName}</div>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="label-title">Contact Name</Typography>
                    <CustomInput
                      fullWidth
                      data-test-id="contact-name"
                      placeholder="Contact Name"
                      value={values.contactName}
                      onChange={(e) => {
                        setFieldValue("contactName", e.target.value);
                      }}
                    />
                    {touched.contactName && errors.contactName &&
                      <div className="text-danger">{errors.contactName}</div>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="label-title">Contact Job Title</Typography>
                    <CustomInput
                      fullWidth
                      value={values.jobTitle}
                      data-test-id="job-title"
                      placeholder="Contact Job Title"
                      onChange={(e) => {
                        setFieldValue("jobTitle", e.target.value);
                      }}
                    />
                    {touched.jobTitle && errors.jobTitle &&
                      <div className="text-danger">{errors.jobTitle}</div>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="label-title">Contact Email</Typography>
                    <CustomInput
                      fullWidth
                      value={values.email}
                      data-test-id="email"
                      placeholder="Contact Email"
                      onChange={(e) => {
                        this.onNameChange();
                        setFieldValue("email", e.target.value);
                      }}
                    />
                    {touched.email && errors.email &&
                      <div className="text-danger">{errors.email}</div>
                    }
                    {this.state.emailError &&
                      <div className="text-danger">{this.state.emailError}</div>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="label-title">Contact Phone</Typography>
                    <CustomInput
                      fullWidth
                      value={values.contactPhone}
                      data-test-id="phone"
                      placeholder="Contact Phone"
                      onChange={(e) => {
                        const value = e.target.value;
                        if(/^\d+$/.test(value) || value === '') {
                          this.onPhoneChange();
                          setFieldValue("contactPhone", value);
                        }
                      }}
                    />
                    {touched.contactPhone && errors.contactPhone &&
                      <div className="text-danger">{errors.contactPhone}</div>
                    }
                    {this.state.contactError &&
                      <div className="text-danger">{this.state.contactError}</div>
                    }
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="dialog-action">
                <CancelButton data-test-id="cancel-btn" onClick={this.onCloseDialog}>
                  Cancel
                </CancelButton>
                <DoneButton
                  type="submit"
                >
                  Create Client
                </DoneButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </CustomDialog>
    );
  }

  getFontWeight = (isSelected: boolean) => {
    return isSelected ? '700' : '400';
  };

  renderDeleteClientDialog = () => {
    return (
      <CustomDialog
        open={this.state.isDeleteClientModalOpen}
        onClose={() => this.onDeleteClienFeedback(false)}
      >
        <Box sx={{ display: 'flex',maxWidth:'600px', justifySelf: 'flex-end', justifyContent: 'flex-end', padding: '8px' }}> <CloseIcon className="close-icon" data-test-id="close-btn" onClick={() => this.onDeleteClienFeedback(false)} /></Box>
        <DialogContent className="content">
          <Typography sx={{
            fontFamily: 'Heebo',
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '32px',
            letterSpacing: '-0.005em',
            textAlign: 'left',
            color:'#0F172A'
          }}
          >{`Are you sure you want to delete ` + this.state.selectedClientToDelete?.attributes.contact_name + `'s account?`}</Typography>
          <Typography sx={{
            fontFamily: 'Heebo',
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '26px',
            textAlign: 'left',
            marginTop: '16px'
          }}>This action can’t be undone and all the data stored in this account won’t be accessible anymore.</Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            data-test-id="cancelDelete"
            onClick={() => this.onDeleteClienFeedback(false)}
          >
            No
          </CancelButton>
          <DoneButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            data-test-id="confirmDelete"
            onClick={() => this.onDeleteClienFeedback(true)}
          >
            Yes
          </DoneButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentPage, itemsPerPage, accountsData } = this.state;
    const totalPages = Math.ceil(accountsData.length / itemsPerPage);
    const startResult = (this.state.metaData.current_page - 1) * itemsPerPage + 1;
    const endResult = Math.min(startResult + itemsPerPage - 1, this.state.metaData.total_count);
    const open = Boolean(this.state.filterAchorEl); 
    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2); 
      return `${day}.${month}.${year}`;
    };
    
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {this.renderCreateClientDialog()}
          {this.renderAssignCoursesDialog()}
          {this.renderDeleteClientDialog()}
          <Wrapper sx={webStyles.mainWrapper}>
            <Box sx={webStyles.mainBlock}>
            <Loader loading={this.state.isLoaded} />
            <Box>
              <Sidebar navigation={this.props.navigation} current='clients' />
            </Box>
              <Box sx={webStyles.pageContent} data-testid="pageContent">
                <Box sx={webStyles.contentHeader}>
                  <Box sx={webStyles.navigation}>
                    <Box sx={webStyles.breadcrumbButton}>Clients</Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Button
                        startIcon={
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 11.575C7.71667 11.575 7.47933 11.479 7.288 11.287C7.096 11.0957 7 10.8583 7 10.575V3.425L5.125 5.3C4.925 5.5 4.69167 5.6 4.425 5.6C4.15833 5.6 3.91667 5.49167 3.7 5.275C3.5 5.075 3.40433 4.83733 3.413 4.562C3.421 4.28733 3.51667 4.05833 3.7 3.875L7.3 0.275C7.4 0.175 7.50833 0.104 7.625 0.0619998C7.74167 0.0206665 7.86667 0 8 0C8.13333 0 8.25833 0.0206665 8.375 0.0619998C8.49167 0.104 8.6 0.175 8.7 0.275L12.3 3.875C12.5 4.075 12.5957 4.31233 12.587 4.587C12.579 4.86233 12.4833 5.09167 12.3 5.275C12.1 5.475 11.8627 5.579 11.588 5.587C11.3127 5.59567 11.075 5.5 10.875 5.3L9 3.425V10.575C9 10.8583 8.90433 11.0957 8.713 11.287C8.521 11.479 8.28333 11.575 8 11.575ZM2 15.575C1.45 15.575 0.979333 15.3793 0.588 14.988C0.196 14.596 0 14.125 0 13.575V11.575C0 11.2917 0.0956668 11.054 0.287 10.862C0.479 10.6707 0.716667 10.575 1 10.575C1.28333 10.575 1.521 10.6707 1.713 10.862C1.90433 11.054 2 11.2917 2 11.575V13.575H14V11.575C14 11.2917 14.096 11.054 14.288 10.862C14.4793 10.6707 14.7167 10.575 15 10.575C15.2833 10.575 15.5207 10.6707 15.712 10.862C15.904 11.054 16 11.2917 16 11.575V13.575C16 14.125 15.8043 14.596 15.413 14.988C15.021 15.3793 14.55 15.575 14 15.575H2Z" fill="#0F172A"/>
                          </svg>
                        }
                        disableElevation
                        variant="contained"
                        data-test-id="export-btn"
                        onClick={this.onExport}
                        style={{ backgroundColor: "white", color: "black", marginRight: '10px', width: "111px", height: '44px',textTransform:'none',borderRadius:'8px' }}
                      > 
                        Export
                      </Button>

                      <Button
                        disableElevation
                        variant="contained"
                        data-test-id="new-client-btn"
                        onClick={this.handleNewClick}
                        style={{ backgroundColor: '#1C1C1C', width: "169px", height: '44px', color: 'white',whiteSpace: 'nowrap',textTransform:'none',borderRadius:'8px' }}
                        startIcon={<GroupAddIcon />}
                      >
                        Add New Client
                      </Button>

                    </Box>
                  </Box>
                </Box>
                <TextField
                  
                  InputProps={{
                    
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    sx: { backgroundColor: 'white',padding:'5px',paddingLeft:'20px' } 
                  }}
                  variant="outlined"
                  size="small"
                  placeholder="Search for a specific client"
                  sx={{ width: "55%", height: "50px", marginLeft: "4px",}}
                  value={this.state.searchQuery}
                  onChange={this.handleSearchChange}/>
                <Box display="flex" alignItems="center" justifyContent="space-between"  p={[0, 3]} bgcolor="#f5f5f5">
                  <Box display="flex" alignItems="center">
                    {this.state.selectedCount > 0 && (
                      <>
                        <img onClick={this.deselectAll} src={DeselectIcon} style={{marginRight:'10px'}} data-test-id="deselect-btn" />
                        <Typography>{this.state.selectedCount} item selected</Typography>
                        <ArchiveButton 
                        variant="contained"
                        disableElevation
                        data-test-id="archive-btn"
                        onClick={this.onArchive}
                        >
                          Archive
                        </ArchiveButton>
                      </>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: "center" }}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                      <Typography>Sort by:</Typography>
                      <MuiSelect
                        data-testid="sort-select"
                        value={this.state.sortBy}
                        onChange={(e: any) => this.handleSortByChange(e)}
                        defaultValue="relevance"
                        disableUnderline
                        variant='standard'
                        sx={{
                          paddingLeft: '10px',
                          width: '150px',
                          backgroundColor: 'transparent',
                          '& .MuiSelect-icon': {
                            color: 'gray',
                          },
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'transparent',
                            border: '1px solid #ccc',
                            '& input': {
                              backgroundColor: 'transparent',
                              padding: ''
                            },
                          },
                        }}
                      >
                        <MenuItem value="Name (A-Z)">Name (A-Z)</MenuItem>
                        <MenuItem value="Name (Z-A)">Name (Z-A)</MenuItem>
                        <MenuItem value="Oldest to newest">Oldest to newest</MenuItem>
                        <MenuItem value="Newest to oldest">Newest to oldest</MenuItem>
                        <MenuItem value="status">Status</MenuItem>
                      </MuiSelect>
                    </div>
                  </Box>
                </Box>
                <Container style={{paddingLeft:'4px'}} maxWidth={false} sx={{ width: "100%", }}>

                  <Paper style={webStyles.tableViewStyle}>
                    <TableContainer style={webStyles.tableContainerStyle}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>   </TableCell>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderCompany}</TableCell>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderAdminUser}</TableCell>
                            <TableCell sx={{textAlign:"left",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderPackages}</TableCell>
                            <TableCell sx={{textAlign:"center",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderTotalNumberOfUsers}</TableCell>
                            <TableCell sx={{textAlign:"center",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderDateCreated}</TableCell>
                            <TableCell sx={{textAlign:"center",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}>{configJSON.tableHeaderStatus}</TableCell>
                            <TableCell sx={{textAlign:"right",font:'inter',color:'#475569', fontSize:"14px",fontWeight:'bold'}}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {accountsData.map((account:any) => (
                            <TableRow
                              key={account.id}
                              style={{
                                backgroundColor: account.isSelected ? '#f8fafc' : '#FFFFFF',
                              }}
                            >

                              <TableCell sx={{textAlign:"left",padding:'0px'}}>
                                <Checkbox
                                sx={{
                                  '&.Mui-checked': {
                                    color: 'black', 
                                    borderRadius: '6px', 
                                    '&:hover': {
                                     backgroundColor: 'rgba(0, 0, 0, 0.08)', 
                                     },},
                                }}    
                                  checked={account.isSelected}
                                  onChange={() => this.handleCheckboxChange(account.id, account.isSelected)}
                                  data-testid={`checkbox-${account.id}`}
                                />
                              </TableCell>

                              <TableCell sx={{textAlign:"left",fontWeight: this.getFontWeight(account.isSelected)}}>
                                {account.attributes.company_name}
                              </TableCell>
                              <TableCell sx={{textAlign:"left",fontWeight: this.getFontWeight(account.isSelected)}}>{account.attributes.contact_name}</TableCell>
                              <TableCell sx={{textAlign:"left",fontWeight: this.getFontWeight(account.isSelected)}} >Premium</TableCell>
                              <TableCell sx={{textAlign:"center",fontWeight: this.getFontWeight(account.isSelected)}}>{account.attributes.count}</TableCell>

                              <TableCell sx={{ textAlign: "center", fontWeight: this.getFontWeight(account.isSelected) }}>
                                {formatDate(account.attributes.created_at)}
                              </TableCell>
                              <TableCell sx={{ fontFamily: 'Heebo-bold', textAlign: 'center' }}>
                                {account.attributes.status === "active" ? <span className='active'>ACTIVE</span> : <span className='archive'>ARCHIVE</span>}
                              </TableCell>
                              <TableCell sx={{textAlign:"right"}}>
                                <IconButton
                                  onClick={(event) => this.handleMenuOpen(event, account.id)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={this.state.anchorEl[account.id]}
                                  open={Boolean(this.state.anchorEl[account.id])}
                                  onClose={() => this.handleMenuClose(account.id)}
                                  sx={{ml:'4px'}}
                                  anchorOrigin={{
                                    vertical: 'center', 
                                    horizontal: 'left', 
                                  }}
                                  transformOrigin={{
                                    vertical: 'top', 
                                    horizontal: 'right', 
                                  }}
                                  PaperProps={{
                                    style: { width: '200px' }, 
                                  }}
                                >
                                  <MenuItem onClick={() => this.handleClientClick(account.id,account.attributes.company_name)}>View</MenuItem>
                                  <MenuItem data-test-id={`edit-client-${account.id}`} onClick={() => this.handleEditClientClick(account.id)}>Edit</MenuItem>
                                  <MenuItem data-test-id={`delete-client-${account.id}`} sx={{color:'#DC2626'}} onClick={() => this.handleDeleteClientConfirm(account.id)}>Delete</MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                        {startResult >= 0 ?<TableRow>
                            <TableCell colSpan={8} align="center">
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}></div>
                                <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                                  <CustomPagination
                                    data-test-id="pagination"
                                    size='small'
                                    count={this.state.metaData.total_pages}
                                    page={this.state.metaData.current_page}
                                    onChange={(event, value) => this.getUsers(value)}
                                    siblingCount={1}
                                    boundaryCount={1}
                                  />
                                </div>
                                <div style={{ flex: 1, textAlign: 'right' }}>
                                  {`${startResult}-${endResult} of ${this.state.metaData.total_count} results`}
                                </div>

                              </div>
                            </TableCell>
                          </TableRow>
                          :
                          <TableCell colSpan={8} align="center">No Record found!</TableCell>
                        }
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Container>
              </Box>



              <Modal open={this.state.isVisibleModal} onClose={this.hideModal}>
                <Box sx={webStyles.modalStyle}>
                  {this.state.fieldError && (
                    <p style={webStyles.errorMsg}>
                      {configJSON.errorAllFieldsAreMandatory}
                    </p>
                  )}
                  <Box sx={webStyles.modalRowViewStyle}>
                    <Input
                      data-test-id={'inputName'}
                      placeholder={configJSON.textName}
                      defaultValue={this.state.editMode ? this.state.name : ''}
                      onChange={(e) => this.handleInputName(e.target.value)}
                    />
                    <Button
                      data-test-id="btnAddGroup"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.state.editMode
                          ? this.editGroup(this.state.id)
                          : this.addGroup();
                      }}
                    >
                      {this.state.editMode
                        ? configJSON.textUpdate
                        : configJSON.textSave}
                    </Button>
                  </Box>

                  <Box sx={webStyles.modalButtonViewStyle}>
                    <Button
                      data-test-id="btnHideModal"
                      variant="contained"
                      onClick={this.hideModal}
                    >
                      {configJSON.textClose}
                    </Button>
                  </Box>
                </Box>
              </Modal>

              
              <Modal
                open={this.state.isVisibleAddAccountModal}
                onClose={this.hideAddAccountModal}
              >
                <Box sx={webStyles.modalStyle}>
                  <Box sx={webStyles.modalRowViewStyle}>
                    <p>{this.state.name}</p>
                  </Box>
                  <Box sx={webStyles.dropdownViewStyle}>
                    <p>{configJSON.textSelectAccounts}</p>
                    <IconButton
                      data-test-id={'btnExpandAccountsView'}
                      onClick={this.expandAccountsView}
                      edge="end"
                      size="large"
                    >
                      {this.state.dropdownAccountStatus ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </Box>
                  {this.state.dropdownAccountStatus && (
                    <Paper style={webStyles.dropdownListContainer}>
                      <List>
                        {this.state.modalAccData.map(
                          (account: Account, index: number) => {
                            const labelId = `checkbox-list-label-${index}`;
                            return (
                              <ListItem
                                key={account.id}
                                data-test-id={'listItem' + index}
                                dense
                                button
                                onClick={() =>
                                  this.handleAccountSelect(account.id)
                                }
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={account.isSelected}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={
                                    account.attributes.first_name! + account.attributes.last_name!
                                  }
                                />
                              </ListItem>
                            );
                          }
                        )}
                      </List>
                    </Paper>
                  )}

                  <Box sx={webStyles.modalButtonViewStyle}>
                    <Button
                      data-test-id="btnSaveAccountsToGroup"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.handleSaveAccountsToGroup(this.state.id)
                      }
                    >
                      {configJSON.textSave}
                    </Button>
                    <Button
                      data-test-id="btnCloseAddAccountModal"
                      variant="contained"
                      onClick={this.hideAddAccountModal}
                    >
                      {configJSON.textClose}
                    </Button>
                  </Box>
                </Box>
              </Modal>

              
              <Modal
                open={this.state.isVisibleDeleteAccountModal}
                onClose={this.hideDeleteAccountModal}
              >
                <Box sx={webStyles.modalStyle}>
                  <Box sx={webStyles.modalRowViewStyle}>
                    <p>{this.state.name}</p>
                  </Box>
                  <Paper style={webStyles.dropdownListContainer}>
                    <List>
                      {this.state.selectedAccounts.map(
                        (account: GroupAccount, index: number) => {
                          const labelId = `checkbox-list-label-${index}`;
                          return (
                            <ListItem
                              key={account.id}
                              data-test-id={'deleteListItem' + index}
                              dense
                              button
                              onClick={() =>
                                this.handleDeleteAccountSelect(account.id)
                              }
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={account.isSelected}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={
                                  account.first_name + " " + account.last_name
                                }
                              />
                            </ListItem>
                          );
                        }
                      )}
                    </List>
                  </Paper>
                  <Box sx={webStyles.modalButtonViewStyle}>
                    <Button
                      data-test-id="btnHandleRemoveAccountsToGroup"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.handleRemoveAccountsToGroup(this.state.id)
                      }
                    >
                      {configJSON.textDelete}
                    </Button>
                    <Button
                      data-test-id="btnHideDeleteAccountModal"
                      variant="contained"
                      onClick={this.hideDeleteAccountModal}
                    >
                      {configJSON.textClose}
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Wrapper>
          <Box sx={webStyles.footerBlock}>
            <Box sx={webStyles.leftFooter}>
              <Box>Terms and Conditions</Box>
              <Box>Privacy Policy</Box>

              <Box></Box>
            </Box>

            <Box>
              <Box>
              © 2024 Thinkspike, All Rights Reserved</Box>
            </Box>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  "& .active": {
    fontFamily: "Heebo-Bold",
    padding: '4px 11px',
    color: 'white',
    backgroundColor: 'black',
    borderRadius: '20px'
  },
  "& .archive": {
    fontFamily: "Heebo-Bold",
    padding: '4px 11px',
    color: '#78716C',
    backgroundColor: '#E7E5E4',
    borderRadius: '20px'
  }
});

const CustomPagination = styled(Pagination)({
  "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
          backgroundColor: "transparent",
          color: "#6200EA",
          fontWeight: "700",
      },
  },
})
const ArchiveButton = styled(Button)({
  backgroundColor:'#FDF2F8',
  border:'1px solid #F87171',
  color:'#DC2626',
  textTransform:"none",
  fontWeight:'700',
  marginLeft:'10px',
  '&:hover': {
    backgroundColor: '#DC2626', 
    color: 'white', 
  },
})
const webStyles = {
  modalStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff',
  },
  leftFooter: {
    display: 'flex',
    gap: '24px',
    flexGrow:1
  },
  rightFooter: {},
  modalRowViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 280,
    justifyContent: 'space-between',
    padding: 10,
    border: '1px solid #ccc',
    borderRadius: 5,
  },
  modalButtonViewStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0px',
  },
  tableViewStyle: {
    width: '100%',
    overflow: 'hidden',
    borderRadius:"6px"
  },
  tableContainerStyle: {
    maxHeight: 600,
    width:'100%',
  },
  tableButtonViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerButtonViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
  btnAddAccounts: {
    backgroundColor: '#005500',
    marginLeft: 10,
  },
  btnDeleteAccounts: {
    backgroundColor: '#990000',
    marginLeft: 10,
  },
  checkCircleRed: {
    height: 10,
    width: 10,
    backgroundColor: 'red',
    borderRadius: 5,
  },
  innerTableBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrow: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
  },
  dropdownListContainer: {
    maxHeight: 200,
    overflow: 'auto',
    width: 300,
  },
  errorMsg: {
    color: 'red',
    margin: 10,
  },
  mainWrapper: {
    flex: 1,
    flexDirection: 'col',
    backgroundColor: '#f6f6f6',

  },
  mainBlock: {
      height: "calc(100vh - 72px)",
      display: 'flex',
      width:'100%',
      flexDirection: 'row',
      backgroundColor:'#fafafa',
  },
  pageContent: {
    padding: "0px 0px 25px 5px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign:"center",
    justifyContent:'center',
    color:"black"
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 20px',
    paddingLeft:'0px',
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 4px'
  },
  breadcrumbButton: {
    fontSize: '24px',
    padding: '4px 8px',
    fontWeight:'bolder',
    fontFamily:'Heebo'
  },
};

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: '600px',
  },
  '& > .MuiPaper-root': {
    width: '100%',
    borderRadius: "8px 8px 25px",
    [theme.breakpoints.down('md')]: {
      maxWidth: "80% !important",
    },
  },
  "& .MuiGrid-root > .MuiGrid-item": {
    paddingTop: "7px",
  },
  "& .dialog-title": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 15px 12px 30px",
    borderBottom: "1px solid #E2E8F0",
    [theme.breakpoints.down('sm')]: {
      padding: "20px 15px 25px 20px",
      borderBottom: "0px",
    },
    "& .heading": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
    },
    "& .close-icon": {
      cursor: "pointer",
      color: "#334155",
    },
  },
  "& .content": {
    padding: "9px 30px 15px !important",
    [theme.breakpoints.down('sm')]: {
      padding: "15px !important",
    },
  },
  "& .course-content": {
    padding: "0px 7px !important",
    "& .loader": {
      color:"#000"
    }
  },
  "& .loader-wrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "790px",
    height: "295px",
  },
  "& .upload-logo": {
    "& .uploaded": {
      marginTop: "15px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: '17px 15px 20px',
      border: "1px dashed #CBD5E1",
      borderRadius: "8px",
      "& .logo": {
        width: "150px",
        height: "150px",
        borderRadius: "4px",
        objectFit: "cover",
      },
      "& .button-wrapper": {
        display: "flex",
        marginTop: "17px",
        gap: "15px",
      },
      "& .reupload-btn": {
        cursor: "pointer",
        fontSize: "14px",
        textTransform: "none !important",
        fontFamily: "Heebo-SemiBold",
        color: "black !important",
        textDecoration: "underline"
      },
      "& .delete-btn": {
        cursor: "pointer",
        fontSize: "14px",
        textTransform: "none !important",
        fontFamily: "Heebo-SemiBold",
        color: "red !important",
        textDecoration: "underline"
      }
    },
    "& .logo-wrapper": {
      gap: '3px',
      marginTop: "15px",
      padding: '25px 15px 25px',
      cursor: "pointer",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      border: "1px dashed #CBD5E1",
      "& .upload-image": {
        marginTop: "15px",
        fontFamily: 'Heebo-Bold',
        fontSize: '18px',
        color: "#000"
      },
      "& .drag": {
        fontFamily: 'Heebo',
        fontSize: '16px',
        color: '#334155',
      },
      "& .support-text": {
        marginTop: "10px",
        fontFamily: 'Heebo',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: '400',
        color: '#94A3B8',
      }
    },
  },
  "& .label-title": {
    fontSize: "14px",
    color: "#64748B",
    paddingBottom: '1px',
    fontFamily: "Heebo-SemiBold",
  },
  "& .dialog-action": {
    padding: "10px 16px 10px",
    borderTop: "1px solid #E2E8F0",
  },
  '& .package-wrapper .package-card:nth-of-type(odd)': {
    paddingRight: "15px",
    [theme.breakpoints.down('sm')]: {
      paddingRight: "0px",
    }
  },
  "& .package-wrapper .package-card": {
    paddingTop: "15px"
  },
  "& .packages": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    borderRadius: '8px',
    backgroundColor:"#fff",
    boxShadow: "0px 2px 20px 0px #00000014",
  }
}));

const CustomInput = styled(InputBase)({
  border: "1px solid #CBD5E1",
  borderRadius: "5px",
  fontFamily: "Heebo-Regular",
  backgroundColor: "#fff",
  marginTop: "3px",
  fontSize: "14px",
  "& input": {
    padding: "12px 10px",
    '&:placeholder': {
      fontWeight: 500
    }
  },
  "& .MuiInputBase-inputMultiline": {
    fontSize: "14px",
    padding: "10px 8px",
  },
});

const CancelButton = styled(Button)({
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "12px 30px !important",
  backgroundColor: "#F0F0F0",
  borderRadius: "5px !important",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

const DoneButton = styled(Button)({
  marginLeft: "15px !important",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "12px 22px !important",
  backgroundColor: "black",
  borderRadius: "5px !important",
  "&:hover": {
    backgroundColor: "black",
  },
});

const StyledAccordion = styled(Accordion)({
  border: '1px solid #D6D3D1',
  boxShadow: 'none',
  marginBottom: '10px',
  "& .summary": {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  "& .title": {
    fontFamily: "Heebo-SemiBold",
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  "& .text": {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    height: '65px',
    boxSizing: 'border-box'
  },
  "& .image": {
    height:"160px",
    position:"relative",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  "& .published": {
    color: "#fff",
    left: "10px",
    top: "10px",
    fontSize: "12px",
    padding: "4px 8px",
    fontFamily: "Heebo-Regular",
    position: "absolute",
    backgroundColor: "#000",
    borderRadius: "4px"
  },
  "& .time-icon": {
    marginBottom: "2px",
    fontSize: "15px",
    marginRight: '5px'
  },
  "& .hours": {
    position: "absolute",
    bottom: "0px",
    display: 'flex',
    color: '#fff',
    padding: '10px',
    fontFamily: "Heebo-Regular",
    fontSize: '12px',
    alignItems: 'center',
    zIndex: '2'
  },
  "& .learning-path-description": {
    padding: "0rem 2.1rem",
    fontSize: "14px",
    fontFamily: "Heebo-Medium",
    marginBottom: "20px",
  },
  "& .course-wrapper": {
    padding: "10px"
  },
  "& .courseCard": {
    borderRadius: "4px",
    display: 'flex',
    width: '100%',
    height: "100%",
    maxWidth: '350px',
    gap: '16px',
    flexDirection: 'column',
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
  },
  "& .courseCardDescription": {
    gap: '16px',
    flexDirection: 'column',
    padding: '0px 16px 10px',
    display: 'flex',
  },
  "& .course-title": {
    fontSize: '16px',
    color: 'black'
  },
  "& .course-lesson": {
    fontSize: '12px',
    color: 'black'
  },
  "& .description": {
    fontSize: '14px',
    color: '#8A8A8A'
  },
  "& .MuiAccordionSummary-root": {
    padding: "0px 0px 0px 11px",
    minHeight: "45px"
  },
  "& .MuiAccordionSummary-content": {
    margin: "2px 0px"
  },
  "& .MuiAccordionDetails-root": {
    padding: "8px 6px 16px"
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "4px 0px",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#000",
    marginRight: "10px"
  },
  "& .course-description": {
    "-webkit-line-clamp": "3",
    "text-overflow": "ellipsis",
    "overflow": "hidden",
    "display": "-webkit-box",
    "-webkit-box-orient": "vertical",
    "& > p": {
      margin: "0px"
    }
  }
});

const BoxThumbnail = styled(Box)({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '160px',
  "&>img": {
      "border-top-left-radius": "4px",
      "border-top-right-radius": "4px",
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: '1'
  }
});

const LearningPathCheckbox = styled(Checkbox)({
  width: "22px",
  height: "22px",
  marginRight: "11px",
  borderRadius: "4px",
  color: "black",
  padding: "0px",
  '&.Mui-checked': {
    color: "black"
  },
  "& .icons": {
    border: "1px solid #8b7878",
    borderRadius: "4px",
    height: "22px",
    width: "22px",
  },
  "&.course-checkbox": {
    position: "absolute",
    top:"10px",
    right:"0px",
    "& .icons": {
      backgroundColor: "#fff"
    }
  }
});

const PackageCheckbox = styled(Checkbox)({
  width: "25px",
  height: "25px",
  borderRadius: "4px",
  color: "black",
  padding: "0px",
  '&.Mui-checked': {
    color: "black"
  },
  "& .icons": {
    border: "1px solid #8b7878",
    borderRadius: "4px",
    height: "25px",
    width: "25px",
  }
});
// Customizable Area End
